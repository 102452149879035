import { SvgIcon, SvgIconProps } from '@mui/material'

export default function BonusAlertIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 41" fill="none">
      <circle cx="20" cy="20.5" r="19.2188" fill="#EBEDF6" stroke="#EBEDF6" strokeWidth="1.5625" />
      <path d="M9.21826 11H25.4998L25.5 30H9.21841L9.21826 11Z" fill="#F9E4A7" />
      <path
        d="M17.3333 8.5C11.44 8.5 6.66663 10.8867 6.66663 13.8333V27.1667C6.66663 30.1133 11.4533 32.5 17.3333 32.5C23.2133 32.5 28 30.1133 28 27.1667V13.8333C28 10.8867 23.2266 8.5 17.3333 8.5ZM25.3333 27.1667C25.3333 27.8333 22.4933 29.8333 17.3333 29.8333C12.1733 29.8333 9.33329 27.8333 9.33329 27.1667V24.1933C11.48 25.2333 14.2933 25.8333 17.3333 25.8333C20.3733 25.8333 23.1866 25.2333 25.3333 24.1933V27.1667ZM25.3333 21.1C23.6 22.3667 20.56 23.1667 17.3333 23.1667C14.1066 23.1667 11.0666 22.3667 9.33329 21.1V17.3533C11.2933 18.46 14.1466 19.1667 17.3333 19.1667C20.52 19.1667 23.3733 18.46 25.3333 17.3533V21.1ZM17.3333 16.5C12.1733 16.5 9.33329 14.5 9.33329 13.8333C9.33329 13.1667 12.1733 11.1667 17.3333 11.1667C22.4933 11.1667 25.3333 13.1667 25.3333 13.8333C25.3333 14.5 22.4933 16.5 17.3333 16.5ZM33.3333 13.8333V21.8333H30.6666V13.8333H33.3333ZM30.6666 24.5H33.3333V27.1667H30.6666V24.5Z"
        fill="#F3C94E"
      />
      <path d="M30.668 13.8375L33.3398 13.8375V21.8282L30.668 21.8282L30.668 13.8375Z" fill="#324AA8" />
      <path d="M30.668 24.5L33.3398 24.5V27.1491L30.668 27.1491V24.5Z" fill="#324AA8" />
    </SvgIcon>
  )
}
