import { Box, IconButton, Stack, Typography, CircularProgress } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import WaveSurfer from 'wavesurfer.js'
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm.js'
import Row from '../row'
import { Check, CheckCircleOutlined, Close, PlayArrow, Stop, WarningAmberOutlined } from '@mui/icons-material'
import { DateTime } from 'luxon'
import { GenericPlugin } from 'wavesurfer.js/dist/base-plugin'

const waveSurferOptions = {
  barWidth: 2,
  barGap: 1,
  barHeight: 3,
  height: 30,
  waveColor: '#324AA8',
  progressColor: '#324AA8'
}

const AudioRecord = ({
  cancelRecordingCallback,
  acceptRecordingCallback,
  audioRecordingUrl
}: {
  audioRecordingUrl?: string
  cancelRecordingCallback: () => void
  acceptRecordingCallback: (file: File) => void
}) => {
  const waveSurferRef = useRef()
  const waveSurfer = useRef<WaveSurfer>()
  const recorder = useRef<any>()

  const [isRecording, setIsRecording] = useState(!audioRecordingUrl)
  const [recordingDuration, setRecordingDuration] = useState(0)
  const [currentPlayTime, setCurrentPlayTime] = useState(0)
  const [blob, setBlob] = useState<Blob>()
  const [acceptRecordingLoading, setAcceptRecordingLoading] = useState(false)
  const [isUploadSuccessful, setIsUploadSuccessful] = useState(!!audioRecordingUrl)

  useEffect(() => {
    if (waveSurferRef.current) {
      waveSurfer.current = WaveSurfer.create({
        ...waveSurferOptions,
        container: waveSurferRef.current,
        hideScrollbar: true,
        url: audioRecordingUrl
      })

      recorder.current = waveSurfer.current.registerPlugin(
        RecordPlugin.create({
          renderRecordedAudio: false,
          continuousWaveform: true
        }) as unknown as GenericPlugin
      )

      recorder?.current?.on('record-progress', (time: number) => {
        setRecordingDuration(time)
      })

      recorder?.current?.on('record-end', (blob: Blob) => {
        setBlob(blob)

        if (waveSurferRef.current) {
          waveSurfer.current?.destroy()

          waveSurfer.current = WaveSurfer.create({
            ...waveSurferOptions,
            container: waveSurferRef.current,
            url: URL.createObjectURL(blob)
          })

          waveSurfer?.current?.on('timeupdate', currentTime => {
            setCurrentPlayTime(currentTime * 1000)
          })
        }
      })

      if (!audioRecordingUrl) {
        startRecording()
      }
    }

    return () => {
      if (waveSurfer.current) {
        waveSurfer.current.destroy()
      }
    }
  }, [audioRecordingUrl])

  const startRecording = () => {
    waveSurfer.current?.setOptions({
      waveColor: '#EA0000',
      progressColor: '#EA0000'
    })

    recorder.current.startRecording().then(() => {
      setIsRecording(true)
    })
  }

  const stopRecording = () => {
    if (recorder.current.isRecording()) {
      recorder.current.stopRecording()
      setIsRecording(false)
    }
  }

  const handleAcceptRecording = async () => {
    try {
      if (!blob) throw new Error()

      setAcceptRecordingLoading(true)

      const file = new File([blob], 'recording.wav', { type: 'audio/wav' })

      await acceptRecordingCallback(file)
      setIsUploadSuccessful(true)
    } catch (err) {
      console.log('err', err)
    } finally {
      setAcceptRecordingLoading(false)
    }
  }

  const timer = isRecording ? recordingDuration : currentPlayTime

  return (
    <Stack spacing={2}>
      <Row
        spacing={1}
        sx={{
          backgroundColor: 'grey.A100',
          borderRadius: '100px',
          padding: 1
        }}
      >
        {!isRecording && (
          <IconButton
            onClick={() => waveSurfer?.current?.playPause()}
            sx={{
              bgcolor: 'grey.A200'
            }}
          >
            <PlayArrow color="primary" />
          </IconButton>
        )}
        <Box ref={waveSurferRef} width={isRecording ? 157.5 : 102.5} />
        <Row spacing={acceptRecordingLoading ? 3 : 1}>
          <Typography variant="caption3" color={isRecording ? 'error' : 'black'}>
            {DateTime.fromMillis(timer).toFormat('mm:ss')}
          </Typography>
          {isRecording ? (
            <IconButton onClick={stopRecording}>
              <Stop color="error" />
            </IconButton>
          ) : acceptRecordingLoading ? (
            <CircularProgress size={14} />
          ) : (
            <Row spacing={1}>
              <IconButton
                onClick={cancelRecordingCallback}
                size="small"
                sx={{
                  'width': 20,
                  'height': 20,
                  'backgroundColor': 'grey.400',
                  '&:hover': {
                    backgroundColor: 'grey.800'
                  }
                }}
              >
                <Close fontSize="mSmall" sx={{ color: 'white' }} />
              </IconButton>
              <IconButton
                disabled={isUploadSuccessful}
                size="small"
                sx={{
                  'width': 20,
                  'height': 20,
                  'backgroundColor': 'grey.400',
                  '&.Mui-disabled': {
                    backgroundColor: 'success.main',
                    color: 'grey.500'
                  },
                  '&:hover': {
                    backgroundColor: 'grey.800'
                  }
                }}
                onClick={handleAcceptRecording}
              >
                <Check fontSize="mSmall" sx={{ color: 'white' }} />
              </IconButton>
            </Row>
          )}
        </Row>
      </Row>
      {!isRecording && (
        <Row spacing={0.75} justifyContent={'flex-end'}>
          {isUploadSuccessful ? (
            <CheckCircleOutlined color="success" fontSize={'mSmall'} />
          ) : (
            <WarningAmberOutlined fontSize="mSmall" color="warning" />
          )}
          <Typography variant="label3" fontSize={12} sx={{ top: '1.25px', position: 'relative' }}>
            {isUploadSuccessful ? 'Your audio has been saved!' : 'Are you satisfied with this recording?'}
          </Typography>
        </Row>
      )}
    </Stack>
  )
}

export default AudioRecord
