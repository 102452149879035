import { ProposalGoal } from '@productwindtom/shared-momentum-zeus-types'

export const CampaignGoalTypeToName: Record<ProposalGoal, string> = {
  PRODUCT_LAUNCH: 'Product launch',
  EXISTING_PRODUCT: 'Boost existing product',
  BOOST_RATING: 'Boost star rating',
  EVERGREEN: 'Evergreen',
  EVENT: 'Holiday or promo',
  OTHER: 'Other'
}
