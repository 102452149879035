import { v4 } from 'uuid'
import { SAMPLE_CAMPAIGNS } from '../getCampaign/sample-data'
import { ZeusInterceptor } from '../types'

export const searchBrandContent: ZeusInterceptor<'query', 'searchBrandContent'> = async args => {
  const params = args.o.searchBrandContent?.[0]

  if (Object.values(SAMPLE_CAMPAIGNS).filter(campaign => params?.campaignIds.includes(campaign.id)).length) {
    return {
      items: Object.values(SAMPLE_CAMPAIGNS)
        .filter(campaign => params?.campaignIds.includes(campaign.id))
        .map(campaign => campaign.creators)
        .flat()
        .filter(creator => !!creator?.content?.length)
        .map(creator => {
          return {
            ...creator,
            userCampaignId: v4(),
            content: creator.content
              .filter(content => {
                return !!content.channel
              })
              .map(content => ({
                ...content,
                groupId: v4()
              }))
          }
        })
    }
  }
}
