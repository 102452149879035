import CircleIcon from '@mui/icons-material/Circle'
import { Stack, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-premium'
import { CampaignPerformanceProduct } from '../../queries'

export type RowData = CampaignPerformanceProduct & {
  htmlColor: string
  daysToMilestone: Array<number | undefined>
  isPromoted: boolean
}

export const getPerformanceColumnDefinitions = (milestones: number[]): GridColDef<RowData>[] => [
  {
    field: 'productName',
    type: 'string',
    headerName: '',
    width: 360,
    align: 'left',
    renderCell: ({ row }) => {
      return (
        <Stack direction={'row'} spacing={1} pl={1}>
          <CircleIcon
            htmlColor={row.htmlColor}
            sx={{
              width: 16
            }}
          />
          <Stack py={0}>
            <Typography
              variant={'label3'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
              sx={{
                display: '-webkit-box',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                whiteSpace: 'normal',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical'
              }}
            >
              {row.productName}
            </Typography>
            <Typography variant={'body1'} color={theme => theme.palette.grey.A700}>
              {row.isPromoted ? 'Promoted Product' : 'Benchmarked product'}
            </Typography>
          </Stack>
        </Stack>
      )
    }
  },
  ...milestones.map(
    (m, index) =>
      ({
        field: `daysToMilestone[${index}]`,
        valueGetter: (_, row) => row.daysToMilestone[index],
        type: 'number',
        headerName: `${m} reviews`,
        width: 110,
        align: 'left',
        headerAlign: 'left',
        renderCell: ({ value }) => (value != null ? `${value.toFixed(0)} days` : '--')
      }) as GridColDef<RowData>
  )
]
