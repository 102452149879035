import { mApi } from '@momentum/api'
import { GraphQLTypes, InputType, Selector, ValueTypes } from '@productwindtom/shared-momentum-zeus-types'
import { keyBy, map } from 'lodash'

export const userSelector = Selector('User')({
  id: true,
  email: true,
  firstName: true,
  lastName: true,
  role: true,
  primaryBrandId: true,
  accessLevel: true,
  createdAt: true,
  lastActiveAt: true
})

export type User = InputType<GraphQLTypes['User'], typeof userSelector>

const creatorPricingSelector = Selector('CreatorPricing')({
  type: true,
  price: true
})
export type CreatorPricing = InputType<GraphQLTypes['CreatorPricing'], typeof creatorPricingSelector>

export const pricingSelector = Selector('Pricing')({
  store: true,
  creatorPricing: creatorPricingSelector
})
export type Pricing = InputType<GraphQLTypes['Pricing'], typeof pricingSelector>

const brandApiSelector = Selector('BrandApi')({
  brandId: true,
  api: true,
  apiVersion: true,
  enabled: true,
  accessToken: true,
  isIntegrated: true
})

export type BrandApi = InputType<GraphQLTypes['BrandApi'], typeof brandApiSelector>

export const brandSelector = Selector('Brand')({
  id: true,
  companyId: true,
  name: true,
  nameAudioKey: true,
  logo: true,
  region: true,
  trialSubscriptionStartedAt: true,
  subscriptionStartsAt: true,
  subscriptionEndsAt: true,
  amazonBrandStoreUrl: true,
  amazonBrandName: true,
  walmartBrandName: true,
  isBrandStoreScrapeEnabled: true,
  walmartScrapeUrl: true,
  isWalmartScrapeEnabled: true,
  paymentBillingContact: {
    name: true,
    email: true
  },
  pricing: pricingSelector,
  createdAt: true,
  lastScrapedAt: true,
  walmartLastScrapedAt: true,
  brandApis: brandApiSelector,
  proposals: {
    id: true
  }
})

export type Brand = InputType<GraphQLTypes['Brand'], typeof brandSelector>

export const baseBrandSelector = Selector('Brand')({
  id: true,
  companyId: true,
  name: true,
  nameAudioKey: true,
  logo: true,
  region: true,
  subscriptionStartsAt: true,
  amazonBrandStoreUrl: true,
  walmartScrapeUrl: true,
  createdAt: true,
  proposals: {
    id: true
  }
})

export const agencySelector = Selector('Agency')({
  id: true,
  name: true,
  logo: true,
  websiteUrl: true,
  paymentTermsType: true,
  paymentTermsCustomNetDays: true,
  pricing: pricingSelector,
  clientPricingId: true,
  clientPricing: pricingSelector
})

export type Agency = InputType<GraphQLTypes['Agency'], typeof agencySelector>
export type BaseBrand = InputType<GraphQLTypes['Brand'], typeof baseBrandSelector>

const creditProposalSelector = Selector('Proposal')({
  title: true,
  brandId: true,
  invoiceNumber: true,
  invoicePaidDate: true,
  invoiceStatus: true,
  invoiceLink: true,
  invoiceDueDate: true,
  invoiceProductCostNumber: true,
  invoiceProductCostStatus: true,
  invoiceProductCostDueDate: true,

  orderFormKey: true,

  paymentType: true,
  updatedAt: true,
  submittedAt: true,
  submittedBy: {
    firstName: true,
    lastName: true
  },
  updatedBy: {
    firstName: true,
    lastName: true
  }
})

export type SubscriptionProposal = InputType<GraphQLTypes['Proposal'], typeof creditProposalSelector>
export const creditsSelector = Selector('SubscriptionCreditAction')({
  id: true,
  actionId: true,
  action: true,
  associatedBrandId: true,
  numCredits: true,
  actionCreatedByFirstName: true,
  actionCreatedByLastName: true,
  actionCreatedById: true,
  createdAt: true,
  invoiceStatus: true,
  invoiceNumber: true,
  invoicePONumber: true,
  orderFormKey: true,
  associatedProposalId: true,
  proposal: creditProposalSelector,
  invoiceDueDate: true,
  invoiceId: true
})

export type SubscriptionCreditAction = InputType<GraphQLTypes['SubscriptionCreditAction'], typeof creditsSelector>

export const baseCompanySelector = Selector('Company')({
  id: true,
  name: true,
  logo: true,
  websiteUrl: true,
  paymentBillingContact: {
    email: true,
    name: true
  },
  paymentInvoicingType: true,
  paymentTermsType: true,
  paymentTermsCustomNetDays: true,
  productCostPaymentTermsType: true,
  productCostPaymentTermsCustomNetDays: true,
  invoiceMethod: true,
  subscriptionStartsAt: true,
  subscriptionEndsAt: true,
  subscriptionLevel: true,
  agencyId: true,
  createdAt: true
})

export const companySelector = Selector('Company')({
  ...baseCompanySelector,
  agency: agencySelector,
  pricing: pricingSelector
})

export const companyWithBrandsSelector = Selector('Company')({
  ...baseCompanySelector,
  brands: baseBrandSelector
})

export type BaseCompany = InputType<GraphQLTypes['Company'], typeof baseCompanySelector>
export type Company = InputType<GraphQLTypes['Company'], typeof companySelector>
export type CompanyWithBrands = InputType<GraphQLTypes['Company'], typeof companyWithBrandsSelector>

export const getUserResources = async (userId: string, isAdmin: boolean, isAgency: boolean) => {
  const brandCounts = isAdmin || isAgency ? await searchBrandCounts() : []
  const countsByBrand = brandCounts && keyBy(brandCounts, 'brandId')

  if (isAdmin) {
    const [companies, agencies] = await Promise.all([getAdminCompanies(), getAdminAgencies()])

    const brands = companies.map(c => c.brands).flat()

    const companiesWithAgency = companies.map(c => ({
      ...c,
      agency: agencies.find(a => a.id === c.agencyId)
    }))

    return {
      companies: companiesWithAgency,
      brands,
      agencies,
      countsByBrand
    }
  } else if (isAgency) {
    const resp = (
      await mApi('query')({
        listUserAgencies: [
          { userId },
          {
            items: {
              companies: companyWithBrandsSelector,
              agency: agencySelector
            }
          }
        ]
      })
    )?.listUserAgencies?.items

    const agencies = map(resp, r => r.agency).flat()
    const companies = map(resp, r => r.companies).flat()
    const brands = companies.map(c => c.brands).flat()
    const companiesWithAgency = companies.map(c => ({
      ...c,
      agency: agencies.find(a => a.id === c.agencyId)
    }))

    return {
      companies: companiesWithAgency,
      brands,
      agencies,
      countsByBrand
    }
  } else {
    const resp = await mApi('query')({
      listUserCompanies: [
        { userId },
        {
          items: {
            company: { ...companyWithBrandsSelector, agency: agencySelector }
          }
        }
      ]
    })

    const companies = resp.listUserCompanies?.items?.map(l => l.company) || []
    const brands = companies
      .map(c => c.brands)
      .flat()
      .map(brand => ({
        ...brand,
        proposals: []
      }))

    return {
      companies,
      brands
    }
  }
}

export const getCredits = async (id: string, nextToken?: string): Promise<SubscriptionCreditAction[]> => {
  const resp = await mApi('query')({
    listSubscriptionCreditActions: [
      { id, nextToken },
      {
        items: creditsSelector,
        nextToken: true
      }
    ]
  })
  if (resp.listSubscriptionCreditActions?.nextToken) {
    const nextCredits = await getCredits(id, resp.listSubscriptionCreditActions?.nextToken)
    return [...(resp.listSubscriptionCreditActions?.items || []), ...nextCredits]
  }
  return resp.listSubscriptionCreditActions?.items || []
}

export const getAdminCompanies = async (nextToken?: string): Promise<Omit<CompanyWithBrands, 'pricing'>[]> => {
  const resp = await mApi('query')({
    listCompanies: [
      { nextToken, limit: 100 },
      {
        items: companyWithBrandsSelector,
        nextToken: true
      }
    ]
  })

  if (resp.listCompanies?.nextToken) {
    const nextCompanies = await getAdminCompanies(resp.listCompanies.nextToken)
    return [...(resp.listCompanies?.items || []), ...nextCompanies]
  }

  return resp.listCompanies?.items || []
}

export const getAdminAgencies = async (nextToken?: string): Promise<Agency[]> => {
  const resp = await mApi('query')({
    listAgencies: [
      { nextToken, limit: 100 },
      {
        items: agencySelector,
        nextToken: true
      }
    ]
  })

  if (resp.listAgencies?.nextToken) {
    const nextAgencies = await getAdminAgencies(resp.listAgencies.nextToken)
    return [...(resp.listAgencies?.items || []), ...nextAgencies]
  }

  return resp.listAgencies?.items || []
}

export const getAgency = async (agencyId: string): Promise<Agency | undefined> => {
  const resp = await mApi('query')({
    getAgency: [{ id: agencyId }, agencySelector]
  })

  return resp?.getAgency
}

const searchBrandCountsSelector = Selector('SearchBrandCountsOutput')({
  brandId: true,
  totalProducts: true,
  totalRecommendations: true
})

export type SearchBrandCountsItem = InputType<GraphQLTypes['SearchBrandCountsOutput'], typeof searchBrandCountsSelector>

export const searchBrandCounts = async (afterKey?: string): Promise<SearchBrandCountsItem[]> => {
  const { searchBrandCounts: sbc } = await mApi('query')({
    searchBrandCounts: [
      {
        afterKey
      },
      {
        afterKey: true,
        items: {
          brandId: true,
          totalProducts: true,
          totalRecommendations: true
        }
      }
    ]
  })

  if (sbc.afterKey && sbc.afterKey !== afterKey) {
    return [...(sbc.items || []), ...(await searchBrandCounts(sbc.afterKey))]
  }

  return sbc?.items || []
}

export const getBrand = async (brandId: string) => {
  const resp = await mApi('query')({
    getBrand: [{ id: brandId }, brandSelector]
  })
  return resp.getBrand
}

export const getCompany = async (companyId: string) => {
  const resp = await mApi('query')({
    getCompany: [{ id: companyId }, companySelector]
  })
  return resp.getCompany
}

export const adjustSubscriptionCredits = async (input: ValueTypes['AdjustCreditInput']) => {
  const resp = await mApi('mutation')({
    adjustSubscriptionCredits: [{ input }, creditsSelector]
  })
  return resp.adjustSubscriptionCredits
}

export const purchaseSubscriptionCredits = async (input: ValueTypes['PurchaseCreditsInput']) => {
  const resp = await mApi('mutation')({
    purchaseSubscriptionCredits: [{ input }, creditsSelector]
  })
  return resp.purchaseSubscriptionCredits
}
