import { SvgIcon, SvgIconProps } from '@mui/material'

export default function CalendarCheckIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_774_11708)">
        <circle cx="12" cy="12" r="12" fill="#94D5D9" />
        <rect x="3.5" y="3.5" width="17" height="16" rx="1.5" fill="white" stroke="black" />
        <path
          d="M16.4352 8L10.139 14.2962L7.66452 11.8312L6.31738 13.1783L10.139 17L17.7824 9.35669L16.4352 8Z"
          fill="#12B76A"
        />
        <path d="M3 3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3V7H3V3Z" fill="#EA0000" />
        <rect x="6" width="2" height="4" rx="0.5" fill="#EAEAEA" />
        <rect x="16" width="2" height="4" rx="0.5" fill="#EAEAEA" />
      </g>
      <defs>
        <clipPath id="clip0_774_11708">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
