import { EstimatedTimeline } from '@momentum/routes/proposals-create/common/EstimatedTimeline'
import { Product, Proposal } from '@momentum/routes/campaign/context/queries'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { DateTime } from 'luxon'
import { Box, Stack } from '@mui/system'
import { Divider } from '@mui/material'
import { useTimelineSteps } from '@momentum/routes/proposals-create/common/useTimelineSteps'

import { convertFromCreatorPricing } from '@momentum/utils/proposalUtils'
import {
  calculateResults,
  CampaignProposalInput,
  convertToCreatorInputFromProposalCreatorPricing
} from '@productwindtom/shared-momentum'

export const CampaignTimeline = ({ proposal, product }: { proposal: Proposal; product: Product }) => {
  const { brand } = useBrandContext()

  const input: CampaignProposalInput = {
    creators: convertToCreatorInputFromProposalCreatorPricing(proposal.creatorPricing || []),
    ratingGoal: proposal.ratingGoal,
    productCost: proposal.productPriceOverride || proposal.productPrice!,
    exchangeRate: proposal.exchangeRate || 1,
    managedServiceFee: proposal.managedServiceFee,
    store: product.store,
    ratingsSummary: {
      numRatings: proposal.productRatingCount,
      rating: proposal.productRating
    },
    estimatedAverageRating: proposal.estimatedAverageRating
  }

  const results = calculateResults(proposal.goal, input)

  const timelineSteps = useTimelineSteps({
    ...proposal,
    reviewsGoal: results.numReviews,
    estimatedUnitsSoldPerWeek: (proposal.estimatedUnitsSoldPerMonth || 0) / 4,
    eventDate: proposal.eventDate ? DateTime.fromISO(proposal.eventDate) : undefined,
    launchDate: DateTime.fromISO(proposal.launchDate),
    creatorPricing: convertFromCreatorPricing(proposal.creatorPricing),
    isVendor: brand.brandApis.some(b => b.api.includes('vendor') && b.enabled),
    isDailySchedule: proposal.isDailyScheduling
  })
  return (
    <Stack>
      <Box py={2}>
        <EstimatedTimeline {...timelineSteps} launchDate={DateTime.fromISO(proposal.launchDate)} />
      </Box>
      <Divider />
    </Stack>
  )
}
