import { Selector } from '@productwindtom/shared-ws-zeus-types'

export const brandApiInputSelector = Selector('BrandApi')({
  enabled: true,
  api: true,
  locale: true,
  shop: true,
  shopId: true
})

export const brandInputSelector = Selector('Brand')({
  id: true,
  name: true,
  brandInstaHandle: true,
  brandTikTokHandle: true,
  nameAudioKey: true,
  defaultMarketingBriefKey: true,
  defaultPostingInspirationLinks: true,
  defaultHashtags: true,
  defaultPostingGuidelines: true
})

export const skuInputSelector = Selector('Sku')({
  id: true,
  skuId: true,
  brandId: true,
  bundle: true,
  active: true,
  name: true,
  product: true,
  storeName: true,
  iosAppProviderId: true,
  priceCents: true,
  approxRetailPrice: true,
  exactRetailPrice: true,
  approxShippingCost: true,
  minimumOrderQuantity: true,
  listingLink: true,
  productImageKey: true,
  productSearchImageKey: true,
  exactListingTitle: true,
  listingGuidance: true,
  nonSponsoredListingKey: true,
  sponsoredListingKey: true,
  brandListingKey: true,
  productPostingGuidance: true,
  productPostingGuidanceDocument: true,
  mccValue: true,
  childSkus: [
    {},
    {
      items: {
        skuId: true
      }
    }
  ],
  parentSku: {
    id: true,
    skuId: true,
    childSkus: [
      {},
      {
        items: {
          parentSkuId: true,
          skuId: true
        }
      }
    ]
  },
  nameAudioKey: true
})

export const productInputSelector = Selector('Product')({
  id: true,
  campaignId: true,
  creatorCount: true,
  brandAdvocateCount: true,
  sequenceId: true,
  qualityLevel: true,
  internalName: true,
  internalDescription: true,
  active: true,
  visibleAt: true,
  visibleEndsAt: true,
  maxDiscoveryBonus: true,
  closeoutFormBonus: true,
  baseBonus: true,
  campaignStateMachine: true,
  postingDeadlineDaysRelativeToApproval: true,
  contentApprovalLeadDays: true,
  customHashtags: true,
  customFtcHashtags: true,
  instaHandle: true,
  tikTokHandle: true,
  socialMediaExampleUrls: true,
  automaticReviewRelease: true,
  creatorPreference: true,
  creatorPreferenceDetails: true,
  contentRequirement: {
    requirementLevel: true,
    channel: true,
    type: true,
    bonus: true,
    bronzeBonus: true,
    silverBonus: true,
    goldBonus: true,
    platinumBonus: true,
    diamondBonus: true,
    instances: true,
    requiresManualApproval: true,
    inspirationalContent: {
      type: true,
      url: true
    },

    brandContentGuidance: {
      instance: true,
      text: true
    },
    talkingPoints: {
      instance: true,
      text: true
    }
  },
  hideBrandContentApprovals: true,
  contentApprovalConfiguration: {
    contentApprovalAssignee: true,
    contentApprovalType: true
  },
  stagingSocialQualityRequirement: true,
  stagingApprovalConfiguration: {
    stagingApprovalAssignee: true,
    stagingApprovalType: true
  },
  stagingApprovalRequiredNumCreatorsClientReviewed: true,
  nonRetailReviewLink: true,
  nonRetailReviewWebsiteName: true,
  payForPerformanceBonus: {
    baseBonus: true,
    silverBonus: true,
    goldBonus: true,
    platinumBonus: true,
    diamondBonus: true
  },
  payForImpressionsBonus: {
    enabled: true,
    impressionsBaseline: true,
    impressionsCap: true,
    impressionsIncrement: true,
    initialBonus: true,
    growthRate: true
  },
  productPostingGuidance: true,
  productPostingGuidanceDocument: true,
  brandTalkingPoints: true,
  isDirectListingPurchase: true,
  directPurchaseLink: true,
  listingGuidance: true,
  purchaseDiscountCode: true,
  purchaseDiscountCodePercentage: true,
  purchaseDiscountCodeDetails: true,
  buyOnlinePickupInStore: true,
  purchaseByPromoCode: true,
  payForClicksEnabled: true,
  payForClicksMinClicks: true,
  autoApprovalStatusTiers: true,
  instantJoinType: true,
  promoCodeType: true,
  promoCodeGuidance: true,
  promoCode: true,
  promoLink: true,
  promotionLinkId: true,
  promotionLink: {
    id: true,
    linkId: true,
    storageType: true,
    originalLink: true,
    userEncodedLink: true
  },
  shopifyPriceRule: true,
  shopifyPromotionType: true,
  shopifyPromotionId: true,
  promoDiscountType: true,
  promoDiscountAmount: true,
  requestProductFeedback: true,
  requestEarlyProductFeedback: true,
  isQualityRequirementsEnabled: true,
  impressionsInstagramRangeRequirement: {
    min: true,
    max: true
  },
  impressionsTiktokRangeRequirement: {
    min: true,
    max: true
  },
  engagementRateInstagramRangeRequirement: {
    min: true,
    max: true
  },
  engagementRateTiktokRangeRequirement: {
    min: true,
    max: true
  },
  followersInstagramRangeRequirement: {
    min: true,
    max: true
  },
  followersTiktokRangeRequirement: {
    min: true,
    max: true
  },
  createdAt: true,
  updatedAt: true,
  autoApprovalV2Config: {
    enabled: true,
    autoApprovalCriteria: {
      completedCampaigns: true
    }
  },
  payForPostingContentEarly: {
    enabled: true,
    daysBeforeDeadline: true,
    bonusCents: true
  },
  requiredProductLinkEnabled: true,
  productLinkId: true,
  productLink: {
    id: true,
    userId: true,
    campaignId: true,
    originalLink: true,
    userEncodedLink: true,
    createdAt: true,
    updatedAt: true,
    uniqueClicks: true,
    rank: true
  },
  internalNotes: true,
  internalCreatorsStagingGuidance: true,
  validateProductFoundVariations: true,
  sequence: {
    id: true,
    sequenceName: true,
    active: true,
    sequences: {
      id: true,
      components: {
        id: true,
        componentName: true,
        componentType: true,
        numbered: true,
        content: true,
        images: true,
        type: true,
        validation: true,
        placeholder: true
      }
    },
    createdAt: true,
    updatedAt: true
  }
})

export const campaignInputSelector = Selector('Campaign')({
  id: true,
  title: true,

  goal: true,

  anticipatedStartDate: true,
  actualStartDate: true,
  endDate: true,
  influencerApprovalEnabled: true,

  committedReviewsCount: true,
  lifestyleCommittedContentCount: true,
  socialCommittedContentCount: true,
  premiumLifestyleCommittedContentCount: true,
  committedPlacementWinsCount: true,

  amountPaidByClient: true,
  authorizedUnits: true,
  marketingFee: true,
  totalProductCost: true,
  budgetSpent: true,

  earlyReviewEnabled: true,
  totalEarlyReviewsAllowed: true,
  closeoutReleaseRate: {
    count: true,
    period: true,
    isIntegrationFallbackRate: true
  },

  applicationForm: true,
  postingGuidance: true,
  preApplicationProductPriceRange: true,
  campaignStateMachine: true,
  brandId: true,
  brandStoreLink: true,
  brandProductShort: true,
  applicationProductLinkId: true,
  applicationProductLink: {
    id: true,
    linkId: true,
    storageType: true,
    originalLink: true,
    userEncodedLink: true
  },
  preApplicationProductImage: true,
  preApplicationUgcProductImage: true,
  active: true,
  visibleToUser: true,
  contentCompleteDeadline: true,
  reviewVotingTarget: true,
  createdAt: true,
  localeV1: true,
  badges: {
    type: true,
    value: true
  },
  attributionRefUrlSuffix: true,
  applicationStatus: true,
  userAutoApproved: true,
  payForPerformanceEnabled: true,
  commissionFlatRate: true,
  commissionPercentageRate: true,
  sourcingPriority: true,
  contentApprovalRequired: true,
  campaignReferralBonusEnabled: true,
  campaignReferralBonusAmount: true,
  campaignProductType: true,
  aboutSection: true,
  sponsorshipInstructions: true,
  creationSourceVersion: true,
  updatedAt: true,
  brand: brandInputSelector,
  enableContentComplianceReview: true,
  committedClicks: true,
  requiredProductLinksEnabled: true,
  skuId: true,
  sku: skuInputSelector,
  campaignProducts: [
    {},
    {
      items: {
        ...productInputSelector
      }
    }
  ]
})
