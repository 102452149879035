import { useFormContext, useFieldArray } from 'react-hook-form'
import { Stack, Button, Alert, Typography, Grid } from '@mui/material'
import { Box } from '@mui/system'
import { Add, Cancel, EmailOutlined, ContactPageOutlined } from '@mui/icons-material'
import { TextInput } from '@productwindtom/ui-base'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import React from 'react'

const READ_ONLY_CONTACT = 'billing@productwind.com'

export const BillingContactsInput = ({ name }: { name: 'billingContacts' }) => {
  const {
    control,
    formState: { errors },
    trigger
  } = useFormContext<ProposalCreateForm>()
  const { append, fields, remove } = useFieldArray({ name, control })

  const handleAdd = () => {
    append({ email: '', name: '' })
  }
  const error = errors[name]?.message
  return (
    <Stack spacing={2}>
      {!!fields.length && (
        <Stack spacing={1}>
          {fields.map((v, index) =>
            v.email === READ_ONLY_CONTACT ? (
              <Stack key={v.id} direction={'row'} alignItems={'center'}>
                <Grid xs={6} container gap={0.5} alignItems={'center'} pr={1}>
                  <EmailOutlined color={'primary'} fontSize={'medium'} />
                  <Typography variant={'label3'} textOverflow={'ellipsis'} overflow={'hidden'} flex={1}>
                    {READ_ONLY_CONTACT}
                  </Typography>
                </Grid>
                <Grid xs={6} container gap={0.5} alignItems={'center'} pl={1}>
                  <ContactPageOutlined color={'primary'} fontSize={'medium'} />
                  <Typography variant={'label3'} textOverflow={'ellipsis'} overflow={'hidden'} flex={1}>
                    ProductWind Billing
                  </Typography>
                </Grid>
              </Stack>
            ) : (
              <Stack key={v.id} direction={'row'} spacing={1} alignItems={'flex-start'}>
                <TextInput
                  name={`${name}[${index}].email`}
                  fullWidth
                  placeholder={'Enter billing email'}
                  onBlur={() => trigger(name)}
                />
                <TextInput
                  name={`${name}[${index}].name`}
                  fullWidth
                  placeholder={'Enter contact name'}
                  onBlur={() => trigger(name)}
                />
                <Cancel
                  sx={{ cursor: 'pointer', pt: 1 }}
                  onClick={() => remove(index)}
                  fontSize={'mSmall'}
                  color={'action'}
                />
              </Stack>
            )
          )}
        </Stack>
      )}

      <Box>
        <Button startIcon={<Add />} variant={'text'} onClick={handleAdd} disabled={fields.length >= 5}>
          Add contact
        </Button>
      </Box>
      {!!error && (
        <Alert severity={'error'} variant={'outlined'}>
          <Typography color={'black'} variant={'label3'}>
            {error}
          </Typography>
        </Alert>
      )}
    </Stack>
  )
}
