import { yupResolver } from '@hookform/resolvers/yup'
import { BackButton } from '@momentum/components/back-button'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { Add, Cancel } from '@mui/icons-material'
import { Alert, Box, Button, CircularProgress, Link, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { SubscriptionLevel } from '@productwindtom/shared-momentum-zeus-types'
import { getCurrencySymbol } from '@productwindtom/shared-ws-currency'
import { InvoiceMethod } from '@productwindtom/shared-ws-zeus-types'
import { DateInput, Form, NumberInput, SubmitButton, TextInput } from '@productwindtom/ui-base'
import { DateTime } from 'luxon'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { BuyCreditsFormData, schema } from './BuyCreditsFormV2'

export const BuyCreditsForm = ({
  onSubmit,
  onCancel,
  onManuallyAddCreditsClick
}: {
  onCancel: () => void
  onSubmit: (data: BuyCreditsFormData) => Promise<void>
  onManuallyAddCreditsClick: () => void
}) => {
  const { isAdminView, selectedBrand, selectedCompany } = useUserSessionContext()

  const handleSubmit = async (submitValues: BuyCreditsFormData) => {
    await onSubmit(submitValues)
  }

  const billingContact =
    selectedCompany?.subscriptionLevel === SubscriptionLevel.COMPANY
      ? selectedCompany?.paymentBillingContact
      : selectedBrand?.paymentBillingContact

  const defaultValues = {
    invoiceMethod: selectedCompany?.invoiceMethod || InvoiceMethod.BILL,
    paymentDueDate: DateTime.now().plus({ days: 7 }),
    paymentBillingContact: {
      email: billingContact?.email || '',
      name: billingContact?.name || ''
    },
    billingContacts: [
      {
        email: READ_ONLY_CONTACT,
        name: 'ProductWind Billing'
      },
      {
        email: '',
        name: ''
      }
    ]
  }

  return (
    <Form onSubmit={handleSubmit} defaultValues={defaultValues} resolver={yupResolver(schema)}>
      <Stack spacing={3}>
        <Stack>
          <FormBody />
          {isAdminView && (
            <Box>
              <Button
                variant={'text'}
                disableRipple
                disableFocusRipple
                onClick={onManuallyAddCreditsClick}
                data-cy={'manualCreditsButton'}
              >
                Manually add credits instead
              </Button>
            </Box>
          )}
        </Stack>
        <Stack>
          <LoadingSpinner />
          <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
            <BackButton variant={'text'} onClick={onCancel} text={'Cancel'} data-cy={'cancelButton'} />
            <SubmitButton variant={'contained'} disableOnDirty={false} data-cy={'submitButton'}>
              Buy credits
            </SubmitButton>
          </Stack>
        </Stack>
      </Stack>
    </Form>
  )
}

const LoadingSpinner = () => {
  const { formState } = useFormContext()
  if (formState.isSubmitting) {
    return (
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <CircularProgress size={24} />
        <Typography variant={'label3'}>We're generating your invoice!</Typography>
      </Stack>
    )
  }
  return null
}

const FormBody = () => {
  const { selectedBrand } = useUserSessionContext()
  const { getValues } = useFormContext<BuyCreditsFormData>()

  return (
    <Stack spacing={2}>
      <NumberInput
        inputProps={{ 'data-cy': 'numCredits' }}
        returnAsNumber
        decimalScale={0}
        placeholder={'Enter credits'}
        name={'numCredits'}
        primaryText={`Enter the amount of credits you want to buy`}
      />
      <Stack spacing={1}>
        <Typography variant={'label3'}>Primary contact for this invoice</Typography>
        <Typography variant={'body2'}>
          An invoice will be generated and emailed to this contact. Contact customer success to update this billing
          contact.
        </Typography>
        <PaymentBillingContactInput name={'paymentBillingContact'} />
      </Stack>
      <Stack spacing={1}>
        <Typography variant={'label3'}>Enter additional contacts to be cc’d on the invoice</Typography>
        <Typography variant={'body2'}>An invoice will be generated and cc’ed to these contacts.</Typography>
        <BillingContactsInput name={'billingContacts'} />
      </Stack>
      {getValues().invoiceMethod === InvoiceMethod.CUSTOM_PO && (
        <Stack>
          <Box>
            <TextInput name={'invoicePONumber'} primaryText={'Enter PO number'} />
          </Box>
        </Stack>
      )}
      {getValues().invoiceMethod === InvoiceMethod.CUSTOM_PO && (
        <Stack>
          <Box>
            <TextInput
              name={'invoicePOSystem'}
              primaryText={'What is your invoicing system?'}
              subtext={'Enter your required invoicing system, such as Ariba or EasyO'}
            />
          </Box>
        </Stack>
      )}
      {getValues().invoiceMethod !== InvoiceMethod.CUSTOM_PO && (
        <TextInput
          primaryText={
            'Provide any additional information which is required by your finance team to be added to the invoice.'
          }
          name={'invoiceAdditionalInformation'}
          multiline
          minRows={3}
        />
      )}
      <DateInput
        name={'paymentDueDate'}
        primaryText={'What is your estimated payment due date?'}
        minDate={DateTime.now().plus({ days: 1 })}
        subtext={
          'You will receive your credits as soon as we receive payment (typically 3 days after payment is completed).'
        }
      />
      <NumberInput
        disabled
        returnAsNumber
        decimalScale={0}
        name={'numCredits'}
        inputProps={{ 'data-cy': 'invoiceAmount' }}
        primaryText={`Invoice amount:`}
        subtext={
          <Typography variant={'formSubText'}>
            By clicking “buy credits” you agree to the{' '}
            <Link
              variant={'formSubText'}
              sx={{ fontWeight: 800 }}
              href={'https://www.productwind.com/terms-and-conditions'}
              target={'_blank'}
            >
              Momentum Platform Terms and Conditions
            </Link>{' '}
            and will receive a credit order receipt via email.
          </Typography>
        }
        prefix={getCurrencySymbol(selectedBrand!.region)}
      />
    </Stack>
  )
}

const PaymentBillingContactInput = ({ name }: { name: 'paymentBillingContact' }) => {
  const {
    formState: { errors },
    trigger
  } = useFormContext<BuyCreditsFormData>()

  const error = errors[name]?.message

  return (
    <Stack spacing={2}>
      <Stack direction={'row'} spacing={1} alignItems={'flex-start'}>
        <TextInput
          name={`${name}.email`}
          fullWidth
          placeholder={'Enter billing email'}
          onBlur={() => trigger(name)}
          error={!!error}
          disabled
        />
        <TextInput
          name={`${name}.name`}
          fullWidth
          placeholder={'Enter contact name'}
          onBlur={() => trigger(name)}
          error={!!error}
          disabled
        />
      </Stack>

      {!!error && (
        <Alert severity={'error'} variant={'outlined'} data-cy={'paymentBillingContactErrorAlert'}>
          <Typography color={'black'} variant={'label3'}>
            {error}
          </Typography>
        </Alert>
      )}
    </Stack>
  )
}

const READ_ONLY_CONTACT = 'billing@productwind.com'
const BillingContactsInput = ({ name }: { name: 'billingContacts' }) => {
  const {
    control,
    formState: { errors },
    trigger
  } = useFormContext<BuyCreditsFormData>()
  const { append, fields, remove } = useFieldArray({ name, control })
  const handleAdd = () => {
    append({ email: '', name: '' })
  }
  const error = errors[name]?.message
  return (
    <Stack spacing={2}>
      {!!fields.length && (
        <Stack spacing={1}>
          {fields.map((v, index) => (
            <Stack key={v.id} direction={'row'} spacing={1} alignItems={'flex-start'}>
              <TextInput
                disabled={v.email === READ_ONLY_CONTACT}
                name={`${name}[${index}].email`}
                fullWidth
                placeholder={'Enter billing email'}
                onBlur={() => trigger(name)}
              />
              <TextInput
                disabled={v.email === READ_ONLY_CONTACT}
                name={`${name}[${index}].name`}
                fullWidth
                placeholder={'Enter contact name'}
                onBlur={() => trigger(name)}
              />
              <Cancel
                sx={{
                  cursor: v.email === READ_ONLY_CONTACT ? undefined : 'pointer',
                  pt: 1,
                  color: v.email === READ_ONLY_CONTACT ? 'transparent' : undefined
                }}
                onClick={() => {
                  if (v.email === READ_ONLY_CONTACT) return
                  remove(index)
                }}
                fontSize={'mSmall'}
                color={'action'}
              />
            </Stack>
          ))}
        </Stack>
      )}

      <Box>
        <Button startIcon={<Add />} variant={'text'} onClick={handleAdd} disabled={fields.length >= 5}>
          Add contact
        </Button>
      </Box>
      {!!error && (
        <Alert severity={'error'} variant={'outlined'} data-cy={'billingContactsErrorAlert'}>
          <Typography color={'black'} variant={'label3'}>
            {error}
          </Typography>
        </Alert>
      )}
    </Stack>
  )
}
