import { ProposalCreatorPricing } from '@momentum/routes/brand/types'
import { ProposalCreatorPricingInput } from '@momentum/routes/proposals-create/types'
import {
  CreatorPricing,
  DEFAULT_CONTENT_REQUIREMENTS,
  DEFAULT_CREATOR_CLOSEOUT_BONUS,
  DEFAULT_PRICING,
  getAutoBuysDistribution,
  getReviewRate,
  getSocialCreatorDistribution,
  MANAGED_SERVICE_CREATOR_TYPES,
  PW_RATING,
  Retailer,
  SOCIAL_CREATOR_TYPES,
  STORE_TO_RETAILER,
  SUPPORTED_AGENCY_CREATOR_TYPES,
  SUPPORTED_CREATOR_TYPES,
  toWeeklyPartition
} from '@productwindtom/shared-momentum'
import {
  CreatorType,
  GraphQLTypes,
  InputType,
  InvoicePaymentType,
  PricingCreatorType,
  ProposalGoal,
  RecommendationType,
  Region,
  Selector
} from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { get, isNumber, keyBy, sum, sumBy } from 'lodash'
import { DateTime } from 'luxon'
import { v4 } from 'uuid'
import { addBusinessDays, isHoliday, isWeekend } from './dateUtils'

export const PRODUCT_LAUNCH_RATING_COUNT = 50
const USER_RATING = PW_RATING
const CREATOR_BUFFER = 1.05
export const DEFAULT_NET_TERMS = 7

export const PaymentTypeToTitle: Record<InvoicePaymentType, string> = {
  [InvoicePaymentType.CREDITS]: 'Use existing credits',
  [InvoicePaymentType.END_OF_MONTH_INVOICE]: 'End of month billing',
  [InvoicePaymentType.EXISTING_INVOICE]: 'There is an existing invoice',
  [InvoicePaymentType.NEW_INVOICE]: 'Generate a new invoice'
}

const productSelector = Selector('Product')({
  store: true,
  ratingSummary: {
    numRatings: true,
    rating: true
  }
})

export type Product = InputType<GraphQLTypes['Product'], typeof productSelector>

export const generateContentRequirements = (creatorPricing: ProposalCreatorPricingInput[]) =>
  Array(
    creatorPricing.reduce((acc, cr) => {
      const maxContentRequirementsLength = Math.max(
        cr?.contentRequirements?.length || 0,
        ...(cr?.socialCreatorPricing?.map(scp => scp.contentRequirements?.length || 0) || [])
      )

      return Math.max(acc, maxContentRequirementsLength)
    }, 0)
  ).fill({
    id: v4()
  })

export const getProposalDefaultForRecommendationType = (
  type: RecommendationType,
  retailer: Retailer,
  creatorPricing: CreatorPricing[],
  product: Product,
  isRecommendation: boolean,
  isDailyScheduling: boolean
) => {
  const socialPrice =
    creatorPricing.find(bp => bp.type === PricingCreatorType.SOCIAL)?.price ||
    creatorPricing.find(bp => bp.type === PricingCreatorType.SOCIAL)?.fallbackPrice
  const ugcPrice =
    creatorPricing.find(bp => bp.type === PricingCreatorType.UGC)?.price ||
    creatorPricing.find(bp => bp.type === PricingCreatorType.UGC)?.fallbackPrice
  const ugcPremiumPrice =
    creatorPricing.find(bp => bp.type === PricingCreatorType.PREMIUM_UGC)?.price ||
    creatorPricing.find(bp => bp.type === PricingCreatorType.PREMIUM_UGC)?.fallbackPrice
  const advocatePrice =
    creatorPricing.find(bp => bp.type === PricingCreatorType.ADVOCATE)?.price ||
    creatorPricing.find(bp => bp.type === PricingCreatorType.ADVOCATE)?.fallbackPrice

  const countsPerType = getDefaultCreatorCountsForRecommendationRetailer(
    RECOMMENDATION_TYPE_TO_GOAL[type],
    retailer,
    product,
    isRecommendation
  )
  const creatorBrandPricing = getCreatorsDefault(retailer, {
    social: {
      numCreators: countsPerType[PricingCreatorType.SOCIAL],
      price: socialPrice
    },
    ugc: { numCreators: countsPerType[PricingCreatorType.UGC], price: ugcPrice },
    ugcPremium: { numCreators: countsPerType[PricingCreatorType.PREMIUM_UGC], price: ugcPremiumPrice },
    advocates: {
      numCreators: countsPerType[PricingCreatorType.ADVOCATE],
      price: advocatePrice
    }
  })

  const autobuysDistribution = getAutoBuysDistribution({ creatorPricing: creatorBrandPricing })
  const keyedAutobuys = keyBy(autobuysDistribution, 'type')

  const ratingGoal =
    type === RecommendationType.BOOST_RATING && product.ratingSummary?.rating && product.ratingSummary?.rating < 4.3
      ? 4.3
      : undefined

  return {
    goal: RECOMMENDATION_TYPE_TO_GOAL[type],
    launchDate: DateTime.now().plus({ weeks: 2 }),
    ratingGoal,
    creatorPricing: creatorBrandPricing.map(c => ({
      ...c,
      schedule: isDailyScheduling
        ? keyedAutobuys[c.type]?.dailySchedule
        : toWeeklyPartition(keyedAutobuys[c.type]?.dailySchedule)
    }))
  }
}

type DefaultPricingEntry = {
  price?: number
  numCreators?: number
}

const getCreatorsDefault = (
  _retailer: Retailer,
  {
    social,
    ugc,
    ugcPremium,
    advocates
  }: {
    social?: DefaultPricingEntry
    ugc?: DefaultPricingEntry
    ugcPremium?: DefaultPricingEntry
    advocates?: DefaultPricingEntry
  }
) => {
  const defaultConfig = DEFAULT_PRICING
  return [
    {
      type: PricingCreatorType.ADVOCATE,
      numCreators: advocates?.numCreators || 0,
      price: advocates?.price || defaultConfig.ADVOCATE.default
    },
    {
      type: PricingCreatorType.SOCIAL,
      numCreators: social?.numCreators || 0,
      price: social?.price || defaultConfig.SOCIAL.default
    },
    {
      type: PricingCreatorType.UGC,
      numCreators: ugc?.numCreators || 0,
      price: ugc?.price || defaultConfig.UGC.default
    },
    {
      type: PricingCreatorType.PREMIUM_UGC,
      numCreators: ugcPremium?.numCreators || 0,
      price: ugcPremium?.price || defaultConfig.UGC.default
    }
  ]
}

const RATING_RANGES = [
  [0, 0.25],
  [0.25, 0.75],
  [0.75, 1.25],
  [1.25, 1.75],
  [1.75, 2.25],
  [2.25, 2.75],
  [2.75, 3.25],
  [3.25, 3.75],
  [3.75, 4.25],
  [4.25, 4.75]
]

export const determineRatingsNextLevel = (currentRating: number) => {
  const ratingRange = RATING_RANGES.find(range => currentRating >= range[0] && currentRating <= range[1])

  return ratingRange?.[1]
}

// A*N*R - N / (1-A*5)  =   X
//(N*R - 4.25*N)/(4.25 - 5.0)
export const determineNumberReviewsForNextLevel = (currentRating: number, numRatings: number) => {
  const aim = determineRatingsNextLevel(currentRating)
  if (!aim) {
    return 0
  }

  return Math.ceil(Math.max(1, (aim * numRatings - currentRating * numRatings) / (USER_RATING - aim)))
}

export const determineNumberReviews = (
  currentRating: number,
  numRatings: number,
  desiredRatingInput: number,
  averageRating: number = USER_RATING
) => {
  const desiredRating = desiredRatingInput === 4.8 ? 4.79 : desiredRatingInput

  return Math.ceil(
    Math.max(
      1,
      Math.abs((numRatings * (desiredRating - currentRating)) / ((averageRating || USER_RATING) - desiredRating))
    )
  )
}

export const getNextLevelRating = (currentRating: number) => {
  const ratingRange = RATING_RANGES.find(range => currentRating >= range[0] && currentRating <= range[1])
  if (ratingRange) {
    return ratingRange[1] + 0.05
  }
}

// For not a recommendation
export const getDefaultCreatorCountsForRecommendationRetailer = (
  type: ProposalGoal,
  retailer: Retailer,
  product: Product,
  isRecommendation: boolean
) => {
  switch (type) {
    case ProposalGoal.PRODUCT_LAUNCH:
      return {
        [PricingCreatorType.SOCIAL]: 0,
        [PricingCreatorType.UGC]: Math.max(
          0,
          isRecommendation
            ? Math.ceil(
                (PRODUCT_LAUNCH_RATING_COUNT - (product.ratingSummary?.numRatings || 0)) / getReviewRate(retailer)
              )
            : 50
        ),
        [PricingCreatorType.PREMIUM_UGC]: 0,
        [PricingCreatorType.ADVOCATE]: 200
      }
    case ProposalGoal.EXISTING_PRODUCT:
    case ProposalGoal.BOOST_RATING:
      return {
        [PricingCreatorType.SOCIAL]: 0,
        [PricingCreatorType.UGC]:
          product.ratingSummary?.rating != null && product.ratingSummary?.numRatings != null
            ? Math.max(
                10,
                Math.ceil(
                  determineNumberReviewsForNextLevel(product.ratingSummary.rating, product.ratingSummary.numRatings) /
                    getReviewRate(retailer)
                )
              )
            : 10,
        [PricingCreatorType.PREMIUM_UGC]: 0,
        [PricingCreatorType.ADVOCATE]: 0
      }
    case ProposalGoal.EVERGREEN:
      return {
        [PricingCreatorType.SOCIAL]: 0,
        [PricingCreatorType.UGC]: 10,
        [PricingCreatorType.PREMIUM_UGC]: 0,
        [PricingCreatorType.ADVOCATE]: 100
      }

    case ProposalGoal.OTHER:
      return {
        [PricingCreatorType.SOCIAL]: 0,
        [PricingCreatorType.UGC]: 50,
        [PricingCreatorType.PREMIUM_UGC]: 0,
        [PricingCreatorType.ADVOCATE]: 200
      }

    default:
      return {
        [PricingCreatorType.SOCIAL]: 0,
        [PricingCreatorType.UGC]: 0,
        [PricingCreatorType.PREMIUM_UGC]: 0,
        [PricingCreatorType.ADVOCATE]: 0
      }
  }
}

export const RECOMMENDATION_SUPPORTED_PROPOSAL_TYPES = [
  ProposalGoal.PRODUCT_LAUNCH,
  ProposalGoal.EXISTING_PRODUCT,
  ProposalGoal.BOOST_RATING,
  ProposalGoal.EVENT
]

export const getRecommendedCreatorCounts = (
  type: ProposalGoal,
  product: Product,
  monthlyUnits: number,
  desiredStarRating?: number,
  averageReviewRating?: number
) => {
  switch (type) {
    case ProposalGoal.PRODUCT_LAUNCH: {
      const totalUnits = Math.max(Math.ceil(monthlyUnits * 0.15), 200)
      const ugc = 50
      return {
        [PricingCreatorType.SOCIAL]: 0,
        [PricingCreatorType.UGC]: ugc,
        [PricingCreatorType.PREMIUM_UGC]: 0,
        [PricingCreatorType.ADVOCATE]: totalUnits - ugc
      }
    }
    case ProposalGoal.EXISTING_PRODUCT: {
      const advocates = monthlyUnits >= 300 ? Math.ceil(monthlyUnits * 0.3) : 100
      const reviewRate = getReviewRate(STORE_TO_RETAILER[product.store])
      const ugc = Math.ceil(
        Math.max(PRODUCT_LAUNCH_RATING_COUNT - (product.ratingSummary?.numRatings || 0), 0) / reviewRate
      )
      return {
        [PricingCreatorType.SOCIAL]: 0,
        [PricingCreatorType.UGC]: ugc,
        [PricingCreatorType.PREMIUM_UGC]: 0,
        [PricingCreatorType.ADVOCATE]: advocates
      }
    }
    case ProposalGoal.BOOST_RATING: {
      const ugc =
        product.ratingSummary?.rating != null && product.ratingSummary?.numRatings != null && desiredStarRating
          ? Math.max(
              50,
              Math.ceil(
                (determineNumberReviews(
                  product.ratingSummary.rating,
                  product.ratingSummary.numRatings,
                  desiredStarRating,
                  averageReviewRating
                ) /
                  getReviewRate(STORE_TO_RETAILER[product.store])) *
                  CREATOR_BUFFER
              )
            )
          : 50

      return {
        [PricingCreatorType.SOCIAL]: 0,
        [PricingCreatorType.UGC]: ugc,
        [PricingCreatorType.PREMIUM_UGC]: 0,
        [PricingCreatorType.ADVOCATE]: 0
      }
    }
    case ProposalGoal.EVENT: {
      const advocates = monthlyUnits >= 300 ? Math.ceil(monthlyUnits * 0.3) : 100
      return {
        [PricingCreatorType.SOCIAL]: 0,
        [PricingCreatorType.UGC]: 0,
        [PricingCreatorType.PREMIUM_UGC]: 0,
        [PricingCreatorType.ADVOCATE]: advocates
      }
    }
    case ProposalGoal.EVERGREEN:
    case ProposalGoal.OTHER:
    default:
      return {
        [PricingCreatorType.SOCIAL]: 0,
        [PricingCreatorType.UGC]: 0,
        [PricingCreatorType.PREMIUM_UGC]: 0,
        [PricingCreatorType.ADVOCATE]: 0
      }
  }
}

export const getRecommendedCreatorCountMessage = (
  type: ProposalGoal,
  product: Product,
  creatorCounts: Record<PricingCreatorType, number>,
  desiredRating?: number,
  estimatedAverageRating?: number
) => {
  const reviewsGenerated = Math.ceil(creatorCounts.UGC * getReviewRate(STORE_TO_RETAILER[product.store]))

  switch (type) {
    case ProposalGoal.PRODUCT_LAUNCH: {
      const baString = `We added ${creatorCounts.ADVOCATE.toLocaleString()} Brand Advocates to drive SEO`
      const ugcString = creatorCounts.UGC
        ? ` and ${creatorCounts.UGC.toLocaleString()} UGC creators to generate an estimated ${reviewsGenerated.toLocaleString()} reviews.`
        : `. We added no UGC creators because the listing already has ${PRODUCT_LAUNCH_RATING_COUNT}+ reviews.`
      return `${baString}${ugcString}`
    }
    case ProposalGoal.EXISTING_PRODUCT: {
      const baString = `We added ${creatorCounts.ADVOCATE.toLocaleString()} Brand Advocates to drive SEO and sales`
      const ugcString = creatorCounts.UGC
        ? ` and ${creatorCounts.UGC.toLocaleString()} UGC creators to generate an estimated ${reviewsGenerated.toLocaleString()} reviews (so the listing shows ${PRODUCT_LAUNCH_RATING_COUNT}+ reviews total).`
        : `. We added no UGC creators because the listing already has ${PRODUCT_LAUNCH_RATING_COUNT}+ reviews.`
      return `${baString}${ugcString}`
    }
    case ProposalGoal.BOOST_RATING: {
      return `We added ${creatorCounts.UGC.toLocaleString()} UGC creators to improve star rating from ${product.ratingSummary?.rating} to a ${desiredRating} rating (assumes the average review is ${estimatedAverageRating || PW_RATING}).`
    }
    case ProposalGoal.EVENT: {
      return `We added ${creatorCounts.ADVOCATE.toLocaleString()} brand advocates to drive SEO and sales leading into your holiday or promo period.`
    }
    case ProposalGoal.OTHER:
    case ProposalGoal.EVERGREEN:
    default:
      return ''
  }
}

export const RECOMMENDATION_TYPE_TO_GOAL: Record<RecommendationType, ProposalGoal> = {
  [RecommendationType.BOOST_RATING]: ProposalGoal.BOOST_RATING,
  [RecommendationType.PRODUCT_LAUNCH]: ProposalGoal.PRODUCT_LAUNCH
}

export const getDefaultCreatorPricing = (
  pricing?: CreatorPricing[],
  creatorPricing?: ProposalCreatorPricingInput[],
  countsPerType?: any,
  isAgency?: boolean,
  isDailyScheduling?: boolean
): ProposalCreatorPricingInput[] => {
  const existingByType = keyBy(creatorPricing || [], 'type')
  const types = isAgency ? SUPPORTED_AGENCY_CREATOR_TYPES : SUPPORTED_CREATOR_TYPES
  const autoBuysDistribution = getAutoBuysDistribution({
    creatorPricing: types.map(type => ({
      type,
      numCreators: existingByType[type]?.numCreators ?? get(countsPerType || {}, type) ?? 0
    }))
  })

  const keyedAutoBuys = keyBy(autoBuysDistribution, 'type')

  return types.map(type => {
    const existing = creatorPricing?.find(b => b.type === type)
    const numCreators = sum(keyedAutoBuys[type]?.dailySchedule || []) || 0
    const brandPricing = pricing?.find(bc => bc.type === type)

    return {
      type,
      price: existing?.price || brandPricing?.price || brandPricing?.fallbackPrice || DEFAULT_PRICING[type].default,
      numCreators,
      schedule: isDailyScheduling
        ? keyedAutoBuys[type]?.dailySchedule || []
        : toWeeklyPartition(keyedAutoBuys[type]?.dailySchedule || []),
      contentRequirements: existing?.contentRequirements,
      socialCreatorPricing: existing?.socialCreatorPricing,
      hidden: MANAGED_SERVICE_CREATOR_TYPES.includes(type) || existing?.hidden
    }
  })
}

export const CREATOR_TYPE_TO_PRICING_CREATOR_TYPE = {
  [CreatorType.ADVOCATE]: PricingCreatorType.ADVOCATE,
  [CreatorType.UGC]: PricingCreatorType.UGC,
  [CreatorType.PREMIUM_UGC]: PricingCreatorType.PREMIUM_UGC,
  [CreatorType.TT]: PricingCreatorType.SOCIAL,
  [CreatorType.IG]: PricingCreatorType.SOCIAL,
  [CreatorType.YOUTUBE]: PricingCreatorType.SOCIAL
}
export const convertFromCreatorPricing = (creatorPricing: ProposalCreatorPricing[]): ProposalCreatorPricingInput[] => {
  const socialPricing = creatorPricing.filter(pricing => SOCIAL_CREATOR_TYPES.includes(pricing.type))
  const socialSchedules = socialPricing.map(pricing => pricing.schedule)

  const nonSocialPricing = creatorPricing
    .filter(pricing => !SOCIAL_CREATOR_TYPES.includes(pricing.type))
    .map(pricing => ({ ...pricing, type: CREATOR_TYPE_TO_PRICING_CREATOR_TYPE[pricing.type] }))

  return [
    ...nonSocialPricing.map(cp => ({
      ...cp,
      contentRequirements: cp?.contentRequirements,
      closeoutBonus: isNumber(cp?.closeoutBonus)
        ? cp.closeoutBonus
        : DEFAULT_CREATOR_CLOSEOUT_BONUS[cp.type as unknown as CreatorType],
      hidden: cp.hidden
    })),
    socialPricing?.[0] && {
      type: PricingCreatorType.SOCIAL,
      numCreators: sumBy(socialPricing, 'numCreators'),
      price: socialPricing[0].price,
      schedule: socialSchedules.reduce((acc, schedule) => {
        schedule.forEach((s, index) => {
          acc[index] = (acc[index] || 0) + s
        })

        return acc
      }, []),
      socialCreatorPricing: socialPricing.map(sp => ({
        ...sp,
        contentRequirements: sp?.contentRequirements,
        closeoutBonus: isNumber(sp?.closeoutBonus) ? sp.closeoutBonus : DEFAULT_CREATOR_CLOSEOUT_BONUS[sp.type]
      })),
      hidden: socialPricing.every(sp => sp.hidden)
    }
  ].filter(notEmpty)
}
export const PRICING_CREATOR_TO_CREATOR_TYPE = {
  [PricingCreatorType.ADVOCATE]: CreatorType.ADVOCATE,
  [PricingCreatorType.UGC]: CreatorType.UGC,
  [PricingCreatorType.PREMIUM_UGC]: CreatorType.PREMIUM_UGC,
  [PricingCreatorType.SOCIAL]: CreatorType.IG
}
export const convertToCreatorPricing = (
  creatorPricing?: ProposalCreatorPricingInput[],
  resetDefaults?: boolean
): ProposalCreatorPricing[] => {
  const socialPricing = (creatorPricing || []).find(pricing => pricing.type === PricingCreatorType.SOCIAL)

  const nonSocialPricing = (creatorPricing || [])
    .filter(pricing => pricing.type !== PricingCreatorType.SOCIAL)
    .map(pricing => ({ ...pricing, type: PRICING_CREATOR_TO_CREATOR_TYPE[pricing.type] }))

  return [
    ...nonSocialPricing,
    // We want to convert SOCIAL creators to the appropriate IG / TT distributions
    ...(socialPricing ? convertSocialPricingToCreatorPricing(socialPricing, resetDefaults) : [])
  ]
    .filter(notEmpty)
    .map(p => ({
      ...p,
      contentRequirements:
        !resetDefaults && p?.contentRequirements?.length
          ? p.contentRequirements.filter(requirement => !!requirement.contentType)
          : DEFAULT_CONTENT_REQUIREMENTS[p.type],
      closeoutBonus:
        isNumber(p?.closeoutBonus) && !resetDefaults ? p.closeoutBonus : DEFAULT_CREATOR_CLOSEOUT_BONUS[p.type],
      schedule: p?.schedule || []
    }))
}
// This function handles the splitting of PricingCreatorType.SOCIAL into the creator pricing for CreatorType.IG and CreatorType.TT
// The requirement is to save the creator pricing on a social platform level for the financial summary
const convertSocialPricingToCreatorPricing = (socialPricing?: ProposalCreatorPricingInput, resetDefaults?: boolean) => {
  // Enabled creator types for PricingCreatorType.SOCIAL to be split into
  const creatorTypes = [CreatorType.IG, CreatorType.TT]

  return creatorTypes.map(creatorType => {
    // Find pricing within the current creatorPricing for one of the creatorTypes specified above
    // If one is already set, we do not want to override it
    const foundPricing = socialPricing?.socialCreatorPricing?.find(({ type }) => type === creatorType)

    return {
      numCreators: 0,
      schedule: [],
      ...(foundPricing && foundPricing),
      type: creatorType,
      price: socialPricing?.price || 0,
      contentRequirements: foundPricing ? foundPricing.contentRequirements : [],

      // If there is no creatorType pricing currently
      // OR if we have passed in a resetDefaults argument for default distribution (reset defaults button),
      // then divide the PricingCreatorType.SOCIAL values into the pre-determined distributions (see SOCIAL_CREATOR_DISTRIBUTION)
      ...((!foundPricing || resetDefaults) && {
        numCreators: Number(
          getSocialCreatorDistribution(socialPricing?.numCreators || 0, creatorType as CreatorType.IG | CreatorType.TT)
        ),
        schedule: socialPricing?.schedule.map(s =>
          getSocialCreatorDistribution(s, creatorType as CreatorType.IG | CreatorType.TT)
        )
      }),
      hidden: socialPricing?.hidden
    }
  })
}

export const getMinLaunchDate = (region: Region) => {
  const businessDays = region === Region.US ? 3 : 14
  let date = addBusinessDays(DateTime.now(), businessDays)

  while (isWeekend(date) || isHoliday(date)) {
    date = date.plus({ days: 1 })
  }

  return { date, businessDays: businessDays, region }
}
