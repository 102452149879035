import { SvgIcon, SvgIconProps } from '@mui/material'

export default function BonusAddIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="11.2188" fill="#EBEDF6" stroke="#EBEDF6" strokeWidth="1.5625" />
      <rect x="5" y="4" width="12" height="14" fill="white" />
      <path
        d="M19 12.09V6C19 3.79 15.42 2 11 2C6.58 2 3 3.79 3 6V16C3 18.21 6.59 20 11 20C11.46 20 11.9 20 12.33 19.94C12.114 19.3159 12.0025 18.6604 12 18V17.95C11.68 18 11.35 18 11 18C7.13 18 5 16.5 5 16V13.77C6.61 14.55 8.72 15 11 15C11.65 15 12.27 14.96 12.88 14.89C13.413 14.0088 14.1642 13.2799 15.061 12.7736C15.9579 12.2674 16.9701 12.0009 18 12C18.34 12 18.67 12.04 19 12.09ZM17 11.45C15.7 12.4 13.42 13 11 13C8.58 13 6.3 12.4 5 11.45V8.64C6.47 9.47 8.61 10 11 10C13.39 10 15.53 9.47 17 8.64V11.45ZM11 8C7.13 8 5 6.5 5 6C5 5.5 7.13 4 11 4C14.87 4 17 5.5 17 6C17 6.5 14.87 8 11 8ZM22 17V19H19V22H17V19H14V17H17V14H19V17H22Z"
        fill="#F3C94E"
      />
      <circle cx="18" cy="18" r="6" fill="#324AA8" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19 14H17V17H14V19H17V22H19V19H22V17H19V14Z" fill="white" />
    </SvgIcon>
  )
}
