import {
  CampaignStatus,
  CoarseCampaignStatus,
  StateChangeSource,
  UserCampaignAcceptanceStatus,
  Selector,
  InputType,
  GraphQLTypes
} from '@productwindtom/shared-ws-zeus-types'
import { FLOW_DEFINITIONS, Flow, FlowDefinition } from '../../configs/flows'
import { getCoarseCampaignStatusForCampaignStatus, getCampaignAcceptanceStatusCampaignStatus } from './state-machine'

const userCampaignStatueInputSelector = Selector('UserCampaign')({
  campaignStatus: true,
  campaignId: true,
  statusHistory: true,
  campaignStatusOnArchive: true,
  isAutoApproved: true,
  isInstantJoin: true,
  selectedFlow: true,
  campaign: {
    campaignStateMachine: true
  }
})

export type StateChangeInput = InputType<GraphQLTypes['UserCampaign'], typeof userCampaignStatueInputSelector>

type UserCampaignWithFlow = {
  selectedFlow?: string // This is passed in flow, not the one on the userCampaign since that one is deprecated
  product?: { campaignStateMachine?: string }
  campaign?: { campaignStateMachine?: string }
}

export class StateMachineFactory {
  private readonly currentFlow: FlowDefinition

  constructor(flow: Flow) {
    // TODO create union of constants for flow, change into record
    const foundFlow = FLOW_DEFINITIONS[flow]
    if (!foundFlow) {
      throw new Error(`Could not find flow for ${flow}`)
    }
    this.currentFlow = foundFlow
  }

  static fromUserCampaign(userCampaign: UserCampaignWithFlow) {
    return new StateMachineFactory(getFlow(userCampaign))
  }

  getStateIndex(state: CampaignStatus): number {
    const foundIndex = this.currentFlow.findIndex(ele => {
      return ele.includes(state)
    })
    if (foundIndex === -1) {
      console.log(
        'Tried to find state in currentFlow and it was not found. This is either a code bug or user campaign issue.',
        this.currentFlow,
        state
      )
      throw Error('Could not find state of the user campaign')
    }
    return foundIndex
  }

  private getState({ state, defect }: { state: CampaignStatus; defect?: string }): CampaignStatus {
    const foundIndex = this.getStateIndex(state)
    const innerIndex = defect ? 1 : 0
    return this.currentFlow[foundIndex][innerIndex]
  }

  getNextState({
    currentState,
    defect
  }: {
    currentState: CampaignStatus
    defect?: string
  }): CampaignStatus | undefined {
    const foundIndex = this.getStateIndex(currentState)
    const newIndex = foundIndex + 1
    const innerIndex = defect ? 1 : 0
    return this.currentFlow[newIndex]?.[innerIndex]
  }

  static getStateAndUpdateTime(
    userCampaign: StateChangeInput,
    campaignStatus: CampaignStatus,
    source?: StateChangeSource,
    requestById?: string
  ) {
    // We do this to ensure the state is in the flow
    const state = new StateMachineFactory(getFlow(userCampaign)).getState({ state: campaignStatus })

    return {
      coarseCampaignStatus: getCoarseCampaignStatusForCampaignStatus(state),
      campaignAcceptanceStatus: getCampaignAcceptanceStatusCampaignStatus(
        userCampaign.campaignStatus!,
        campaignStatus,
        userCampaign.statusHistory
      ),
      campaignStatus: state,
      statusHistory: JSON.stringify(this.getUpdatedStatusHistory(userCampaign, state, source, requestById))
    }
  }

  static getUpdatedStatusHistory(
    userCampaign: StateChangeInput,
    campaignStatus?: string,
    source?: StateChangeSource,
    requestById?: string
  ) {
    const time = Date.now()
    const statusHistory = (function () {
      if (userCampaign.statusHistory) {
        try {
          const parsedArray = JSON.parse(userCampaign.statusHistory)
          if (Array.isArray(parsedArray)) {
            return parsedArray
          }
          return []
        } catch (e) {
          console.log(e)
          return []
        }
      }
      return []
    })()

    statusHistory.push({
      campaignStatus,
      time,
      source,
      createdById: requestById
    })
    return statusHistory
  }

  // Command Pattern
  static getNextStateAndUpdateTime(
    userCampaign: StateChangeInput,
    defect?: string,
    source?: StateChangeSource,
    requestById?: string
  ) {
    const currentState = getPropertyOrThrow(userCampaign.campaignStatus, CampaignStatus)

    const campaignStatus = new StateMachineFactory(getFlow(userCampaign)).getNextState({
      currentState: currentState,
      defect
    })

    if (!campaignStatus) {
      throw new Error(`Could not find next state for ${currentState}`)
    }

    return {
      coarseCampaignStatus: getCoarseCampaignStatusForCampaignStatus(campaignStatus),
      campaignAcceptanceStatus: getCampaignAcceptanceStatusCampaignStatus(
        userCampaign.campaignStatus!,
        campaignStatus,
        userCampaign.statusHistory,
        !!defect
      ),
      campaignStatus,
      statusHistory: JSON.stringify(this.getUpdatedStatusHistory(userCampaign, campaignStatus, source, requestById))
    }
  }

  // Auto joining means putting them into the purchasing state (discovery_v2)
  static autoJoinAndUpdateTime(userCampaign: StateChangeInput, source?: StateChangeSource, requestById?: string) {
    const campaignStatus = CampaignStatus.discovery_v2
    return {
      campaignStatus,
      coarseCampaignStatus: CoarseCampaignStatus.active,
      campaignAcceptanceStatus: UserCampaignAcceptanceStatus.PARTICIPANT,
      statusHistory: JSON.stringify(this.getUpdatedStatusHistory(userCampaign, campaignStatus, source, requestById))
    }
  }

  static resurrect(userCampaign: StateChangeInput, source?: StateChangeSource, requestById?: string) {
    const campaignStatus = userCampaign.campaignStatusOnArchive
      ? (userCampaign.campaignStatusOnArchive as CampaignStatus)
      : CampaignStatus.application_submitted

    return {
      campaignStatus,
      statusHistory: JSON.stringify(this.getUpdatedStatusHistory(userCampaign, campaignStatus, source, requestById))
    }
  }

  getPriorState({
    currentState,
    defect
  }: {
    currentState: CampaignStatus
    defect?: string
  }): CampaignStatus | undefined {
    const newIndex =
      this.currentFlow.findIndex(ele => {
        return ele.includes(currentState)
      }) - 1
    const innerIndex = defect ? 1 : 0
    return this.currentFlow[newIndex]?.[innerIndex]
  }

  submitCampaignApplication() {
    return this.currentFlow[1][0]
  }

  static submitCampaignApplicationAndUpdateTime(
    userCampaign: StateChangeInput,
    source?: StateChangeSource,
    requestById?: string
  ) {
    let campaignStatus
    let coarseCampaignStatus
    if (userCampaign.isAutoApproved) {
      campaignStatus = CampaignStatus.purchase_holding_queue
      coarseCampaignStatus = CoarseCampaignStatus.queued_to_purchase
    } else if (userCampaign.isInstantJoin) {
      campaignStatus = CampaignStatus.application_approved
      coarseCampaignStatus = CoarseCampaignStatus.active
    } else {
      campaignStatus = new StateMachineFactory(getFlow(userCampaign)).submitCampaignApplication()
      coarseCampaignStatus = CoarseCampaignStatus.before_approval
    }

    return {
      campaignStatus,
      coarseCampaignStatus,
      campaignAcceptanceStatus: getCampaignAcceptanceStatusCampaignStatus(
        CampaignStatus.application_submitted,
        campaignStatus,
        userCampaign.statusHistory
      ),
      statusHistory: JSON.stringify(
        StateMachineFactory.getUpdatedStatusHistory(userCampaign, campaignStatus, source, requestById)
      )
    }
  }

  endCampaign({ defect }: { defect?: string }) {
    return this.currentFlow[this.currentFlow.length - 1][defect ? 1 : 0]
  }

  static endCampaignAndUpdateTime(
    userCampaign: StateChangeInput,
    defect?: string,
    source?: StateChangeSource,
    requestById?: string
  ) {
    const campaignStatus = new StateMachineFactory(getFlow(userCampaign)).endCampaign({ defect })

    return {
      campaignStatus,
      coarseCampaignStatus: CoarseCampaignStatus.archived,
      campaignAcceptanceStatus: getCampaignAcceptanceStatusCampaignStatus(
        userCampaign.campaignStatus!,
        campaignStatus,
        userCampaign.statusHistory,
        !!defect
      ),
      statusHistory: JSON.stringify(this.getUpdatedStatusHistory(userCampaign, campaignStatus, source, requestById))
    }
  }
}

function getFlow(userCampaign: UserCampaignWithFlow): Flow {
  const potentialFlow =
    userCampaign.selectedFlow ||
    userCampaign.product?.campaignStateMachine ||
    userCampaign?.campaign?.campaignStateMachine

  if (!potentialFlow) {
    throw new Error(`Could not find flow for ${JSON.stringify(userCampaign, null, 2)}`)
  }

  if (!Object.values(Flow).includes(potentialFlow as Flow)) {
    throw new Error(`This flow is not supported ${potentialFlow}`)
  }
  return potentialFlow as Flow
}

// Typesafe way of getting values from objects, should be used instead of bracket notation
const getPropertyOrThrow = <T>(key: string | undefined, t: T): T[keyof T] => {
  if (!key || !isValidKey<T>(key, t)) {
    throw new Error(`Key ${key} doesn't exist on ${JSON.stringify(t, null, 2)}`)
  }
  return t[key]
}

// TODO - Geoff, confirm t param is type
const isValidKey = <T>(value: string | number | symbol, t: any): value is keyof T => {
  return value in t
}
