import { createRef, useEffect, useState } from 'react'
import { UploadRequestType } from '@productwindtom/shared-momentum-zeus-types'
import useUploadFile from '@momentum/hooks/useUploadFile'
import { InputBase } from '@productwindtom/ui-base'
import { VariantInputBaseProps } from 'libs/ui/base/src/lib/forms/InputBase/types'
import { Box, ButtonProps, Stack, Typography } from '@mui/material'
import { useFormContext, useController } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { CheckCircleOutline, AudioFileOutlined, KeyboardVoiceOutlined } from '@mui/icons-material'
import { MEDIA_CDN } from '@momentum/env'
import AudioRecord from '../audio-record'

export enum AudioUploadVariant {
  UPLOAD = 'UPLOAD',
  RECORD = 'RECORD'
}

type AudioSelectProps = {
  uploadType: UploadRequestType
  companyId?: string
  brandId?: string
  buttonText?: string
  buttonIcon?: boolean | any
  replacementText?: string
  buttonProps?: ButtonProps
  readOnly?: boolean
  uploadVariant?: AudioUploadVariant
  direction?: 'row' | 'column'
  disableFileName?: boolean
}

export const AudioSelect = ({
  uploadType,
  companyId,
  brandId,
  buttonText,
  buttonIcon,
  replacementText = 'Click to replace',
  buttonProps,
  readOnly,
  uploadVariant = AudioUploadVariant.UPLOAD,
  direction = 'row',
  disableFileName,
  ...rest
}: VariantInputBaseProps & AudioSelectProps) => {
  const { control } = useFormContext()
  const { name } = { ...rest }
  const {
    field: { onChange, onBlur, value }
  } = useController({ name, control })

  const [isRecordActive, setIsRecordActive] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)
  const audioRef = createRef<HTMLInputElement>()

  useEffect(() => {
    if (value && uploadVariant === AudioUploadVariant.RECORD) {
      setIsRecordActive(true)
    }
  }, [value, uploadVariant])

  const uploadFile = useUploadFile()

  const handleAudioPick = async (file: File) => {
    setProcessing(true)
    const key = await uploadFile(file, uploadType, { name: 'name_audio_file', companyId, brandId })
    onChange(key)
    onBlur()
    setProcessing(false)
  }

  const playAudio = () => {
    if (value) {
      const audio = new Audio(MEDIA_CDN + value)
      audio.play()
    }
  }

  return (
    <InputBase {...rest}>
      <Stack direction={direction} spacing={2}>
        {!!value && !disableFileName && (
          <Stack direction={'row'} spacing={1} alignItems={'center'} onClick={playAudio} sx={{ cursor: 'pointer' }}>
            <CheckCircleOutline fontSize={'mSmall'} color={'success'} />
            <Typography variant={'label4'} color={theme => theme.palette.grey.A700}>
              {value.split('/').pop()}
            </Typography>
          </Stack>
        )}
        {!readOnly && (
          <Box width="100%">
            {!isRecordActive ? (
              <LoadingButton
                loading={processing}
                disabled={processing}
                type="button"
                onClick={() =>
                  uploadVariant === AudioUploadVariant.UPLOAD ? audioRef?.current?.click() : setIsRecordActive(true)
                }
                startIcon={
                  buttonIcon === true ? (
                    uploadVariant === AudioUploadVariant.UPLOAD ? (
                      <AudioFileOutlined />
                    ) : (
                      <KeyboardVoiceOutlined />
                    )
                  ) : (
                    buttonIcon
                  )
                }
                {...buttonProps}
                sx={{
                  ...(direction === 'column' && {
                    display: 'block',
                    padding: '20px 50px',
                    width: '100%'
                  }),
                  ...buttonProps?.sx
                }}
              >
                {value
                  ? replacementText
                  : buttonText
                    ? buttonText
                    : uploadVariant === AudioUploadVariant.UPLOAD
                      ? 'Click to upload audio'
                      : 'Click to record audio'}
              </LoadingButton>
            ) : (
              <AudioRecord
                audioRecordingUrl={value ? `${MEDIA_CDN}${value}` : undefined}
                acceptRecordingCallback={handleAudioPick}
                cancelRecordingCallback={() => {
                  onChange(null)
                  setIsRecordActive(false)
                }}
              />
            )}
          </Box>
        )}
        <input
          style={{ display: 'none' }}
          accept={'audio/*'}
          ref={audioRef}
          value={''}
          onChange={event => {
            if (event.target.files && event.target.files.length > 0) {
              setProcessing(true)
              handleAudioPick(event.target.files[0])
            }
          }}
          type="file"
        />
      </Stack>
    </InputBase>
  )
}
