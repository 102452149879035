import { Font, Document, Page, Text, StyleSheet, View } from '@react-pdf/renderer'
import React from 'react'
import { DateTime } from 'luxon'
import { InvoicePaymentType } from '@productwindtom/shared-momentum-zeus-types'

const styles = StyleSheet.create({
  page: {
    padding: 45
  },
  title1: {
    fontFamily: 'Avenir',
    fontSize: '14px'
  },
  body: {
    fontFamily: 'Avenir',
    fontSize: '11px'
  },
  body2: {
    fontFamily: 'Avenir',
    fontSize: '11px',
    fontWeight: 'heavy'
  },

  section: {
    textAlign: 'center',
    marginBottom: 15,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center'
  },
  column: { flexDirection: 'column' },
  row: { width: '100%', display: 'flex', flexDirection: 'row' },

  cellHeader: {
    textAlign: 'center',
    alignContent: 'center',
    padding: 5
  },
  firstCell: {
    textAlign: 'left',
    paddingLeft: 10,
    paddingVertical: 5,
    paddingRight: 5,
    width: '25%',
    borderTop: '1px solid black',
    borderRight: '1px solid black'
  },
  cell: {
    textAlign: 'left',
    paddingLeft: 5,
    width: '75%',
    paddingVertical: 5,
    paddingRight: 10,
    borderTop: '1px solid black'
  }
})

export const CampaignOrderFormPdf = ({
  productName,
  productPrice,
  invoiceNumber,
  paymentType,
  numCredits,
  numProductCostCredits,
  numCreatorCostCredits,
  contacts,
  companyName,
  paymentDueDate,
  productCostDueDate,
  numBrandAdvocates,
  numUgcCreators,
  numPremiumUgcCreators,
  numSocialCreators,
  startDate,
  endDate,
  clientContactName,
  clientContactEmail
}: {
  productName: string
  productPrice: string
  invoiceNumber?: string
  paymentType: InvoicePaymentType
  numCredits: number
  numProductCostCredits: number
  numCreatorCostCredits: number
  companyName: string
  contacts: string[]
  paymentDueDate?: string
  productCostDueDate?: string
  numBrandAdvocates: number
  numUgcCreators: number
  numPremiumUgcCreators: number
  numSocialCreators: number
  startDate: string
  endDate: string
  clientContactName: string
  clientContactEmail: string
}) => {
  Font.register({
    family: 'Avenir',
    fonts: [
      {
        src: '/fonts/Avenir/AvenirRegular.ttf'
      },
      {
        src: '/fonts/Avenir/AvenirHeavy.ttf',
        fontWeight: 'heavy'
      }
    ]
  })

  const contactsString =
    contacts.length > 2
      ? [contacts.slice(0, contacts.length - 1).join(', '), contacts.pop()].join(', and ')
      : contacts.join(' and ')

  const isCredits = paymentType === InvoicePaymentType.CREDITS

  const subjectString = isCredits
    ? 'The Campaign is subject to the Momentum Platform Terms and Conditions.'
    : 'The Campaign and Client’s purchase and use of Credits is subject to the Momentum Platform Terms and Conditions.'

  const numCreators = numBrandAdvocates + numUgcCreators + numPremiumUgcCreators + numSocialCreators

  return (
    <Document
      pageLayout="singlePage"
      pageMode="useNone"
      title={`ProductWind Campaign Receipt`}
      author="Momentum"
      creator="Momentum"
      creationDate={DateTime.now().toJSDate()}
      language="en"
    >
      <Page style={styles.page}>
        <View style={[styles.section]}>
          <Text style={[styles.title1]}>Campaign Receipt</Text>
        </View>
        <View style={[styles.section]}>
          <Text style={[styles.body, { textAlign: 'justify' }]}>
            This Campaign Receipt (the “<Text style={[styles.body2, { fontWeight: 'heavy' }]}>Campaign Receipt</Text>
            ”) is provided to the {companyName}, (“
            <Text style={styles.body2}>Client</Text>”) by ProductWind, Inc, a Delaware corporation (“
            <Text style={styles.body2}>ProductWind</Text>”). {subjectString}
          </Text>
        </View>
        <View style={[{ flexDirection: 'column', border: '1px solid black' }]}>
          <View style={[styles.cellHeader]}>
            <Text style={[styles.body2, { textDecoration: 'underline' }]}>DRAFT CAMPAIGN RECEIPT</Text>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Campaign</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body, { marginBottom: '11px' }]}>
                Activation of {numCreators.toLocaleString()} Creators.
              </Text>
              {numBrandAdvocates && (
                <Text style={[styles.body]}>{numBrandAdvocates.toLocaleString()} Brand Advocates</Text>
              )}
              {numUgcCreators && <Text style={[styles.body]}>{numUgcCreators.toLocaleString()} UGC Creators</Text>}
              {numPremiumUgcCreators && (
                <Text style={[styles.body]}>{numPremiumUgcCreators.toLocaleString()} Premium UGC Creators</Text>
              )}
              {numSocialCreators && (
                <Text style={[styles.body]}>{numSocialCreators.toLocaleString()} Social Creators</Text>
              )}
            </View>
          </View>
          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Product</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>
                {productName} (Selling price: {productPrice})
              </Text>
            </View>
          </View>
          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Cost (in Credits)</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>{numCredits.toLocaleString()} Credits</Text>
            </View>
          </View>

          {!isCredits && (
            <View style={[styles.row]}>
              <View style={[styles.firstCell]}>
                <Text style={[styles.body2]}>Credits</Text>
              </View>
              <View style={[styles.cell]}>
                <Text style={[styles.body]}>
                  In exchange for the payment set forth herein, Client will receive {numCredits.toLocaleString()}{' '}
                  Credits redeemable on ProductWind’s Momentum Platform.
                </Text>
              </View>
            </View>
          )}

          {!isCredits && !productCostDueDate && (
            <View style={[styles.row]}>
              <View style={[styles.firstCell]}>
                <Text style={[styles.body2]}>Credit Cost</Text>
              </View>
              <View style={[styles.cell]}>
                <Text style={[styles.body]}>
                  ${numCredits.toLocaleString(undefined, { minimumFractionDigits: 2 })} ($1.00 per Credit)
                </Text>
              </View>
            </View>
          )}
          {!isCredits && !!productCostDueDate && (
            <>
              <View style={[styles.row]}>
                <View style={[styles.firstCell]}>
                  <Text style={[styles.body2]}>Creator Costs</Text>
                </View>
                <View style={[styles.cell]}>
                  <Text style={[styles.body]}>
                    ${numCreatorCostCredits.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                  </Text>
                </View>
              </View>
              <View style={[styles.row]}>
                <View style={[styles.firstCell]}>
                  <Text style={[styles.body2]}>Product Costs</Text>
                </View>
                <View style={[styles.cell]}>
                  <Text style={[styles.body]}>
                    ${numProductCostCredits.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                  </Text>
                </View>
              </View>
              <View style={[styles.row]}>
                <View style={[styles.firstCell]}>
                  <Text style={[styles.body2]}>Total Campaign Cost</Text>
                </View>
                <View style={[styles.cell]}>
                  <Text style={[styles.body]}>
                    ${numCredits.toLocaleString(undefined, { minimumFractionDigits: 2 })} ($1.00 per Credit)
                  </Text>
                </View>
              </View>
            </>
          )}

          {!isCredits && (
            <View style={[styles.row]}>
              <View style={[styles.firstCell]}>
                <Text style={[styles.body2]}>Payment Date</Text>
              </View>
              <View style={[styles.cell]}>
                {!productCostDueDate && paymentDueDate && (
                  <Text style={[styles.body]}>The total payment is due no later than {paymentDueDate}.</Text>
                )}
                {productCostDueDate && paymentDueDate && (
                  <Text style={[styles.body]}>
                    Payment for creator costs is due in full no later than {paymentDueDate}.
                  </Text>
                )}
                {productCostDueDate && (
                  <Text style={[styles.body]}>
                    Payment for product costs is due in full no later than {productCostDueDate}.
                  </Text>
                )}
              </View>
            </View>
          )}

          {!isCredits && (
            <View style={[styles.row]}>
              <View style={[styles.firstCell]}>
                <Text style={[styles.body2]}>Payment Details</Text>
              </View>
              <View style={[styles.cell]}>
                <Text style={[styles.body]}>
                  All payments shall be made in United States dollars. All credit purchases are non-refundable.
                </Text>
              </View>
            </View>
          )}

          {!isCredits && (
            <View style={[styles.row]}>
              <View style={[styles.firstCell]}>
                <Text style={[styles.body2]}>Payment Method</Text>
              </View>
              <View style={[styles.cell]}>
                <Text style={[styles.body]}>See invoice for accepted payment methods.</Text>
              </View>
            </View>
          )}

          {!isCredits && (
            <View style={[styles.row]}>
              <View style={[styles.firstCell]}>
                <Text style={[styles.body2]}>Billing Contacts</Text>
              </View>
              <View style={[styles.cell]}>
                <Text style={[styles.body]}>
                  The corresponding invoice(s) {invoiceNumber ? `#${invoiceNumber} has been` : 'will be'} sent to{' '}
                  {contactsString}
                </Text>
              </View>
            </View>
          )}

          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Estimated Campaign Start Date</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>{startDate}</Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Estimate Campaign End Date</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>{endDate}</Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Client Contact</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>{clientContactEmail}</Text>
              <Text style={[styles.body]}>Attention: {clientContactName}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
