import { RocketLaunchOutlined, AssignmentTurnedInOutlined, Close, EditOutlined } from '@mui/icons-material'
import { Chip, Dialog, Stack, Typography, IconButton, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import React, { useState } from 'react'
import { DateTime } from 'luxon'
import { Form, SubmitButton, DateInput } from '@productwindtom/ui-base'
import { BackButton } from '@momentum/components/back-button'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

type SubscriptionBarProps = {
  name: string
  startDateTime?: string
  endDateTime?: string
  onActivate?: (startDate: string, endDate: string) => Promise<void>
  onEdit?: (startDate: string, endDate: string) => Promise<void>
  onDeactivate?: () => Promise<void>
}

export const SubscriptionBar = ({
  name,
  startDateTime,
  endDateTime,
  onEdit,
  onDeactivate,
  onActivate
}: SubscriptionBarProps) => {
  const [isActivateOpen, setIsActivateOpen] = useState(false)
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [isDeactivateOpen, setIsDeactivateOpen] = useState(false)
  const [isDeactivateLoading, setIsDeactivateLoading] = useState(false)

  const now = DateTime.now()
  const startDateTimeObj = startDateTime ? DateTime.fromISO(startDateTime) : undefined
  const endDateTimeObj = endDateTime ? DateTime.fromISO(endDateTime) : undefined
  const isActive = startDateTimeObj && now > startDateTimeObj && (!endDateTimeObj || now < endDateTimeObj)
  const hasStarted = startDateTimeObj && now > startDateTimeObj

  const handleActivateSubmit = async (startDate: DateTime, endDate: DateTime) => {
    if (onActivate) {
      await onActivate(startDate.toJSDate().toISOString(), endDate.toJSDate().toISOString())
      toast(<Typography variant={'subtitle2'}>Your subscription is now active!</Typography>, { type: 'success' })
    }
    handleActivateClose()
  }

  const handleActivateClose = () => {
    setIsActivateOpen(false)
  }

  const handleEditSubmit = async (startDate: DateTime, endDate: DateTime) => {
    if (onEdit) {
      await onEdit(startDate.toJSDate().toISOString(), endDate.toJSDate().toISOString())
      toast(<Typography variant={'subtitle2'}>Your subscription is now active!</Typography>, { type: 'success' })
    }
    handleEditClose()
  }

  const handleEditClose = () => {
    setIsEditOpen(false)
  }

  const handleDeactivateSubmit = async () => {
    setIsDeactivateLoading(true)
    if (onDeactivate) {
      await onDeactivate()
      toast(<Typography variant={'subtitle2'}>Your subscription has been deactivated!</Typography>, { type: 'success' })
    }
    setIsDeactivateLoading(false)
    handleDeactivateClose()
  }

  const handleDeactivateClose = () => {
    setIsDeactivateOpen(false)
  }

  return (
    <Stack spacing={2} alignItems={'flex-end'}>
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        {!isActive && <Chip label={'Inactive subscription'} />}
        {!isActive && (
          <Button variant={'outlined'} onClick={() => setIsActivateOpen(true)}>
            Activate subscription
          </Button>
        )}
        {isActive && (
          <Button variant={'outlined'} onClick={() => setIsDeactivateOpen(true)}>
            Deactivate subscription
          </Button>
        )}
        {isActive && (
          <Button variant={'text'} onClick={() => setIsEditOpen(true)} startIcon={<EditOutlined />}>
            Edit subscription
          </Button>
        )}
      </Stack>
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        {!isActive && !!startDateTimeObj && (
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <RocketLaunchOutlined sx={{ color: theme => theme.palette.grey.A700 }} />
            <Typography variant={'label3'}>
              {!isActive && !hasStarted ? 'Starting' : 'Started'} {startDateTimeObj.toLocaleString(DateTime.DATE_SHORT)}
            </Typography>
          </Stack>
        )}
        {!isActive && !!endDateTimeObj && (
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <AssignmentTurnedInOutlined sx={{ color: theme => theme.palette.grey.A700 }} />
            <Typography variant={'label3'}>
              {isActive || !hasStarted ? 'Ending' : 'Ended'} {endDateTimeObj.toLocaleString(DateTime.DATE_SHORT)}
            </Typography>
          </Stack>
        )}
      </Stack>

      <Dialog open={isActivateOpen} onClose={handleActivateClose}>
        <Stack p={3} spacing={2}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={'h4'}>Activate subscription for {name}</Typography>
            <IconButton onClick={handleActivateClose}>
              <Close />
            </IconButton>
          </Stack>
          <SubscriptionForm
            name={name}
            submitButtonText={'Activate subscription'}
            onSubmit={d => handleActivateSubmit(d.startDate, d.endDate)}
            onCancel={handleActivateClose}
          />
        </Stack>
      </Dialog>

      <Dialog open={isEditOpen} onClose={handleEditClose}>
        <Stack p={3} spacing={2}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={'h4'}>Edit subscription for {name}</Typography>
            <IconButton onClick={handleEditClose}>
              <Close />
            </IconButton>
          </Stack>
          <SubscriptionForm
            name={name}
            submitButtonText={'Edit subscription'}
            onSubmit={d => handleEditSubmit(d.startDate, d.endDate)}
            onCancel={handleEditClose}
            initialValues={
              startDateTimeObj && endDateTimeObj ? { startDate: startDateTimeObj, endDate: endDateTimeObj } : undefined
            }
          />
        </Stack>
      </Dialog>

      <Dialog open={isDeactivateOpen} onClose={handleDeactivateClose}>
        <Stack p={3} spacing={2}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={'h4'}>Are you sure you want to deactivate your subscription for {name}?</Typography>
            <IconButton onClick={handleDeactivateClose}>
              <Close />
            </IconButton>
          </Stack>
          <Typography variant={'label3'}>
            {name} users will no longer have access to the Momentum dashboard effective immediately. The subscription
            end will be set to {DateTime.now().toLocaleString(DateTime.DATE_MED)}.
          </Typography>
          <Stack direction={'row'} justifyContent={'flex-end'} spacing={2} pt={2}>
            <Button variant={'text'} onClick={handleDeactivateClose}>
              No, go back
            </Button>
            <LoadingButton variant={'contained'} loading={isDeactivateLoading} onClick={handleDeactivateSubmit}>
              Yes, I want to deactivate
            </LoadingButton>
          </Stack>
        </Stack>
      </Dialog>
    </Stack>
  )
}

const schema = yup.object().shape({
  startDate: yup.mixed<DateTime>().required('Required'),
  endDate: yup.mixed<DateTime>().required('Required')
})

type SubscriptionFormData = {
  startDate: DateTime
  endDate: DateTime
}
const SubscriptionForm = ({
  onSubmit,
  submitButtonText,
  onCancel,
  name,
  initialValues
}: {
  name: string
  submitButtonText: string
  onCancel: () => void
  onSubmit: (data: SubscriptionFormData) => Promise<void>
  initialValues?: SubscriptionFormData
}) => {
  const handleSubmit = async (submitValues: SubscriptionFormData) => {
    await onSubmit(submitValues)
  }

  const defaultValues = initialValues || { startDate: DateTime.now(), endDate: DateTime.now().plus({ months: 6 }) }
  return (
    <Form onSubmit={handleSubmit} defaultValues={defaultValues} resolver={yupResolver(schema)}>
      <Stack spacing={3}>
        <Typography variant={'label3'}>
          {name} users will have access to the Momentum dashboard effective immediately.
        </Typography>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <DateInput name={'startDate'} primaryText={'Subscription start date'} />
          <DateInput name={'endDate'} primaryText={'Subscription end date'} />
        </Stack>
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} pt={2}>
          <BackButton variant={'text'} onClick={onCancel} text={'No, go back'} />
          <SubmitButton variant={'contained'} disableOnDirty={false}>
            {submitButtonText}
          </SubmitButton>
        </Stack>
      </Stack>
    </Form>
  )
}
