import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { createProposal, updateProposal } from '@momentum/routes/proposals-create/mutations'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { convertToCreatorPricing } from '@momentum/utils/proposalUtils'
import { MANAGED_SERVICE_CREATOR_TYPES } from '@productwindtom/shared-momentum'
import { ProposalStatus, ValueTypes } from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { uniqBy } from 'lodash'
import { v4 } from 'uuid'

export const useSaveProposal = (proposalId?: string) => {
  const { profile, isAdminView } = useUserSessionContext()
  const { setProposals } = useBrandContext()

  const handleSubmit = async (submitValues: ProposalCreateForm) => {
    const input: ValueTypes['ModelInputCreateProposal'] | ValueTypes['ModelInputUpdateProposal'] = {
      id: proposalId || v4(),
      title: submitValues.title,
      brandId: submitValues.brandId,
      durationWeeks: submitValues.durationWeeks,
      goal: submitValues.goal,
      estimatedUnitsSoldPerWeek: submitValues.estimatedUnitsSoldPerWeek,
      estimatedUnitsSoldPerMonth: submitValues.estimatedUnitsSoldPerMonth,
      productId: submitValues.productId,
      exchangeRate: submitValues.exchangeRate,

      managedServiceFee: submitValues.managedServiceFeeEnabled ? submitValues.managedServiceFee : null,
      subscriptionFee: submitValues.subscriptionFeeEnabled ? submitValues.subscriptionFee : null,
      subscriptionTerm: submitValues.subscriptionFeeEnabled ? submitValues.subscriptionTerm : null,

      productVariationSkus: submitValues.productVariationSkus,
      productPrice: submitValues.productPrice,
      productRating: submitValues.productRating,
      productRatingCount: submitValues.productRatingCount,
      productPriceOverride: submitValues.productPriceOverride ? submitValues.productPriceOverride : null,

      status: submitValues.isVisibleToClient ? ProposalStatus.PUBLISHED : ProposalStatus.DRAFT,
      statusChangedAt: submitValues.statusChangedAt || new Date().toISOString(),
      launchDate: submitValues.launchDate.toISODate()!,
      eventDate: submitValues.eventDate?.toISODate(),

      searchTerms: submitValues.searchTerms.filter(notEmpty),
      benchmarkProducts: submitValues.benchmarkProducts.filter(notEmpty),

      paymentType: submitValues.paymentType,

      invoiceLink: submitValues.invoiceLink,
      ...(!!submitValues.invoiceDueDate && { invoiceDueDate: submitValues.invoiceDueDate.toISODate() }),
      ...(!!submitValues.invoicePaidDate && { invoicePaidDate: submitValues.invoicePaidDate.toISODate() }),
      ...(!!submitValues.invoiceStatus && { invoiceStatus: submitValues.invoiceStatus }),
      invoicePONumber: submitValues.invoicePONumber,

      invoiceProductCostLink: submitValues.invoiceProductCostLink,
      ...(!!submitValues.invoiceProductCostDueDate && {
        invoiceProductCostDueDate: submitValues.invoiceProductCostDueDate.toISODate()
      }),
      ...(!!submitValues.invoiceProductCostPaidDate && {
        invoiceProductCostPaidDate: submitValues.invoiceProductCostPaidDate.toISODate()
      }),
      ...(!!submitValues.invoiceProductCostStatus && {
        invoiceProductCostStatus: submitValues.invoiceProductCostStatus
      }),
      invoiceProductCostPONumber: submitValues.invoiceProductCostPONumber,

      invoicePOSystem: submitValues.invoicePOSystem,
      invoiceAdditionalInformation: submitValues.invoiceAdditionalInformation,

      ...(!!submitValues.paymentBillingContact?.email &&
        !!submitValues.paymentBillingContact?.name && {
          paymentBillingContact: submitValues.paymentBillingContact
        }),

      billingContacts: submitValues.billingContacts.filter(bc => bc.name && bc.email),
      isDailyScheduling: submitValues.isDailyScheduling,
      creatorPricing: convertToCreatorPricing(
        submitValues.creatorPricing.map(p => ({
          type: p.type,
          price: p.price,
          numCreators: p.numCreators,
          schedule: p.schedule,
          contentRequirements: p?.contentRequirements,
          closeoutBonus: p.closeoutBonus,
          socialCreatorPricing: p.socialCreatorPricing?.map(sp => ({
            ...sp,
            hidden:
              sp.hidden ||
              // automatically hide if an admin user set numCreators to 0, this prevents item from hiding from the brand for the same user that's hiding it
              (isAdminView && !sp.numCreators)
          })),
          hidden: p.hidden || (isAdminView && !p.numCreators && MANAGED_SERVICE_CREATOR_TYPES.includes(p.type))
        }))
      ),

      totalCredits: submitValues.totalCredits,
      totalProductCostCredits: submitValues.totalProductCostCredits,

      updatedByUserId: profile.id,
      ...(!proposalId && { createdByUserId: profile.id }),

      estimatedAverageRating: submitValues.estimatedAverageRating,
      ratingGoal: submitValues.ratingGoal,
      hiddenNumReviews: submitValues.hiddenNumReviews,
      hiddenEndRating: submitValues.hiddenEndRating,
      hiddenPdpTraffic: submitValues.hiddenPdpTraffic,
      hiddenRetailEfficiency: submitValues.hiddenRetailEfficiency,
      hiddenSeo: submitValues.hiddenSeo,
      hiddenSocialContent: submitValues.hiddenSocialContent,
      hiddenUgcContent: submitValues.hiddenUgcContent,
      referralCost: submitValues.referralCost,
      operatingCost: submitValues.operatingCost,
      liftNumCreators: submitValues.liftNumCreators,
      liftCloseoutBonus: submitValues.liftCloseoutBonus,

      hubspotDealId: submitValues.hubspotDealLink
        ? new URL(submitValues.hubspotDealLink).pathname.split('/').pop()
        : undefined
    }

    const newProposal = proposalId
      ? await updateProposal(input)
      : await createProposal(input as ValueTypes['ModelInputCreateProposal'])

    setProposals(proposals => uniqBy([newProposal, ...(proposals || [])], 'id'))

    return newProposal
  }

  return { saveProposal: handleSubmit }
}
