import { yupResolver } from '@hookform/resolvers/yup'
import { BackButton } from '@momentum/components/back-button'
import { useSubscriptionContext } from '@momentum/contexts/Subscription'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { BuyCreditsOrderFormPdf } from '@momentum/utils/order-forms/BuyCreditsOrderFormPdf'
import { DEFAULT_NET_TERMS } from '@momentum/utils/proposalUtils'
import { Add, CalendarTodayOutlined, Cancel, ContactPageOutlined, EmailOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, Box, Button, CircularProgress, Link, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { PaymentTermsType, SubscriptionLevel } from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty, toLocaleCurrency } from '@productwindtom/shared-node'
import { InvoiceMethod } from '@productwindtom/shared-ws-zeus-types'
import { Form, NumberInput, TextInput } from '@productwindtom/ui-base'
import { pdf } from '@react-pdf/renderer'
import { DateTime } from 'luxon'
import { useFieldArray, useFormContext } from 'react-hook-form'
import * as yup from 'yup'
import BonusCheckIcon from '@momentum/components/icons/bonus-check'

export const schema = yup
  .object({
    invoiceMethod: yup.mixed<InvoiceMethod>().optional(),
    numCredits: yup.number().min(1, 'Credits is required').required('Credits is required'),
    paymentDueDate: yup.mixed<DateTime>().required('Required'),
    paymentBillingContact: yup
      .object({
        email: yup.string().email('Invalid email').required('Required'),
        name: yup.string().required('Required')
      })
      .required('Required'),
    billingContacts: yup
      .array()
      .of(
        yup.object({
          email: yup.string().email('Invalid email'),
          name: yup.string()
        })
      )
      .required('Required')
      .test(
        'min valid',
        'You must enter at least one billing email and contact name in order to schedule the campaign.',
        v => !!v && v.some(r => r.email && r.name)
      ),
    invoicePONumber: yup.string().when('invoiceMethod', {
      is: InvoiceMethod.CUSTOM_PO,
      then: s => s.required('Required'),
      otherwise: s => s.optional()
    })
  })
  .noUnknown(true)

export type BuyCreditsFormData = {
  invoiceMethod?: InvoiceMethod
  numCredits: number
  paymentDueDate: DateTime
  invoicePONumber?: string
  invoicePOSystem?: string
  invoiceAdditionalInformation?: string
  paymentBillingContact: {
    email: string
    name: string
  }
  billingContacts: {
    email?: string
    name?: string
  }[]
}

const READ_ONLY_CONTACT = 'billing@productwind.com'

export const BuyCreditsFormV2 = ({
  numCredits,
  onSubmit,
  onCancel,
  onManuallyAddCreditsClick
}: {
  numCredits?: number // preset the number of credits
  onCancel: () => void
  onSubmit: (data: BuyCreditsFormData) => Promise<void>
  onManuallyAddCreditsClick?: () => void
}) => {
  const { agency, selectedBrand, selectedCompany, profile } = useUserSessionContext()
  const { subscriptionLevel } = useSubscriptionContext()

  const handleSubmit = async (submitValues: BuyCreditsFormData) => {
    await onSubmit(submitValues)
  }

  const agencyTerms = agency?.paymentTermsType
  const netTermsType = agencyTerms || selectedCompany?.paymentTermsType
  const netTerms =
    netTermsType === PaymentTermsType.NET_CUSTOM
      ? agency?.paymentTermsCustomNetDays || selectedCompany?.paymentTermsCustomNetDays || 0
      : DEFAULT_NET_TERMS

  const paymentDueDate =
    netTermsType === PaymentTermsType.NET_CUSTOM
      ? DateTime.now().plus({ days: netTerms })
      : DateTime.now().plus({ days: 1 })

  const billingContact =
    subscriptionLevel === SubscriptionLevel.COMPANY
      ? selectedCompany?.paymentBillingContact || selectedBrand?.paymentBillingContact
      : selectedBrand?.paymentBillingContact

  const defaultValues = {
    numCredits,
    invoiceMethod: selectedCompany?.invoiceMethod || InvoiceMethod.BILL,
    paymentDueDate,
    paymentBillingContact: {
      email: billingContact?.email || '',
      name: billingContact?.name || ''
    },
    billingContacts: [
      {
        email: READ_ONLY_CONTACT,
        name: 'ProductWind Billing'
      },
      {
        email: profile.email,
        name: `${profile.firstName} ${profile.lastName}`.trim()
      }
    ]
  }

  return (
    <Form onSubmit={handleSubmit} defaultValues={defaultValues} resolver={yupResolver(schema)}>
      <Stack spacing={3}>
        <Stack>
          <FormBody
            onCancel={onCancel}
            onSubmit={handleSubmit}
            onManuallyAddCreditsClick={onManuallyAddCreditsClick}
            netTerms={netTerms}
            netTermsType={netTermsType}
          />
        </Stack>
      </Stack>
    </Form>
  )
}

const FormBody = ({
  onCancel,
  onSubmit,
  onManuallyAddCreditsClick,
  netTerms,
  netTermsType
}: {
  onCancel: () => void
  onSubmit: (submitValues: BuyCreditsFormData) => Promise<void>
  onManuallyAddCreditsClick?: () => void
  netTerms: number
  netTermsType?: PaymentTermsType
}) => {
  const { isAdminView, selectedBrand, selectedCompany } = useUserSessionContext()
  const { subscriptionLevel, overdueCreditActions } = useSubscriptionContext()

  const resourceName = subscriptionLevel === SubscriptionLevel.COMPANY ? selectedCompany?.name : selectedBrand?.name

  const {
    watch,
    formState: { isValid, isSubmitting, errors },
    handleSubmit
  } = useFormContext<BuyCreditsFormData>()

  const formValues = watch()

  const fieldsWithErrors = Object.keys(errors)
  // make invoicePONumber optional for pdf generation
  const isPdfDownloadEnabled = isValid || (fieldsWithErrors.length === 1 && fieldsWithErrors[0] === 'invoicePONumber')

  const generatePdf = async () => {
    const pdfDocument = (
      <BuyCreditsOrderFormPdf
        companyName={selectedCompany?.name || ''}
        contacts={formValues.billingContacts.map(bc => bc.email).filter(notEmpty)}
        clientContactEmail={formValues.paymentBillingContact?.email || ''}
        clientContactName={formValues.paymentBillingContact?.name || ''}
        numCredits={formValues.numCredits}
        paymentDueDate={formValues.paymentDueDate.toLocaleString(DateTime.DATE_SHORT)!}
      />
    )

    await pdf(pdfDocument)
      .toBlob()
      .then(blob => {
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `draft-credits-receipt.pdf`
        a.click()
      })
  }

  return (
    <Stack spacing={3}>
      <NumberInput
        inputProps={{ 'data-cy': 'numCredits' }}
        returnAsNumber
        decimalScale={0}
        name={'numCredits'}
        primaryText={`Enter the amount of credits you want to buy:`}
        subtext={
          <Typography variant="label3" color={'grey.A700'}>
            One credit costs $1.00
          </Typography>
        }
      />

      <Stack spacing={1}>
        <Typography variant={'label3'}>{selectedBrand?.name} will receive:</Typography>
        <Stack direction={'row'} spacing={0.5} alignContent={'center'} alignItems={'center'}>
          <BonusCheckIcon />
          <Typography variant={'label1'}>
            {formValues.numCredits ? formValues.numCredits.toLocaleString() : '--'}
          </Typography>
          <Typography variant={'label3'}>
            {netTermsType === PaymentTermsType.NET_CUSTOM ? 'credits today' : 'credits as soon as the invoice is paid'}
          </Typography>
        </Stack>
      </Stack>

      <Stack spacing={1}>
        <Typography variant={'label3'}>The invoice for credits is due on:</Typography>
        <Typography variant={'label3'} color={'grey.A700'}>
          {netTermsType === PaymentTermsType.NET_CUSTOM
            ? `Credits will be deposited immediately and ${resourceName} will be invoiced on net ${netTerms} payment terms.`
            : 'Credits will be deposited as soon as ProductWind receives payment.'}
        </Typography>

        <Stack direction={'row'} spacing={1} alignContent={'center'} alignItems={'center'}>
          <CalendarTodayOutlined fontSize={'medium'} color={'primary'} />
          <Typography variant={'label3'}>{formValues.paymentDueDate.toLocaleString(DateTime.DATE_SHORT)}</Typography>
        </Stack>
      </Stack>

      <Stack spacing={1}>
        <Typography variant={'label3'}>Primary contact for this invoice:</Typography>
        <Typography variant={'label3'} color={'grey.A700'}>
          An invoice will be generated and emailed to this contact. Contact customer success to update this billing
          contact.
        </Typography>
        {formValues.paymentBillingContact.email && formValues.paymentBillingContact.name ? (
          <PaymentContact
            data-cy={'paymentBillingContact'}
            email={formValues.paymentBillingContact.email}
            name={formValues.paymentBillingContact.name}
          />
        ) : (
          <Alert severity={'info'} variant={'outlined'}>
            <Typography color={'black'} variant={'label3'}>
              Contact customer success to add a billing contact
            </Typography>
          </Alert>
        )}
      </Stack>

      <Stack spacing={1}>
        <Typography variant={'label3'}>Enter contacts to be cc’d on invoice:</Typography>
        <Typography variant={'label3'} color={'grey.A700'}>
          An invoice will be generated and cc’ed to these contacts.
        </Typography>
        <BillingContactsInput name={'billingContacts'} />
      </Stack>

      <TextInput
        primaryText={
          'Provide any additional information which is required by your finance team to be added to the invoice.'
        }
        name={'invoiceAdditionalInformation'}
        multiline
        minRows={3}
      />

      {formValues.invoiceMethod === InvoiceMethod.CUSTOM_PO && (
        <Stack>
          <Box>
            <TextInput name={'invoicePONumber'} primaryText={'Enter PO number'} />
          </Box>
        </Stack>
      )}

      {formValues.invoiceMethod === InvoiceMethod.CUSTOM_PO && (
        <Stack>
          <Box>
            <TextInput
              name={'invoicePOSystem'}
              primaryText={'What is your invoicing system?'}
              subtext={'Enter your required invoicing system, such as Ariba or EasyO'}
            />
          </Box>
        </Stack>
      )}

      <Stack spacing={1} pt={1}>
        <Typography variant={'subtitle2'}>Total:</Typography>
        <Typography variant={'label1'} data-cy={'invoiceAmount'}>
          {formValues.numCredits ? toLocaleCurrency(formValues.numCredits) : '--'}
        </Typography>
        {isAdminView && onManuallyAddCreditsClick && (
          <Box>
            <Button
              variant={'text'}
              disableRipple
              disableFocusRipple
              onClick={onManuallyAddCreditsClick}
              data-cy={'manualCreditsButton'}
            >
              Manually add credits instead
            </Button>
          </Box>
        )}
      </Stack>

      {!!overdueCreditActions.length && (
        <Stack mb={5} spacing={1}>
          {overdueCreditActions.map(creditAction => (
            <Alert severity="warning" variant="outlined">
              <Typography variant="label3" color={'black'}>
                You have an overdue invoice for new credits. Pay invoice #{creditAction.invoiceNumber} before you can
                buy more credits.
              </Typography>
            </Alert>
          ))}
        </Stack>
      )}

      <Typography variant={'label3'} color={'grey.A700'}>
        By clicking “buy credits” you agree to the{' '}
        <Link
          variant={'label3'}
          sx={{ fontWeight: 800 }}
          href={'https://www.productwind.com/terms-and-conditions'}
          target={'_blank'}
        >
          Momentum Platform Terms and Conditions
        </Link>{' '}
        and will receive a{' '}
        {isPdfDownloadEnabled ? (
          <Link
            variant={'label3'}
            sx={{ fontWeight: 800 }}
            onClick={generatePdf}
            target={'_blank'}
            style={{
              cursor: 'pointer'
            }}
          >
            credit order receipt
          </Link>
        ) : (
          'credit order receipt'
        )}{' '}
        via email.
      </Typography>

      <Stack>
        <LoadingSpinner />
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
          <BackButton variant={'text'} onClick={onCancel} text={'Cancel'} data-cy={'cancelButton'} />
          {/*This is like this because this form may appear in another form.*/}
          <LoadingButton
            onClick={handleSubmit(onSubmit)}
            variant={'contained'}
            data-cy={'submitButton'}
            disabled={!isValid || !!overdueCreditActions.length}
            loading={isSubmitting}
          >
            Buy credits
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  )
}

const PaymentContact = ({ email, name, ...rest }: { email: string; name: string }) => {
  return (
    <Stack direction={'row'} spacing={2} py={1} {...rest}>
      <Stack direction={'row'} spacing={0.5} alignContent={'center'} alignItems={'center'} flex={1}>
        <EmailOutlined fontSize={'medium'} color="primary" />
        <Typography
          variant={'label3'}
          textOverflow={'ellipsis'}
          overflow={'hidden'}
          data-cy={'paymentContactEmail'}
          sx={{
            display: '-webkit-box',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical'
          }}
        >
          {email}
        </Typography>
      </Stack>
      <Stack direction={'row'} spacing={0.5} alignContent={'center'} alignItems={'center'} flex={1}>
        <ContactPageOutlined fontSize={'medium'} color="primary" />
        <Typography
          variant={'label3'}
          textOverflow={'ellipsis'}
          overflow={'hidden'}
          data-cy={'paymentContactName'}
          sx={{
            display: '-webkit-box',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical'
          }}
        >
          {name}
        </Typography>
      </Stack>
    </Stack>
  )
}

const BillingContactsInput = ({ name }: { name: 'billingContacts' }) => {
  const { profile } = useUserSessionContext()

  const {
    control,
    formState: { errors },
    trigger
  } = useFormContext<BuyCreditsFormData>()
  const { append, fields, remove } = useFieldArray({ name, control })
  const handleAdd = () => {
    append({ email: '', name: '' })
  }
  const error = errors[name]?.message

  return (
    <Stack spacing={2}>
      {!!fields.length && (
        <Stack spacing={1}>
          {fields.map((v, index) =>
            v.email === READ_ONLY_CONTACT || v.email === profile.email ? (
              <PaymentContact key={v.id} email={v.email!} name={v.name!} data-cy={`billingContact[${index}]`} />
            ) : (
              <Stack key={v.id} direction={'row'} spacing={1} alignItems={'flex-start'}>
                <TextInput
                  disabled={v.email === READ_ONLY_CONTACT}
                  name={`${name}[${index}].email`}
                  fullWidth
                  placeholder={'Enter billing email'}
                  onBlur={() => trigger(name)}
                />
                <TextInput
                  disabled={v.email === READ_ONLY_CONTACT}
                  name={`${name}[${index}].name`}
                  fullWidth
                  placeholder={'Enter contact name'}
                  onBlur={() => trigger(name)}
                />
                <Cancel
                  sx={{
                    cursor: v.email === READ_ONLY_CONTACT ? undefined : 'pointer',
                    pt: 1,
                    color: v.email === READ_ONLY_CONTACT ? 'transparent' : undefined
                  }}
                  onClick={() => {
                    if (v.email === READ_ONLY_CONTACT) return
                    remove(index)
                  }}
                  fontSize={'mSmall'}
                  color={'action'}
                />
              </Stack>
            )
          )}
        </Stack>
      )}

      <Box>
        <Button
          startIcon={<Add />}
          variant={'text'}
          onClick={handleAdd}
          disabled={fields.length >= 5}
          data-cy={'addBillingContactButton'}
        >
          Add billing contact
        </Button>
      </Box>
      {!!error && (
        <Alert severity={'error'} variant={'outlined'} data-cy={'billingContactsErrorAlert'}>
          <Typography color={'black'} variant={'label3'}>
            {error}
          </Typography>
        </Alert>
      )}
    </Stack>
  )
}

const LoadingSpinner = () => {
  const { formState } = useFormContext()
  if (formState.isSubmitting) {
    return (
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <CircularProgress size={24} />
        <Typography variant={'label3'}>We're generating your invoice!</Typography>
      </Stack>
    )
  }
  return null
}
