import { yupResolver } from '@hookform/resolvers/yup'
import { Question } from '@momentum/components/proposal-common/Question'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { updateCompany } from '@momentum/routes/company/queries'
import { Company } from '@momentum/routes/queries'
import { Typography, Stack, Chip, Box } from '@mui/material'
import { Form, SubmitButton, RadioInput } from '@productwindtom/ui-base'
import { captureException } from '@sentry/react'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { LockOutlined } from '@mui/icons-material'
import { SubscriptionLevel } from '@productwindtom/shared-momentum-zeus-types'
import { UseFormReturn } from 'react-hook-form'

const schema = yup.object({
  subscriptionLevel: yup.string<SubscriptionLevel>().required()
})

type SubscriptionCreditsFormInput = {
  subscriptionLevel: SubscriptionLevel
}
export const CompanySubscriptionCredits = ({
  company,
  hasExistingSubscription
}: {
  company: Company
  hasExistingSubscription?: boolean
}) => {
  const { refreshCompany } = useUserSessionContext()

  const handleSubmit = async (
    submitValues: SubscriptionCreditsFormInput,
    methods: UseFormReturn<SubscriptionCreditsFormInput>
  ) => {
    try {
      await updateCompany({
        id: company.id,
        ...submitValues
      })

      await refreshCompany(company.id)
      toast(<Typography variant={'subtitle2'}>Company subscription settings saved!</Typography>, { type: 'success' })
      methods.reset(submitValues)
    } catch (error) {
      captureException(error)
      toast(<Typography variant={'subtitle2'}>Failed to save company subscription settings!</Typography>, {
        type: 'error'
      })
    }
  }

  const defaultValues = {
    subscriptionLevel: company?.subscriptionLevel || SubscriptionLevel.BRAND
  }

  if (!company) {
    return null
  }

  return (
    <Form onSubmit={handleSubmit} defaultValues={defaultValues} resolver={yupResolver(schema)}>
      <Stack spacing={2}>
        <Stack direction={'row'}>
          <Typography variant={'h4'} mr={1}>
            Company subscription and credits
          </Typography>
          <Chip label={'Admin'} size="small" color="secondary" />
        </Stack>
        <Stack border={1} borderRadius={'4px'} borderColor={t => t.palette.grey.A200} p={3} spacing={2}>
          {hasExistingSubscription && (
            <Stack
              direction={'row'}
              alignItems={'center'}
              spacing={1}
              bgcolor={t => t.palette.grey.A200}
              p={2}
              borderRadius={'4px'}
              alignSelf={'flex-start'}
            >
              <LockOutlined sx={{ color: 'grey.A700' }} />
              <Typography variant={'label3'}>
                Subscription and credits management is locked because a subscription has already been added to this
                account.
              </Typography>
            </Stack>
          )}
          <Question
            primaryText={'Subscriptions and credits'}
            subtext={'Manage subscription and credits at the brand level except for small businesses.'}
          >
            <RadioInput
              name={'subscriptionLevel'}
              radioProps={{ sx: { py: 0.5 }, disableRipple: true }}
              disabled={hasExistingSubscription}
              options={[
                {
                  label: (
                    <Typography variant={'label3'}>Subscription and credits are managed at the brand level</Typography>
                  ),
                  value: SubscriptionLevel.BRAND
                },
                {
                  label: (
                    <Typography variant={'label3'}>
                      Subscription and credits are managed at the company level
                    </Typography>
                  ),
                  value: SubscriptionLevel.COMPANY
                }
              ]}
            />
          </Question>
          <Box>
            <SubmitButton variant={'contained'}>Save subscription and credits</SubmitButton>
          </Box>
        </Stack>
      </Stack>
    </Form>
  )
}
