import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { Alert, Button, Typography } from '@mui/material'
import { useCreateProposalContext } from '../../context/CreateProposalContext'
import Row from '@momentum/components/row'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { Product } from '../../context/queries'
import { useFormContext } from 'react-hook-form'

const PlaceholderProductAlert = ({ isAddProductEnabled }: { isAddProductEnabled?: boolean }) => {
  const { isAdminView } = useUserSessionContext()
  const { setIsAddProductActiveOrCallback, products } = useBrandContext()
  const { selectedProduct, setSelectedProduct, proposal } = useCreateProposalContext()
  const { setValue } = useFormContext()

  if ((!isAddProductEnabled && isAdminView) || !selectedProduct || selectedProduct?.skuId) return null

  const onAddProduct = () => {
    setIsAddProductActiveOrCallback({
      selectedProductId: selectedProduct?.id,
      productPriceOverride: proposal?.productPriceOverride,
      callback: async (product: Product) => {
        if (product) {
          setValue('productId', product.id)
          setSelectedProduct(products?.find(p => p.id === product.id))
        }
      }
    })
  }

  return (
    <Alert variant="outlined" severity="info" data-cy="selfService-placeholderItem">
      <Row justifyContent={'space-between'} spacing={4}>
        <Typography variant="label3">
          {isAddProductEnabled
            ? 'This campaign has a placeholder product. Add the live product URL to schedule this campaign.'
            : 'This campaign has a placeholder product. Remove the product and add the live product to schedule this campaign.'}
        </Typography>
        {isAddProductEnabled && (
          <Button
            variant="outlined"
            sx={{
              flex: '0 0 auto'
            }}
            onClick={onAddProduct}
          >
            Add live product URL
          </Button>
        )}
      </Row>
    </Alert>
  )
}

export default PlaceholderProductAlert
