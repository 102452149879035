import { SvgIcon, SvgIconProps } from '@mui/material'

export default function BonusIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 25" fill="none">
      <path
        d="M19 8.5C19 9.02174 18.5511 9.76329 17.2096 10.434C15.928 11.0748 14.0876 11.5 12 11.5C9.91244 11.5 8.07196 11.0748 6.79036 10.434C5.44895 9.76329 5 9.02174 5 8.5C5 7.97826 5.44895 7.23671 6.79036 6.566C8.07196 5.9252 9.91244 5.5 12 5.5C14.0876 5.5 15.928 5.9252 17.2096 6.566C18.5511 7.23671 19 7.97826 19 8.5Z"
        stroke="currentColor"
        strokeWidth="2"
        fill={'none'}
      />
      <line x1="5" y1="8.5" x2="5" y2="16.5" stroke="currentColor" strokeWidth="2" />
      <line x1="19" y1="8.5" x2="19" y2="16.5" stroke="currentColor" strokeWidth="2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 16.5C18 16.5021 17.9999 16.5052 17.9987 16.5109C17.9975 16.5168 17.9945 16.5291 17.9865 16.5486C17.9703 16.5881 17.9317 16.6621 17.8444 16.7653C17.662 16.9809 17.3196 17.261 16.7624 17.5396C15.6469 18.0973 13.966 18.5 12 18.5C10.034 18.5 8.35305 18.0973 7.23757 17.5396C6.68039 17.261 6.33804 16.9809 6.15562 16.7653C6.06834 16.6621 6.02968 16.5881 6.01352 16.5486C6.00555 16.5291 6.00248 16.5168 6.00128 16.5109C6.00013 16.5052 6 16.5021 6 16.5H4C4 18.7091 7.58172 20.5 12 20.5C16.4183 20.5 20 18.7091 20 16.5H18Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 12.5C18 12.5021 17.9999 12.5052 17.9987 12.5109C17.9975 12.5168 17.9945 12.5291 17.9865 12.5486C17.9703 12.5881 17.9317 12.6621 17.8444 12.7653C17.662 12.9809 17.3196 13.261 16.7624 13.5396C15.6469 14.0973 13.966 14.5 12 14.5C10.034 14.5 8.35305 14.0973 7.23757 13.5396C6.68039 13.261 6.33804 12.9809 6.15562 12.7653C6.06834 12.6621 6.02968 12.5881 6.01352 12.5486C6.00555 12.5291 6.00248 12.5168 6.00128 12.5109C6.00013 12.5052 6 12.5021 6 12.5H4C4 14.7091 7.58172 16.5 12 16.5C16.4183 16.5 20 14.7091 20 12.5H18Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
