import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { Summary } from '@momentum/routes/campaign/content/Summary'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import ContentProvider from '@momentum/sections/content/context'
import { ContentFilterData, ContentSortBy, CreatorAndContent } from '@momentum/sections/content/types'
import {
  ContentTypeToPremiumContentType,
  ExtendedChannel,
  ExtendedContentType,
  getHandle,
  PremiumChannel,
  UGC_CHANNELS
} from '@momentum/utils/socialUtils'
import { Paper } from '@mui/material'
import { Stack } from '@mui/system'
import { CampaignStatus } from '@productwindtom/shared-campaign'
import { Channel, CreatorType } from '@productwindtom/shared-momentum-zeus-types'
import { orderBy, sumBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { NotSupportedState } from './NotSupportedState'
import { UpcomingState } from './UpcomingState'

export const CampaignContent = () => {
  const { isAdminView } = useUserSessionContext()
  const { campaignDetails, activeStatus, updateContentGroup } = useCampaignContext()

  const content: CreatorAndContent[] = useMemo(() => {
    return campaignDetails.creators
      .map(({ content, creator, creatorType, ...rest }) =>
        content.map(o => {
          const channel: ExtendedChannel =
            creatorType === CreatorType.PREMIUM_UGC ? PremiumChannel.PREMIUM_UGC : o.channel

          const contentType: ExtendedContentType =
            creatorType === CreatorType.PREMIUM_UGC
              ? ContentTypeToPremiumContentType[o.contentType] ?? o.contentType
              : o.contentType

          return {
            ...o,
            ...creator,
            ...rest,
            creatorId: creator.id,
            qualityScore: sumBy(o.content, 'qualityScore'),
            handle: getHandle(o.channel, creator),
            engagements: sumBy(o.content, 'engagements'),
            comments: sumBy(o.content, 'comments'),
            views: sumBy(o.content, 'views'),
            likes: sumBy(o.content, 'likes'),
            channel,
            contentType
          }
        })
      )
      .flat()
  }, [campaignDetails.creators])

  const handleFilter = useCallback(
    (data: ContentFilterData) => {
      const { search = '', isFavorite, sortBy, excludedContentTypes = [] } = data || {}

      const lowerSearch = search.toLowerCase().trim()

      const filteredContent = content.filter(
        c =>
          (isAdminView || !c.isHidden) &&
          (!isFavorite || c.isFavorite) &&
          (!excludedContentTypes.length || !excludedContentTypes.includes(c.contentType)) &&
          (!lowerSearch ||
            c?.content?.some(o => o.caption?.toLowerCase().includes(lowerSearch)) ||
            c.handle?.toLowerCase().includes(lowerSearch) ||
            c.email.toLowerCase().includes(lowerSearch) ||
            c.firstName.toLowerCase().includes(lowerSearch) ||
            c.lastName.toLowerCase().includes(lowerSearch))
      )

      switch (sortBy) {
        case ContentSortBy.DATE:
          return filteredContent.sort((a, b) => new Date(b.submittedAt).getTime() - new Date(a.submittedAt).getTime())
        case ContentSortBy.RELEVANCE:
          return orderBy(
            filteredContent,
            [
              a => (UGC_CHANNELS.includes(a.channel) ? 0 : 1),
              a => (UGC_CHANNELS.includes(a.channel) ? a.qualityScore : a.views || 0)
            ],
            ['desc', 'desc']
          )
        default:
          return filteredContent
      }
    },
    [isAdminView, content]
  )

  const isSupported =
    !!campaignDetails.numUgcCreators || !!campaignDetails.numSocialCreators || !!campaignDetails.numPremiumUgcCreators
  const isUpcoming = activeStatus === CampaignStatus.UPCOMING || !content.length

  if (!isSupported) {
    return (
      <Stack spacing={3}>
        <NotSupportedState />
      </Stack>
    )
  }

  return (
    <Stack spacing={3}>
      {!isUpcoming && (
        <Paper sx={{ px: 4, py: 2 }}>
          <Summary campaignContent={content} />
        </Paper>
      )}
      {isUpcoming ? (
        <UpcomingState />
      ) : (
        <ContentProvider onFilterChange={handleFilter} content={content} updateContentGroup={updateContentGroup} />
      )}
    </Stack>
  )
}
