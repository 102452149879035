import { useState } from 'react'
import { Close } from '@mui/icons-material'
import { Dialog, IconButton, Typography, Stack, DialogProps, Box, Button } from '@mui/material'
import Row from '../row'
import { LoadingButton } from '@mui/lab'

const ConfirmationModal = ({
  title,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  children,
  open,
  handleIsOpen,
  dialogProps,
  onConfirm
}: {
  title: string
  confirmText?: string | null
  cancelText?: string | null
  children?: React.ReactNode
  open: boolean
  handleIsOpen: (isOpen: boolean) => void
  dialogProps?: Omit<DialogProps, 'open'>
  onConfirm: () => void | Promise<void>
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    handleIsOpen(false)
  }

  const handleConfirm = async () => {
    try {
      setIsLoading(true)
      await onConfirm()
    } catch (err) {
      console.log('Confirmation error', err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog open={open} fullWidth maxWidth={'xs'} PaperProps={{ sx: { p: 3 } }} {...dialogProps}>
      <Stack spacing={2}>
        <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant={'h3'}>{title}</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Stack>
        <Box>{children}</Box>
        <Row spacing={1} justifyContent={'flex-end'}>
          {cancelText && (
            <Button onClick={handleClose} disabled={isLoading}>
              {cancelText}
            </Button>
          )}
          {confirmText && (
            <LoadingButton loading={isLoading} disabled={isLoading} onClick={handleConfirm} variant="contained">
              {confirmText}
            </LoadingButton>
          )}
        </Row>
      </Stack>
    </Dialog>
  )
}

export default ConfirmationModal
