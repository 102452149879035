import { mApi } from '@momentum/api'
import { ValueTypes } from '@productwindtom/shared-momentum-zeus-types'

export const updateBrand = async (input: ValueTypes['UpdateBrandInput']) => {
  return (await mApi('mutation')({ updateBrand: [{ input }, { id: true }] })).updateBrand
}

export const updateCompany = async (input: ValueTypes['ModelInputUpdateCompany']) => {
  return (await mApi('mutation')({ updateCompany: [{ input }, { id: true }] })).updateCompany
}
