import BonusIcon from '@momentum/components/icons/bonus'
import Logo from '@momentum/components/icons/logo'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { CampaignGoalTypeToName } from '@momentum/utils/campaignGoalUtils'
import { CampaignGoalTypeToIcon } from '@momentum/utils/goalIcons'
import { getCdnImageUrl, noProductImageAlt } from '@momentum/utils/imageUtils'
import { StoreToIcon } from '@momentum/utils/storeIcons'
import { ellipsisString } from '@momentum/utils/stringUtils'
import { KeyboardArrowDown, OpenInNewOutlined, RateReviewOutlined, StarOutline } from '@mui/icons-material'
import { Box, Button, Chip, Link, Menu, MenuItem, Paper, Stack, Typography, ChipOwnProps } from '@mui/material'
import { calculateResults, convertToCreatorInputFromProposalCreatorPricing } from '@productwindtom/shared-momentum'
import {
  GraphQLTypes,
  InputType,
  ProposalStatus,
  Selector,
  STORE_TO_LOCALE
} from '@productwindtom/shared-momentum-zeus-types'
import { useFlag } from '@unleash/proxy-client-react'
import { DateTime } from 'luxon'
import React from 'react'
import Row from '../row'
import { toCurrencyStringCents } from '@productwindtom/shared-ws-currency'

const proposalSelector = Selector('Proposal')({
  id: true,
  brandId: true,
  title: true,
  goal: true,
  launchDate: true,
  productId: true,
  productPriceOverride: true,
  exchangeRate: true,
  managedServiceFee: true,
  status: true,
  ratingGoal: true,
  creatorPricing: {
    type: true,
    numCreators: true,
    price: true,
    schedule: true
  },
  product: {
    name: true,
    image: true,
    priceCents: true,
    store: true,
    listingLink: true,
    ratingSummary: {
      rating: true,
      numRatings: true
    }
  },
  updatedBy: {
    firstName: true,
    lastName: true
  },
  updatedAt: true,
  createdAt: true
})
type Proposal = InputType<GraphQLTypes['Proposal'], typeof proposalSelector>

export type ProposalCardProps = {
  proposal: Proposal
  brandName: string
  onEditClick?: () => void
  onViewClick?: (isFinancialSummaryView?: boolean) => void
}

export const ProposalCard = ({ proposal, brandName, onEditClick, onViewClick }: ProposalCardProps) => {
  const financialSummaryFlag = useFlag('FinancialSummary')

  const { isAdminView } = useUserSessionContext()

  const product = proposal.product
  const productCost = proposal.productPriceOverride || product?.priceCents
  const results = productCost
    ? calculateResults(proposal.goal, {
        creators: convertToCreatorInputFromProposalCreatorPricing(proposal.creatorPricing || []),
        ratingGoal: proposal.ratingGoal,
        productCost: productCost,
        exchangeRate: proposal.exchangeRate || 1,
        managedServiceFee: proposal.managedServiceFee,
        store: product?.store,
        ratingsSummary: product?.ratingSummary
      })
    : undefined

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Paper sx={{ py: 2, px: 1 }} data-cy={`proposalCard-${proposal.id}`}>
      <Stack direction={'row'} spacing={1}>
        <Box px={4}>
          <img
            style={{ width: 100, height: 100, objectFit: 'contain' }}
            src={getCdnImageUrl(proposal.product?.image) || '/images/no-product.png'}
            alt={`${proposal.product?.name} image`}
            onError={noProductImageAlt}
          />
        </Box>
        <Stack sx={{ minWidth: 0 }} spacing={2} flex={1}>
          <Stack>
            <Typography variant={'body1'} color={theme => theme.palette.grey.A700}>
              {brandName}
            </Typography>
            <Stack direction={'row'}>
              <Typography
                variant={'link1'}
                sx={{
                  maxWidth: '375px'
                }}
                data-cy="proposalTitle"
              >
                {!!proposal.title && ellipsisString(proposal.title, 75)}{' '}
                {!!proposal.product?.listingLink && (
                  <Link
                    href={proposal.product?.listingLink}
                    target={'_blank'}
                    display={'inline-flex'}
                    sx={{ verticalAlign: 'sub' }}
                  >
                    <OpenInNewOutlined fontSize={'mSmall'} color={'primary'} />
                  </Link>
                )}
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} spacing={2} my={1}>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                {proposal.product?.store && StoreToIcon[proposal.product.store]({ fontSize: 'mSmall' })}
                {!!proposal.product && productCost != null && (
                  <Typography
                    data-cy={'proposalProductPrice'}
                    variant={'body2'}
                    color={theme => theme.palette.grey.A700}
                  >
                    {toCurrencyStringCents(productCost, STORE_TO_LOCALE[proposal.product.store])}
                  </Typography>
                )}
              </Stack>
              {!!proposal.product?.ratingSummary?.rating && (
                <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                  <StarOutline fontSize={'mSmall'} color={'primary'} />
                  <Typography data-cy={'proposalRating'} variant={'body2'} color={theme => theme.palette.grey.A700}>
                    {proposal.product?.ratingSummary?.rating?.toFixed(1)}
                  </Typography>
                </Stack>
              )}
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <RateReviewOutlined fontSize={'mSmall'} color={'primary'} />
                <Typography data-cy={'proposalNumRating'} variant={'body2'} color={theme => theme.palette.grey.A700}>
                  {proposal.product?.ratingSummary?.numRatings?.toLocaleString()} Reviews and ratings
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction={'row'} spacing={2}>
            <Typography variant={'body2'} color={theme => theme.palette.grey.A700} data-cy={'proposalLastUpdated'}>
              Last updated: {DateTime.fromISO(proposal.updatedAt).toLocaleString(DateTime.DATE_SHORT)}
            </Typography>
            {!!proposal.updatedBy && (
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Logo fontSize={'small'} />
                <Typography variant={'body2'} color={theme => theme.palette.grey.A700} data-cy={'proposalUpdatedBy'}>
                  Last edited by: {`${proposal.updatedBy.firstName} ${proposal.updatedBy.lastName?.charAt(0)}.`}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack alignItems={'flex-end'} spacing={1} px={4}>
          <Stack spacing={0.5} alignItems={'flex-end'}>
            <Box>
              <Chip size={'small'} {...getChipStatus(proposal.status)} />
            </Box>
            <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
              <Box color={'primary'} display={'inline-flex'}>
                {CampaignGoalTypeToIcon[proposal.goal]?.({
                  sx: { fontSize: '16px' },
                  color: 'primary'
                })}
              </Box>
              <Typography variant={'body1'} data-cy={'proposalGoal'}>
                {CampaignGoalTypeToName[proposal.goal]}
              </Typography>
            </Stack>
            {!!results && (
              <Stack direction={'row'} spacing={0.5}>
                <BonusIcon fontSize={'mSmall'} color={'primary'} />
                <Typography variant={'body1'} data-cy="proposalCredits">
                  {results.totalCostCredits.toLocaleString()} Credits
                </Typography>
              </Stack>
            )}
          </Stack>
          <Row alignItems={'stretch'} spacing={0.1}>
            <Button variant={'contained'} onClick={() => onViewClick?.(false)} sx={{ px: 2 }} data-cy="viewProposal">
              View proposal
            </Button>
            {isAdminView && financialSummaryFlag && (
              <>
                <Button variant={'contained'} onClick={handleClick} size={'small'} sx={{ px: 0, minWidth: '40px' }}>
                  <KeyboardArrowDown fontSize="small" />
                </Button>
                <Menu
                  sx={{
                    height: 'auto'
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => onViewClick?.(true)}>View Financial Summary</MenuItem>
                </Menu>
              </>
            )}
          </Row>
        </Stack>
      </Stack>
    </Paper>
  )
}

const getChipStatus = (status: ProposalStatus): { label?: ChipOwnProps['label']; color?: ChipOwnProps['color'] } => {
  switch (status) {
    case ProposalStatus.DRAFT:
      return { label: 'Draft', color: 'info' }
    case ProposalStatus.PUBLISHED:
      return { label: 'Published', color: 'success' }
    case ProposalStatus.SUBMITTED:
      return { label: 'Submitted', color: 'success' }
    default:
      return {}
  }
}
