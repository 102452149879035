import { Box, Divider, Menu, MenuItem, Stack, Typography } from '@mui/material'
import React from 'react'
import {
  KeyboardArrowDownOutlined,
  ViewWeekOutlined,
  CheckBoxOutlined,
  CheckBoxOutlineBlank
} from '@mui/icons-material'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'

export const ColumnSelector = ({
  apiRef,
  columns,
  columnVisibilityModel
}: {
  apiRef: GridApiPremium
  columns: { field: string; headerName?: string }[]
  columnVisibilityModel: Record<string, boolean>
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const allSelected = !columns.some(c => !(columnVisibilityModel[c.field] ?? true))

  const handleShowAll = () => {
    columns.forEach(column => apiRef.setColumnVisibility(column.field, true))
  }

  const toggleVisibility = (field: string) => {
    apiRef.setColumnVisibility(field, !(columnVisibilityModel[field] ?? true))
  }

  return (
    <Box>
      <Stack
        direction={'row'}
        alignItems={'center'}
        onClick={handleClick}
        spacing={0.5}
        sx={{
          border: theme => `1px solid ${theme.palette.grey.A200}`,
          borderRadius: '4px',
          p: 1,
          background: theme => theme.palette.grey.A100,
          cursor: 'pointer'
        }}
      >
        <ViewWeekOutlined sx={{ color: theme => theme.palette.grey.A700 }} />
        <Typography variant={'label3'} color={theme => theme.palette.grey.A700}>
          Columns
        </Typography>
        <KeyboardArrowDownOutlined sx={{ color: theme => theme.palette.grey.A700 }} />
      </Stack>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} disablePortal sx={{ zIndex: 10 }}>
        <MenuItem onClick={handleShowAll}>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            {allSelected ? <CheckBoxOutlined color={'primary'} /> : <CheckBoxOutlineBlank />}
            <Typography variant={'label3'}>Show all columns</Typography>
          </Stack>
        </MenuItem>
        <Divider />
        {columns.map(c => (
          <MenuItem key={c.field} onClick={() => toggleVisibility(c.field)} sx={{ py: 1 }}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              {columnVisibilityModel?.[c.field] ?? true ? (
                <CheckBoxOutlined color={'primary'} />
              ) : (
                <CheckBoxOutlineBlank />
              )}
              <Typography variant={'label3'}>{c.headerName || c.field}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
