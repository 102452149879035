import { HelpOutline } from '@mui/icons-material'
import { Box, Dialog, Fab, Menu, MenuItem, Typography } from '@mui/material'
import { useRef, useState } from 'react'
import ContactSupportForm from './ContactSupportForm'
import { useFlag } from '@unleash/proxy-client-react'

const Support = () => {
  const supportFlag = useFlag('Support')

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isContactSupportOpen, setIsContactSupportOpen] = useState<boolean>(false)

  const open = Boolean(anchorEl)

  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSupportClick = () => {
    setIsContactSupportOpen(true)
    setAnchorEl(null)
  }

  const handleClose = () => {
    setIsContactSupportOpen(false)
    setAnchorEl(null)
  }

  if (!supportFlag) {
    return null
  }

  return (
    <Box ref={anchorRef} position={'fixed'} bottom={20} right={20} zIndex={999}>
      <Fab color="primary" onClick={handleClick}>
        <HelpOutline fontSize="large" />
      </Fab>
      <Menu
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        sx={{
          transform: 'translate(-27.5px, -27.5px)'
        }}
      >
        <MenuItem
          component={'a'}
          href={'https://info.productwind.com/'}
          target={'_blank'}
          disableRipple
          sx={{
            width: 200
          }}
        >
          <Typography variant="label3">Help Center</Typography>
        </MenuItem>
        <MenuItem
          onClick={handleSupportClick}
          disableRipple
          sx={{
            width: 200
          }}
        >
          <Typography variant="label3">Contact Support</Typography>
        </MenuItem>
      </Menu>
      <Dialog open={isContactSupportOpen} maxWidth="xs">
        <ContactSupportForm onClose={handleClose} />
      </Dialog>
    </Box>
  )
}

export default Support
