import { UpdateContentGroup } from '@momentum/routes/campaign/context/types'
import { ExtendedChannel, ExtendedContentType } from '@momentum/utils/socialUtils'
import { CreatorType, GraphQLTypes, InputType } from '@productwindtom/shared-momentum-zeus-types'
import { ArrayElement } from '@productwindtom/shared-type-utils'
import { creatorContentSelector } from './selectors'

export enum ContentSortBy {
  DATE = 'Date submitted',
  RELEVANCE = 'Most relevant'
}

export type CreatorContent = InputType<GraphQLTypes['CreatorContent'], typeof creatorContentSelector>
export type CreatorAndContent = CreatorContent['creator'] &
  Omit<ArrayElement<CreatorContent['content']>, 'contentType' | 'channel'> & {
    creatorId: string
    qualityScore?: number
    handle?: string
    engagements?: number
    comments?: number
    views?: number
    likes?: number
    creatorType?: CreatorType | null
    channel: ExtendedChannel
    contentType: ExtendedContentType
  }

export type ContentContextType = {
  isLoading: boolean
  selectedContent: string[]
  selectContent: (contentId: string) => void
  unselectContent: (contentId: string) => void
  unselectAllContent: () => void
  favoriteContent: (groupId: string) => void
  unfavoriteContent: (contentId: string) => void
  setContentIsHidden: (contentId: string, isHidden: boolean) => void
  setExpandedContent: (content: CreatorAndContent | null) => void
  expandContent: (groupId: string) => void
  selectAllContent: () => void
  filteredContent: CreatorAndContent[]
  content: CreatorAndContent[]
  isSearch: boolean | undefined
  updateContentGroup?: UpdateContentGroup
}

export type ContentContextProps = {
  updateContentGroup?: UpdateContentGroup
  isSearch?: boolean
  content?: CreatorAndContent[]
  onFilterChange: (data: ContentFilterData) => Promise<CreatorAndContent[]> | CreatorAndContent[]
}

export type ContentFilterData = {
  campaignIds: []
  search?: string
  excludedContentTypes: ExtendedContentType[]
  sortBy: ContentSortBy
  isFavorite: boolean
}
