import { useFormContext } from 'react-hook-form'
import { Stack, Alert, Typography, Grid } from '@mui/material'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import React, { useEffect } from 'react'
import { EmailOutlined, ContactPageOutlined } from '@mui/icons-material'

export const PaymentBillingContactInput = ({ name }: { name: 'paymentBillingContact' }) => {
  const {
    getValues,
    formState: { errors },
    trigger
  } = useFormContext<ProposalCreateForm>()

  useEffect(() => {
    trigger(name)
  }, [])

  const error = errors[name]?.message
  const billingContact = getValues(name)
  return (
    <Stack spacing={2}>
      <Stack direction={'row'} alignItems={'center'}>
        <Grid xs={6} container gap={0.5} alignItems={'center'} pr={1}>
          <EmailOutlined color={'primary'} fontSize={'medium'} />
          <Typography variant={'label3'} textOverflow={'ellipsis'} overflow={'hidden'} flex={1}>
            {billingContact?.email}
          </Typography>
        </Grid>
        <Grid xs={6} container gap={0.5} alignItems={'center'} pl={1}>
          <ContactPageOutlined color={'primary'} fontSize={'medium'} />
          <Typography variant={'label3'} textOverflow={'ellipsis'} overflow={'hidden'} flex={1}>
            {billingContact?.name}
          </Typography>
        </Grid>
      </Stack>

      {!!error && (
        <Alert severity={'error'} variant={'outlined'}>
          <Typography color={'black'} variant={'label3'}>
            {error}
          </Typography>
        </Alert>
      )}
    </Stack>
  )
}
