import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { ConfirmDeleteDialog } from '@momentum/routes/campaign/e-commerce/performance/components/ConfirmDeleteDialog'
import { useCampaignOverviewPerformanceContext } from '@momentum/routes/campaign/e-commerce/performance/overviewPerformanceContext'
import {
  CampaignPerformanceProduct,
  updateCampaignPerformanceProduct
} from '@momentum/routes/campaign/e-commerce/performance/queries'
import { calculateDatesToGoal } from '@momentum/routes/campaign/e-commerce/performance/timeToUtils'
import { getCdnImageUrl, noProductImageAlt } from '@momentum/utils/imageUtils'
import { pluralWord } from '@momentum/utils/stringUtils'
import { CheckCircleOutline, OpenInNewOutlined } from '@mui/icons-material'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import CircleIcon from '@mui/icons-material/Circle'
import { Box, Chip, Divider, IconButton, Link, Stack, Typography } from '@mui/material'
import { ChipOwnProps } from '@mui/material/Chip/Chip'
import { PerformanceComparisonMetric } from '@productwindtom/shared-momentum-zeus-types'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { FormType } from '..'
import { EditProductDialog } from './EditProductDialog'

type ProductCardProps = {
  index: number
  productColor: string
  chipLabel: string
  chipColor: ChipOwnProps['color']
  canDelete?: boolean
}

export const ProductCard = ({ index, productColor, chipLabel, chipColor, canDelete }: ProductCardProps) => {
  const { isAdminView } = useUserSessionContext()
  const { deleteCampaignPerformanceProduct } = useCampaignOverviewPerformanceContext()
  const { watch, control, getValues, setValue } = useFormContext<FormType>()
  const { remove } = useFieldArray({ control, name: 'products' })

  const [editProductOpen, setEditProductOpen] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)

  const promotedProductId = watch('promotedProductId')
  const comparisonMetric = watch('comparisonMetric')
  const comparisonMetricValue = watch('comparisonMetricValue')
  const product = watch(`products.${index}`) as CampaignPerformanceProduct
  const { productImage, productName, productUrl, bestSellerRankDataUploaded, reviewDataUploaded } = product
  const onDelete = async () => {
    setUploading(true)
    await deleteCampaignPerformanceProduct(product.id)
    remove(index)
    setUploading(false)
    setConfirmDeleteOpen(false)
  }

  const readonly = promotedProductId === product.id

  const onToggleProductGraphVisibility = (isVisible: boolean) => {
    const product = getValues().products[index]
    if (product) {
      setValue(`products.${index}.isVisible`, isVisible)
      if (isAdminView) {
        updateCampaignPerformanceProduct({
          id: product.id,
          isVisible
        })
      }
    }
  }

  const isVisible = !!product.isVisible
  return (
    <Box
      borderRadius={'4px'}
      borderColor={theme => (isVisible ? theme.palette.primary.main : theme.palette.grey.A200)}
      onClick={() => !readonly && onToggleProductGraphVisibility(!product.isVisible)}
      sx={{
        cursor: 'pointer',
        borderStyle: 'solid',
        borderWidth: isVisible ? 2 : 1,
        boxShadow: theme => (isVisible ? theme.shadows[3] : 'none'),
        height: '160px'
      }}
      px={1}
      py={1}
    >
      <Stack height={'100%'}>
        <Stack direction={'row'} alignItems={'center'} width={'100%'} spacing={1}>
          <CircleIcon width={12} htmlColor={productColor} />
          <Chip size={'small'} label={chipLabel} color={chipColor || 'secondary'} />
          {!!productUrl && (
            <Link href={productUrl} target={'_blank'} display={'inline-flex'} sx={{ verticalAlign: 'sub' }}>
              <OpenInNewOutlined color={'primary'} fontSize={'medium'} />
            </Link>
          )}
          <Box display={'flex'} flex={1} mb={2} justifyContent={'flex-end'}>
            {canDelete && (
              <IconButton
                size={'small'}
                disabled={uploading}
                onClick={e => {
                  setConfirmDeleteOpen(true)
                  e.stopPropagation()
                }}
                sx={{ padding: 0 }}
              >
                <CancelRoundedIcon fontSize={'medium'} />
              </IconButton>
            )}
          </Box>
        </Stack>

        <Stack flex={1} spacing={1} mt={1}>
          <Divider />
          <Stack direction={'column'} flex={1} spacing={1}>
            <Typography
              variant={'link2'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
                minHeight: '34px'
              }}
            >
              {productName}
            </Typography>
            <Stack direction={'row'} spacing={1} width={'100%'} flex={1}>
              <img
                src={getCdnImageUrl(productImage) || '/images/no-product.png'}
                alt={productName}
                style={{ width: 60, height: 60, objectFit: 'contain' }}
                onError={noProductImageAlt}
              />
              <Stack width={'100%'}>
                <Stack flex={1}>
                  <Typography
                    variant={'body1'}
                    sx={{
                      fontWeight: 800
                    }}
                  >
                    {getTimeToText(calculateDatesToGoal(product, comparisonMetric, comparisonMetricValue))}
                  </Typography>
                  <Typography variant={'body1'}>
                    time to {comparisonMetricValue?.toLocaleString()}{' '}
                    {comparisonMetric === PerformanceComparisonMetric.REVIEWS ? 'reviews' : 'best seller rank'}
                  </Typography>
                </Stack>
                {isAdminView && (
                  <Stack mt={0.5} justifyContent={'end'}>
                    <Divider />
                    <Stack
                      onClick={event => event.stopPropagation()}
                      direction={'row'}
                      pt={1}
                      pr={1}
                      spacing={1}
                      justifyContent={'space-between'}
                    >
                      {comparisonMetric === PerformanceComparisonMetric.BEST_SELLER_RANK &&
                        bestSellerRankDataUploaded && (
                          <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
                            <CheckCircleOutline color={'success'} fontSize={'mSmall'} />
                            <Typography
                              variant={'caption2'}
                              color={theme => theme.palette.grey.A700}
                              sx={{
                                fontStyle: 'italic',
                                fontSize: '11px'
                              }}
                            >
                              BSR uploaded
                            </Typography>
                          </Stack>
                        )}
                      {comparisonMetric === PerformanceComparisonMetric.REVIEWS && reviewDataUploaded && (
                        <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
                          <CheckCircleOutline color={'success'} fontSize={'mSmall'} />
                          <Typography
                            variant={'caption2'}
                            color={theme => theme.palette.grey.A700}
                            sx={{
                              fontStyle: 'italic',
                              fontSize: '11px'
                            }}
                          >
                            Time to review uploaded
                          </Typography>
                        </Stack>
                      )}
                      <Typography
                        color={'primary'}
                        onClick={e => {
                          !uploading && setEditProductOpen(true)
                        }}
                        variant={'link2'}
                        alignSelf={'center'}
                        sx={{ cursor: 'pointer' }}
                      >
                        Edit
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <EditProductDialog
          product={product}
          productIndex={index}
          open={editProductOpen}
          onClose={() => setEditProductOpen(false)}
        />
        <ConfirmDeleteDialog
          open={confirmDeleteOpen}
          onConfirm={onDelete}
          onClose={() => setConfirmDeleteOpen(false)}
        />
      </Stack>
    </Box>
  )
}

const getTimeToText = (input?: { startDate: string; endDate: string }) => {
  if (input) {
    const { startDate, endDate } = input
    const diff = DateTime.fromISO(endDate).diff(DateTime.fromISO(startDate), ['months', 'days'])

    const components = []
    if (diff.months > 0) {
      components.push(pluralWord(`${diff.months} month`, diff.months > 1))
    }
    if (diff.days >= 1) {
      components.push(pluralWord(`${diff.days} day`, diff.days > 1))
    }
    return components.join(', ')
  }
  return '--'
}
