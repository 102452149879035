import { SvgIcon, SvgIconProps } from '@mui/material'

export default function BonusHighlightedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 41" fill="none">
      <circle cx="20" cy="20.25" r="19.2188" fill="#FDF4DC" stroke="#FDF4DC" strokeWidth="1.5625" />
      <path d="M11.4999 10.75H28.2816L28.2816 29.75H11.4999L11.4999 10.75Z" fill="#F9E4A7" />
      <path
        d="M19.6667 8.25C13.7733 8.25 9 10.6367 9 13.5833V26.9167C9 29.8633 13.7867 32.25 19.6667 32.25C25.5467 32.25 30.3333 29.8633 30.3333 26.9167V13.5833C30.3333 10.6367 25.56 8.25 19.6667 8.25ZM27.6667 26.9167C27.6667 27.5833 24.8267 29.5833 19.6667 29.5833C14.5067 29.5833 11.6667 27.5833 11.6667 26.9167V23.9433C13.8133 24.9833 16.6267 25.5833 19.6667 25.5833C22.7067 25.5833 25.52 24.9833 27.6667 23.9433V26.9167ZM27.6667 20.85C25.9333 22.1167 22.8933 22.9167 19.6667 22.9167C16.44 22.9167 13.4 22.1167 11.6667 20.85V17.1033C13.6267 18.21 16.48 18.9167 19.6667 18.9167C22.8533 18.9167 25.7067 18.21 27.6667 17.1033V20.85ZM19.6667 16.25C14.5067 16.25 11.6667 14.25 11.6667 13.5833C11.6667 12.9167 14.5067 10.9167 19.6667 10.9167C24.8267 10.9167 27.6667 12.9167 27.6667 13.5833C27.6667 14.25 24.8267 16.25 19.6667 16.25Z"
        fill="#F3C94E"
      />
    </SvgIcon>
  )
}
