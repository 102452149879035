import { SvgIcon, SvgIconProps } from '@mui/material'

export default function BonusPendingIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="12" fill="#EFF3FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 4H5V18H9.28988C9.10128 17.3663 9 16.695 9 16C9 12.134 12.134 9 16 9C16.3395 9 16.6734 9.02417 17 9.07089V4Z"
        fill="white"
      />
      <path
        d="M16.5 16.25L19.36 17.94L18.61 19.16L15 17V12H16.5V16.25ZM23 16C23 19.87 19.87 23 16 23C14.8707 23.0009 13.7579 22.7285 12.7566 22.2061C11.7553 21.6837 10.8953 20.9268 10.25 20C6.19 19.79 3 18.08 3 16V6C3 3.79 6.58 2 11 2C15.42 2 19 3.79 19 6V9.68C21.36 10.81 23 13.21 23 16ZM17 9.08V8.64C16.77 8.77 16.5 8.9 16.24 9C16.5 9 16.75 9.04 17 9.08ZM5 6C5 6.5 7.13 8 11 8C14.87 8 17 6.5 17 6C17 5.5 14.87 4 11 4C7.13 4 5 5.5 5 6ZM5 11.45C6.07 12.23 7.8 12.76 9.72 12.93C10.33 11.67 11.32 10.62 12.54 9.92C12.04 9.97 11.53 10 11 10C8.61 10 6.47 9.47 5 8.64V11.45ZM9.26 17.87C9.1 17.27 9 16.65 9 16C9 15.61 9.04 15.23 9.1 14.86C7.56 14.69 6.15 14.33 5 13.77V16C5 16.42 6.5 17.5 9.26 17.87ZM21 16C21 13.24 18.76 11 16 11C13.24 11 11 13.24 11 16C11 18.76 13.24 21 16 21C18.76 21 21 18.76 21 16Z"
        fill="#F3C94E"
      />
      <circle cx="16" cy="16" r="6" stroke="#4668EE" strokeWidth="2" fill="none" />
      <path d="M19.36 17.94L16.5 16.25V12H15V17L18.61 19.16L19.36 17.94Z" fill="#4668EE" />
    </SvgIcon>
  )
}
