import Metric from '@momentum/components/metric'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { CampaignTimeline } from '@momentum/routes/campaign/overview/proposal/CampaignTimeline'
import { ROUTES, ROUTE_NAMES_CAMPAIGN, ROUTE_NAMES_CAMPAIGN_OVERVIEW } from '@momentum/routes/RouteNames'
import { CreatorTypeLabels } from '@momentum/utils/creatorUtils'
import { errorToast, successToast } from '@momentum/utils/toastUtils'
import { Button, ButtonGroup, Divider, Paper, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { calculateResults, convertToCreatorInputFromProposalCreatorPricing } from '@productwindtom/shared-momentum'
import { AccessLevel, InvoiceStatus } from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { Form, SubmitButton } from '@productwindtom/ui-base'
import { sumBy } from 'lodash'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { Navigate, generatePath } from 'react-router-dom'
import { getCampaignStartDate } from '../../context/queries'
import { CampaignImpact } from './CampaignImpact'
import Row from '@momentum/components/row'
import CampaignFinancialSummary from './CampaignFinancialSummary'
import { useFlag } from '@unleash/proxy-client-react'
import { convertFromCreatorPricing, generateContentRequirements } from '@momentum/utils/proposalUtils'
import { ProposalCreatorPricingInput } from '@momentum/routes/proposals-create/types'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { DeliverablesTab } from './types'
import { useFormContext } from 'react-hook-form'
import { Create, SaveAltOutlined } from '@mui/icons-material'
import { getCdnImageUrl } from '@momentum/utils/imageUtils'

export type EditProposalForm = {
  invoiceStatus?: InvoiceStatus
  invoicePaidAmount?: number
  invoicePaidDate?: DateTime
  invoiceDueDate?: DateTime
  invoicePONumber?: string
  invoicePOSystem?: string
  invoiceProductCostStatus?: InvoiceStatus
  invoiceProductCostPaidAmount?: number
  invoiceProductCostPaidDate?: DateTime
  invoiceProductCostDueDate?: DateTime
  invoiceProductCostPONumber?: string
  launchDate?: DateTime
  eventDate?: DateTime
  campaignLaunchDate?: DateTime
  benchmarkProducts?: string[]
  initialBenchmarkProducts?: string[]
  searchTerms?: string[]
  initialSearchTerms?: string[]

  invoiceAdditionalInformation?: string
  referralCost?: number
  operatingCost?: number
  creatorPricing: ProposalCreatorPricingInput[]
  contentRequirements: any[]
  liftNumCreators?: number
  liftCloseoutBonus?: number
}

export const OverviewProposal = () => {
  const { isAdminView, isAdmin, profile } = useUserSessionContext()
  const [tabSelected, setTabSelected] = useState(DeliverablesTab.IMPACT)
  const [isEditingProposal, setIsEditingProposal] = useState(false)
  const {
    brandId,
    campaignDetails: { id, proposal, product, createdAt, anticipatedStartDate, cost },
    updateCampaignProposal,
    adjustCampaignDate
  } = useCampaignContext()
  const { company } = useBrandContext()

  const financialSummaryFlag = useFlag('FinancialSummary') && isAdminView

  const [defaultValues, setDefaultValues] = useState<EditProposalForm | undefined>(
    proposal && {
      invoiceStatus: proposal.invoiceStatus || InvoiceStatus.NOT_PAID,
      invoicePaidDate: proposal.invoicePaidDate ? DateTime.fromISO(proposal.invoicePaidDate) : undefined,
      invoiceDueDate: proposal.invoiceDueDate ? DateTime.fromISO(proposal.invoiceDueDate) : undefined,
      invoicePONumber: proposal.invoicePONumber,
      invoiceProductCostStatus: proposal.invoiceProductCostStatus || InvoiceStatus.NOT_PAID,
      invoiceProductCostPaidDate: proposal.invoiceProductCostPaidDate
        ? DateTime.fromISO(proposal.invoiceProductCostPaidDate)
        : undefined,
      invoiceProductCostDueDate: proposal.invoiceProductCostDueDate
        ? DateTime.fromISO(proposal.invoiceProductCostDueDate)
        : undefined,
      invoiceProductCostPONumber: proposal.invoiceProductCostPONumber,
      launchDate: proposal.launchDate ? DateTime.fromISO(proposal.launchDate) : undefined,
      eventDate: proposal.eventDate ? DateTime.fromISO(proposal.eventDate) : undefined,
      campaignLaunchDate: anticipatedStartDate ? DateTime.fromISO(anticipatedStartDate) : undefined,
      benchmarkProducts: proposal.benchmarkProducts || [],
      initialBenchmarkProducts: proposal.initialBenchmarkProducts || proposal.benchmarkProducts || [],
      searchTerms: proposal.searchTerms || [],
      initialSearchTerms: proposal.initialSearchTerms || proposal.searchTerms || [],
      invoicePOSystem: proposal.invoicePOSystem,
      invoiceAdditionalInformation: proposal.invoiceAdditionalInformation,
      referralCost: proposal.referralCost,
      operatingCost: proposal.operatingCost,
      creatorPricing: convertFromCreatorPricing(proposal.creatorPricing),
      contentRequirements: generateContentRequirements(convertFromCreatorPricing(proposal.creatorPricing)),
      liftNumCreators: proposal.liftNumCreators || 0,
      liftCloseoutBonus: proposal.liftCloseoutBonus
    }
  )

  if (!proposal) {
    return (
      <Navigate
        to={generatePath(
          [ROUTES.CAMPAIGN, ROUTE_NAMES_CAMPAIGN.OVERVIEW, ROUTE_NAMES_CAMPAIGN_OVERVIEW.SUMMARY].join('/'),
          { brandId, campaignId: id }
        )}
      />
    )
  }

  const productCost =
    proposal.productPriceOverride || proposal.productPrice || (product?.priceCents ? product.priceCents : 0)

  const results = calculateResults(proposal.goal, {
    creators: convertToCreatorInputFromProposalCreatorPricing(proposal.creatorPricing || []),
    ratingGoal: proposal.ratingGoal,
    productCost,
    exchangeRate: proposal.exchangeRate || 1,
    managedServiceFee: proposal.managedServiceFee,
    store: product.store,
    ratingsSummary: {
      rating: proposal.productRating,
      numRatings: proposal.productRatingCount
    },
    estimatedAverageRating: proposal.estimatedAverageRating
  })

  const handleSave = async (values: EditProposalForm) => {
    const currentAnticipatedStartDate = (await getCampaignStartDate(id))?.anticipatedStartDate

    if (currentAnticipatedStartDate !== anticipatedStartDate) {
      errorToast('The current campaign start date is outdated. Please refresh the page and try again.')

      return
    }

    if (
      values.campaignLaunchDate &&
      values.campaignLaunchDate?.toISODate() !== DateTime.fromISO(anticipatedStartDate).toISODate()
    ) {
      await adjustCampaignDate(values.campaignLaunchDate.toISO()!)
    }

    await updateCampaignProposal({
      invoiceStatus: values.invoiceStatus,
      invoicePaidDate: values.invoicePaidDate?.toISODate(),
      invoiceDueDate: values.invoiceDueDate?.toISODate(),
      invoicePaidAmount: values.invoiceStatus === InvoiceStatus.PAID ? values.invoicePaidAmount : null,
      ...(values.invoiceProductCostStatus && { invoiceProductCostStatus: values.invoiceProductCostStatus }),
      ...(values.invoiceProductCostPaidDate && {
        invoiceProductCostPaidDate: values.invoiceProductCostPaidDate.toISODate()
      }),
      ...(values.invoiceProductCostDueDate && {
        invoiceProductCostDueDate: values.invoiceProductCostDueDate.toISODate()
      }),
      ...(proposal.invoiceProductCostId && {
        invoiceProductCostPaidAmount:
          values.invoiceProductCostStatus === InvoiceStatus.PAID ? values.invoiceProductCostPaidAmount : null
      }),
      searchTerms: values.searchTerms,
      initialSearchTerms: !proposal.initialSearchTerms && proposal.searchTerms ? proposal.searchTerms : undefined,
      benchmarkProducts: values.benchmarkProducts,
      initialBenchmarkProducts:
        !proposal.initialBenchmarkProducts && proposal.benchmarkProducts ? proposal.benchmarkProducts : undefined,
      invoicePONumber: values.invoicePONumber,
      invoicePOSystem: values.invoicePOSystem,
      referralCost: values.referralCost,
      operatingCost: values.operatingCost,
      updatedByUserId: profile.id
    })
    successToast('Proposal saved')
    setIsEditingProposal(false)
    setDefaultValues(values)
  }

  return (
    <Form onSubmit={handleSave} defaultValues={defaultValues}>
      <Stack spacing={5}>
        {tabSelected !== DeliverablesTab.FINANCIAL_SUMMARY && (
          <Row spacing={2} justifyContent={'space-between'} alignItems={'center'}>
            <Row spacing={2} alignItems={'center'}>
              <Typography variant={'h4'}>
                Campaign {tabSelected === DeliverablesTab.IMPACT ? 'impact' : 'timeline'}
              </Typography>
              {!!proposal.orderFormKey && (
                <Button
                  component={'a'}
                  variant={'text'}
                  startIcon={<SaveAltOutlined />}
                  href={getCdnImageUrl(proposal.orderFormKey)}
                  target={'_blank'}
                >
                  Download campaign receipt
                </Button>
              )}
            </Row>
            {tabSelected === DeliverablesTab.IMPACT && (isAdmin || profile.accessLevel === AccessLevel.FULL_ACCESS) && (
              <Row spacing={2}>
                <SaveProposalButton
                  isEditingProposal={isEditingProposal}
                  setIsEditingProposal={setIsEditingProposal}
                  defaultValues={defaultValues}
                />
                <Button
                  onClick={() => setIsEditingProposal(true)}
                  disabled={isEditingProposal}
                  startIcon={<Create fontSize="medium" />}
                >
                  Edit proposal
                </Button>
              </Row>
            )}
          </Row>
        )}

        {tabSelected === DeliverablesTab.FINANCIAL_SUMMARY ? (
          <CampaignFinancialSummary proposal={proposal} setTabSelected={setTabSelected} />
        ) : (
          <>
            {isAdminView && (
              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <ButtonGroup
                  color="primary"
                  variant="outlined"
                  size="medium"
                  disableRipple
                  disableElevation
                  style={{
                    alignSelf: 'flex-end'
                  }}
                >
                  <Button
                    variant={tabSelected === DeliverablesTab.IMPACT ? 'contained' : 'outlined'}
                    value={DeliverablesTab.IMPACT}
                    onClick={() => setTabSelected(DeliverablesTab.IMPACT)}
                  >
                    Deliverables
                  </Button>
                  <Button
                    variant={tabSelected === DeliverablesTab.TIMELINE ? 'contained' : 'outlined'}
                    value={DeliverablesTab.TIMELINE}
                    onClick={() => setTabSelected(DeliverablesTab.TIMELINE)}
                  >
                    Timeline
                  </Button>
                </ButtonGroup>
              </Stack>
            )}

            <Stack spacing={4}>
              <Paper sx={{ px: 4, py: 2 }}>
                <Stack>
                  <Typography variant={'label1'} data-cy="totalCreators">
                    {sumBy(proposal.creatorPricing, c => c.numCreators).toLocaleString()} total creators
                  </Typography>
                  <Stack direction={'row'} justifyContent={'space-evenly'}>
                    {proposal.creatorPricing
                      .filter(c => c.numCreators)
                      .map(cp => (
                        <Metric
                          key={cp.type}
                          label={CreatorTypeLabels[cp.type]}
                          value={cp.numCreators?.toLocaleString()}
                        />
                      ))}
                  </Stack>
                </Stack>
              </Paper>
              <Stack px={4} spacing={4}>
                {tabSelected === DeliverablesTab.IMPACT && (
                  <CampaignImpact
                    proposal={proposal}
                    proposalResults={results}
                    product={product}
                    isEditingProposal={isEditingProposal}
                  />
                )}
                {tabSelected === DeliverablesTab.TIMELINE && <CampaignTimeline proposal={proposal} product={product} />}

                <Stack spacing={4}>
                  <Stack spacing={1}>
                    {!!proposal.submittedBy && (
                      <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant={'label1'}>Campaign created by:</Typography>
                        <Typography variant={'label1'}>
                          {[proposal.submittedBy?.firstName, proposal.submittedBy?.lastName].filter(notEmpty).join(' ')}
                        </Typography>
                      </Stack>
                    )}
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      <Typography variant={'label1'}>Campaign created on: </Typography>
                      <Typography variant={'label1'}>
                        {DateTime.fromISO(createdAt).toLocaleString(DateTime.DATE_SHORT)}
                      </Typography>
                    </Stack>
                    {!!proposal.updatedBy && (
                      <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant={'label1'}>Campaign updated by:</Typography>
                        <Typography variant={'label1'}>
                          {[proposal.updatedBy?.firstName, proposal.updatedBy?.lastName].filter(notEmpty).join(' ')}
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                  <Divider />
                  <Stack spacing={1}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      <Typography variant={'label1'}>Creator cost</Typography>
                      <Typography variant={'label1'}>
                        {((proposal.totalCredits || 0) - (proposal.totalProductCostCredits || 0)).toLocaleString()}{' '}
                        credits
                      </Typography>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      <Typography variant={'label1'}>Product cost</Typography>
                      <Typography variant={'label1'}>
                        {proposal.totalProductCostCredits?.toLocaleString()} credits
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider />

                  {!!proposal.managedServiceFee && (
                    <>
                      <Stack spacing={1}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                          <Typography variant={'label1'}>Managed service fee</Typography>
                          <Typography variant={'label1'}>
                            {proposal.managedServiceFee?.toLocaleString()} credits
                          </Typography>
                        </Stack>
                      </Stack>
                      <Divider />
                    </>
                  )}

                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography variant={'h4'}>
                      {company.agencyId ? 'Campaign cost (agency to end client)' : 'Campaign cost'}
                    </Typography>

                    <Typography variant={'h4'}>{proposal.totalCredits?.toLocaleString()} credits</Typography>
                  </Stack>

                  {company.agencyId && (
                    <Stack>
                      <Stack py={2} direction={'row'} justifyContent={'space-between'}>
                        <Typography variant={'h4'}>Campaign cost (agency to ProductWind)</Typography>
                        <Typography variant={'h4'}>
                          {((proposal.totalProductCostCredits ?? 0) + cost / 100).toLocaleString()} credits
                        </Typography>
                      </Stack>
                    </Stack>
                  )}
                  <Divider />
                  {tabSelected === DeliverablesTab.IMPACT && (
                    <Row spacing={2}>
                      <SaveProposalButton
                        isEditingProposal={isEditingProposal}
                        setIsEditingProposal={setIsEditingProposal}
                        defaultValues={defaultValues}
                      />
                      {financialSummaryFlag && (
                        <Button
                          onClick={() => {
                            setTabSelected(DeliverablesTab.FINANCIAL_SUMMARY)
                            window.scrollTo({ top: 0 })
                          }}
                        >
                          View Financial Summary
                        </Button>
                      )}
                    </Row>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </Form>
  )
}

const SaveProposalButton = ({
  isEditingProposal,
  setIsEditingProposal,
  defaultValues
}: {
  isEditingProposal: boolean
  setIsEditingProposal: React.Dispatch<React.SetStateAction<boolean>>
  defaultValues?: EditProposalForm
}) => {
  const {
    reset,
    formState: { isDirty }
  } = useFormContext()

  const cancelEditing = () => {
    setIsEditingProposal(false)
    reset(defaultValues)
  }

  return (
    <Row spacing={1}>
      <SubmitButton disabled={!isEditingProposal || !isDirty} variant={'contained'} size={'small'}>
        {isEditingProposal ? 'Save proposal' : 'Saved'}
      </SubmitButton>
      {isEditingProposal && (
        <Button variant="text" onClick={cancelEditing}>
          Cancel
        </Button>
      )}
    </Row>
  )
}
