import Loading from '@momentum/components/loading'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { HeaderBar } from '@momentum/routes/campaign/components/HeaderBar'
import {
  CampaignClick,
  CampaignCreatorForApproval,
  CampaignDetails,
  CampaignProductReviewRatingMetric,
  ContentGroup,
  CreatorDraftContent,
  EtailerProductMetric,
  getCampaignClicks,
  getCampaignDetails,
  getEtailerProductMetrics,
  getReviewMetrics,
  listCampaignCreatorsForApproval,
  listContentPendingApproval
} from '@momentum/routes/campaign/context/queries'
import { SeoContextProvider } from '@momentum/routes/campaign/e-commerce/seoV2/context'
import { getSellerBrandApi, getVendorBrandApi } from '@momentum/utils/brandApiUtils'
import { Container, Stack, Box } from '@mui/material'
import { CreatorApprovalStatus, DraftStatus, ValueTypes } from '@productwindtom/shared-momentum-zeus-types'
import { DateTime } from 'luxon'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { Outlet, generatePath, useNavigate, useParams } from 'react-router-dom'
import { COLLAPSED_WIDTH, EXPANDED_WIDTH, NavBar } from '../components/NavBar'
import { CampaignContextType } from './types'
import { getCampaignStatus } from '@productwindtom/shared-campaign'
import { adjustCampaignStartDate, updateCampaignProduct, updateProposal } from '@momentum/routes/campaign/queries'
import { Product } from '../context/queries'
import { errorToast } from '@momentum/utils/toastUtils'
import { ROUTES } from '@momentum/routes/RouteNames'

export const DAYS_DATA = 14

const CampaignContext = createContext<CampaignContextType>({} as any)

const CampaignProvider = () => {
  const { brandId, campaignId } = useParams<{ brandId: string; campaignId: string }>()
  const [isLoading, setIsLoading] = useState(true)
  const [navCollapsed, setNavCollapsed] = useState(false)
  const [campaignDetails, setCampaignDetails] = useState<CampaignDetails>()
  const [liftCampaignDetails, setLiftCampaignDetails] = useState<CampaignDetails>()
  const [campaignCreatorsForApproval, setCampaignCreatorsForApproval] = useState<CampaignCreatorForApproval[]>()
  const [campaignClicks, setCampaignClicks] = useState<CampaignClick[]>([])
  const [productEtailerMetrics, setProductEtailerMetrics] = useState<EtailerProductMetric[] | undefined>()
  const [campaignProductReviewRatingMetric, setCampaignProductReviewRatingMetric] = useState<
    CampaignProductReviewRatingMetric[] | undefined
  >()

  const [campaignContentForApproval, setCampaignContentForApproval] = useState<CreatorDraftContent[]>([])

  const navigate = useNavigate()
  const { isAdmin, isAdminView } = useUserSessionContext()
  const { brand } = useBrandContext()

  const refreshCampaignDetails = async () => {
    if (campaignId) {
      getCampaignDetails(campaignId).then(async ({ getCampaign, listCampaignClicks }) => {
        if (!getCampaign) {
          errorToast('Campaign was not found')
          navigate(generatePath(ROUTES.BRAND, { brandId }))
          return
        }

        setCampaignDetails(getCampaign)
        if (listCampaignClicks.nextToken) {
          setCampaignClicks([
            ...listCampaignClicks.items,
            ...(await getCampaignClicks(campaignId, listCampaignClicks.nextToken))
          ])
        } else {
          setCampaignClicks(listCampaignClicks.items)
        }
      })
    }
  }

  useEffect(() => {
    if (campaignId) {
      setIsLoading(true)
      refreshCampaignDetails()
      listCampaignCreatorsForApproval(campaignId).then(setCampaignCreatorsForApproval)
      listContentPendingApproval(campaignId).then(setCampaignContentForApproval)
    }
  }, [campaignId])

  useEffect(() => {
    /*
     Putting this as its own as we need the start date.
     Also, not connecting it to isLoading so that the page will load faster.
     We can have a loader on any page that "Requires" it
    */
    if (campaignDetails?.startDate) {
      getReviewMetrics(
        campaignDetails.id,
        DateTime.fromISO(campaignDetails.startDate).minus({ days: DAYS_DATA }).toISODate()!,
        campaignDetails.endDate
          ? DateTime.fromISO(campaignDetails.endDate).plus({ days: DAYS_DATA }).toISODate()!
          : undefined
      ).then(setCampaignProductReviewRatingMetric)
    }
  }, [campaignDetails?.startDate])

  useEffect(() => {
    /*
     Putting this as its own as we need the start date.
     Also, not connecting it to isLoading so that the page will load faster.
     We can have a loader on any page that "Requires" it
    */
    if (campaignDetails?.startDate) {
      getEtailerProductMetrics(
        campaignDetails.skuId,
        DateTime.fromISO(campaignDetails.startDate).minus({ weeks: 4 }).toJSDate().toISOString(),
        campaignDetails.endDate
          ? DateTime.fromISO(campaignDetails.endDate).plus({ days: 14 }).toJSDate().toISOString()
          : undefined
      )
        .then(setProductEtailerMetrics)
        .finally(() => setIsLoading(false))
    }
  }, [campaignDetails?.startDate])

  useEffect(() => {
    if (campaignDetails?.liftCampaignId) {
      getCampaignDetails(campaignDetails.liftCampaignId).then(async ({ getCampaign, listCampaignClicks }) => {
        setLiftCampaignDetails(getCampaign)
        const clicks = listCampaignClicks.items

        if (listCampaignClicks.nextToken) {
          clicks.push(...(await getCampaignClicks(campaignDetails.liftCampaignId!, listCampaignClicks.nextToken)))
        }
        setCampaignClicks(prev =>
          Object.values(
            [...prev, ...clicks].reduce((acc: Record<string, CampaignClick>, curr) => {
              acc[curr.date] = { date: curr.date, clicks: (acc[curr.date]?.clicks || 0) + (curr.clicks || 0) }
              return acc
            }, {})
          )
        )
      })
    }
  }, [campaignDetails?.liftCampaignId])

  const updateCampaignProposal = async (proposalInput: Omit<ValueTypes['ModelInputUpdateProposal'], 'id'>) => {
    if (campaignDetails?.proposal?.id) {
      const resp = await updateProposal({
        id: campaignDetails.proposal.id,
        ...proposalInput
      })
      setCampaignDetails(prev => (prev ? { ...prev, proposal: resp } : undefined))
    }
  }

  const adjustCampaignProduct = async (product: Product) => {
    try {
      await updateCampaignProduct(campaignId!, product.id)
      await refreshCampaignDetails()
    } catch (err) {
      errorToast('Something went wrong, please try again or contact your client success manager.')
    }
  }

  const adjustCampaignDate = async (newCampaignStartDate: string) => {
    await adjustCampaignStartDate({
      campaignId,
      newCampaignStartDate
    })

    await refreshCampaignDetails()
  }

  const updateContentGroup = (content: Partial<ContentGroup>) => {
    setCampaignDetails(prev =>
      prev
        ? {
            ...prev,
            creators: prev.creators.map(creator => ({
              ...creator,
              content: creator.content.map(c => (c.groupId === content.groupId ? { ...c, ...content } : c))
            }))
          }
        : undefined
    )
  }

  const updateCampaignCreatorsForApproval = (creator: CampaignCreatorForApproval) => {
    setCampaignCreatorsForApproval(prev =>
      prev ? prev.map(c => (c.userCampaignId === creator.userCampaignId ? { ...c, ...creator } : c)) : prev
    )
  }

  const updateCampaignContentForApproval = (content: Partial<CreatorDraftContent>) => {
    setCampaignContentForApproval(prev =>
      prev ? prev.map(c => (c.id === content.id ? { ...c, ...content } : c)) : prev
    )
  }

  const visibleContentForApprovals = useMemo(() => {
    return isAdmin && isAdminView
      ? campaignContentForApproval
      : campaignContentForApproval.map(c => ({
          ...c,
          draftContent: c?.draftContent?.filter(dc => !dc.isHidden)
        }))
  }, [campaignContentForApproval, isAdmin, isAdminView])

  const creatorsPendingApprovalCount = useMemo(
    () =>
      campaignCreatorsForApproval?.filter(creator => creator.approvalState.status === CreatorApprovalStatus.PENDING)
        .length,
    [campaignCreatorsForApproval]
  )

  const contentPendingApprovalCount = useMemo(
    () =>
      campaignContentForApproval.reduce((acc, item) => {
        const pendingDraft = item?.draftContent?.filter(
          ({ draftStatus }) => draftStatus === DraftStatus.REQUIRES_BRAND_APPROVAL
        )

        return acc + (pendingDraft?.length || 0)
      }, 0),
    [campaignContentForApproval]
  )

  if (isLoading || !campaignDetails || !brandId) {
    return (
      <Box mt={10}>
        <Loading />
      </Box>
    )
  }
  const { endDate, preLaunchDate, startDate, numCreatorsJoined, pauseReason } = campaignDetails
  const activeStatus = getCampaignStatus(startDate, preLaunchDate, endDate, numCreatorsJoined > 0, pauseReason)

  const sellerBrandApi = getSellerBrandApi(brand.brandApis)
  const vendorBrandApi = getVendorBrandApi(brand.brandApis)
  const needsSellerIntegration = !!sellerBrandApi?.enabled && !sellerBrandApi.isIntegrated
  const needsVendorIntegration = !!vendorBrandApi?.enabled && !vendorBrandApi.isIntegrated

  return (
    <CampaignContext.Provider
      value={{
        navCollapsed,
        setNavCollapsed,
        brandId,
        campaignDetails,
        updateCampaignProposal,
        adjustCampaignDate,
        adjustCampaignProduct,
        liftCampaignDetails,
        campaignCreatorsForApproval,
        updateCampaignCreatorsForApproval,
        creatorsPendingApprovalCount,
        campaignContentForApproval: visibleContentForApprovals,
        campaignClicks,
        productEtailerMetrics,
        reviewMetrics: campaignProductReviewRatingMetric,
        activeStatus,
        updateContentGroup,
        updateCampaignContentForApproval,
        contentPendingApprovalCount,
        needsSellerIntegration,
        needsVendorIntegration,
        updateCampaignDetails: setCampaignDetails
      }}
    >
      <SeoContextProvider>
        <NavBar />
        <Container
          maxWidth={false}
          disableGutters
          sx={{ flexGrow: 1, pl: `${navCollapsed ? COLLAPSED_WIDTH : EXPANDED_WIDTH}px`, transition: 'all 0.5s' }}
        >
          <Container>
            <Stack py={4} spacing={4} height={'100%'}>
              <HeaderBar />
              <Outlet />
            </Stack>
          </Container>
        </Container>
      </SeoContextProvider>
    </CampaignContext.Provider>
  )
}

CampaignContext.displayName = 'CampaignContext'

export default CampaignProvider

export const useCampaignContext = () => useContext(CampaignContext)
