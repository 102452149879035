import { Alert, Stack, Typography, Dialog, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { useDisclose } from '@momentum/hooks/useDisclose'
import { Close } from '@mui/icons-material'
import { BuyCreditsFormV2, BuyCreditsFormData } from '@momentum/routes/subscription/BuyCreditsFormV2'
import { successToast, errorToast } from '@momentum/utils/toastUtils'
import { captureException } from '@sentry/react'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useSubscriptionContext } from '@momentum/contexts/Subscription'
import { SubscriptionCreditAction } from '@momentum/routes/queries'
import { SubscriptionLevel, CreditAction } from '@productwindtom/shared-momentum-zeus-types'
import BonusAddIcon from '@momentum/components/icons/bonus-add'
import BonusPendingIcon from '@momentum/components/icons/bonus-pending'
import BonusInsufficientIcon from '@momentum/components/icons/bonus-insufficient'
import BonusAlertIcon from '@momentum/components/icons/bonus-alert'
import BonusHighlightedIcon from '@momentum/components/icons/bonus-highlighted'
import { Question } from '@momentum/components/proposal-common/Question'

export const CreditPayment = ({
  creditsRemaining,
  totalCostCredits
}: {
  creditsRemaining: number
  totalCostCredits: number
}) => {
  const [invoiceIssued, setInvoiceIssued] = useState<SubscriptionCreditAction>()

  const { selectedBrand, selectedCompany } = useUserSessionContext()
  const { purchaseCredits, subscriptionLevel, netTermsDays } = useSubscriptionContext()
  const { isOpen, onOpen, onClose } = useDisclose()

  const name = subscriptionLevel === SubscriptionLevel.COMPANY ? selectedCompany?.name : selectedBrand?.name

  const additionalCreditsRequired = Math.abs(
    Math.min(
      0,
      creditsRemaining -
        totalCostCredits +
        (invoiceIssued?.action === CreditAction.BUY_CREDITS ? invoiceIssued.numCredits : 0)
    )
  )

  const submitBuyCredits = async ({ invoiceMethod, ...input }: BuyCreditsFormData) => {
    try {
      const resp = await purchaseCredits(input)
      setInvoiceIssued(resp)
      successToast('Credits purchased successfully')
      onClose()
    } catch (e) {
      console.error(e)
      captureException(e)
      errorToast('An error occurred while purchasing credits')
    }
  }

  return (
    <Stack spacing={4}>
      <Question primaryText={'Schedule campaign:'}>
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          <Stack direction={'row'} spacing={1}>
            <BonusAlertIcon fontSize={'large'} />
            <Stack>
              <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                <Typography variant={'h3'} data-cy={'creditsRequired'}>
                  {totalCostCredits.toLocaleString()}
                </Typography>
                <Typography variant={'label3'}>credits</Typography>
              </Stack>
              <Typography variant={'label3'}>required for this campaign</Typography>
            </Stack>
          </Stack>
          <Stack direction={'row'} spacing={1}>
            <BonusHighlightedIcon fontSize={'large'} />
            <Stack>
              <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                <Typography variant={'h3'} data-cy={'creditsRemaining'}>
                  {creditsRemaining.toLocaleString()}
                </Typography>
                <Typography variant={'label3'}>credits</Typography>
              </Stack>
              <Typography variant={'label3'}>currently available</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Question>
      {totalCostCredits > creditsRemaining && !invoiceIssued && (
        <Alert severity={'error'} variant={'outlined'} icon={<BonusInsufficientIcon />}>
          <Stack spacing={0.5}>
            <Typography color={'black'} variant={'label1'}>
              You need {additionalCreditsRequired.toLocaleString()} more credits!
            </Typography>
            <Typography color={'black'} variant={'label3'}>
              You have insufficient credits. Purchase {additionalCreditsRequired.toLocaleString()} credits to schedule
              this campaign.{' '}
              <Typography color={'primary'} onClick={onOpen} variant={'label2'} sx={{ cursor: 'pointer' }}>
                Buy credits
              </Typography>
            </Typography>
          </Stack>
        </Alert>
      )}
      {!!invoiceIssued && (
        <IssuedInvoice
          invoiceIssued={invoiceIssued}
          netTermsDays={netTermsDays}
          onBuyCreditsClick={onOpen}
          name={name}
          additionalCreditsRequired={additionalCreditsRequired}
        />
      )}
      <Dialog open={isOpen} maxWidth={'sm'} fullWidth>
        <Stack p={3} spacing={4}>
          <Stack>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography variant={'h4'}>Buy credits for {name}</Typography>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Stack>
            <Typography variant={'subtitle2'} color={'grey.A700'}>
              Credits can be used on any Momentum campaign. An invoice will be generated through{' '}
              <Typography color={'primary'} variant={'subtitle1'} component={'span'}>
                Bill.com
              </Typography>
              .
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant={'label1'}>Payment for campaign:</Typography>
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <Stack direction={'row'} spacing={1}>
                <BonusAlertIcon />
                <Stack>
                  <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                    <Typography variant={'label1'} data-cy={'creditsRequired'}>
                      {totalCostCredits.toLocaleString()}
                    </Typography>
                    <Typography variant={'label3'}>credits</Typography>
                  </Stack>
                  <Typography variant={'label3'}>required for this campaign</Typography>
                </Stack>
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <BonusHighlightedIcon />
                <Stack>
                  <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                    <Typography variant={'label1'} data-cy={'creditsRemaining'}>
                      {creditsRemaining.toLocaleString()}
                    </Typography>
                    <Typography variant={'label3'}>credits</Typography>
                  </Stack>
                  <Typography variant={'label3'}>currently available</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <BuyCreditsFormV2
            key={additionalCreditsRequired}
            onCancel={onClose}
            onSubmit={submitBuyCredits}
            numCredits={additionalCreditsRequired}
          />
        </Stack>
      </Dialog>
    </Stack>
  )
}

const IssuedInvoice = ({
  name,
  invoiceIssued,
  netTermsDays,
  additionalCreditsRequired,
  onBuyCreditsClick
}: {
  name?: string
  netTermsDays?: number
  invoiceIssued: SubscriptionCreditAction
  additionalCreditsRequired?: number
  onBuyCreditsClick: () => void
}) => {
  let title, body, icon
  let alertType: undefined | 'success' | 'info' = 'success'
  if (invoiceIssued.action === CreditAction.ADD) {
    icon = <BonusAddIcon />

    title = `You’ve added ${invoiceIssued.numCredits.toLocaleString()} credits.`

    body = (
      <Typography variant={'label3'}>
        Credits have been deposited and {name} has been issued invoice{' '}
        <Typography variant={'label2'} color={'primary'}>
          #{invoiceIssued.invoiceNumber}
        </Typography>{' '}
        on net {netTermsDays} payment terms.
      </Typography>
    )
  } else if (invoiceIssued.action === CreditAction.BUY_CREDITS) {
    alertType = 'info'
    icon = <BonusPendingIcon />
    title = `You’ve issued invoice #${invoiceIssued.invoiceNumber} for ${invoiceIssued.numCredits.toLocaleString()} credits.`
    body = (
      <Typography variant={'label3'}>
        Credits will be deposited as soon as ProductWind receives payment. Pay invoice{' '}
        <Typography variant={'label2'} color={'primary'}>
          #{invoiceIssued.invoiceNumber}
        </Typography>{' '}
        now to schedule this campaign!
      </Typography>
    )
  }

  if (!!additionalCreditsRequired) {
    alertType = 'info'
  }

  title =
    title +
    (additionalCreditsRequired
      ? ` Add ${additionalCreditsRequired.toLocaleString()} more to schedule this campaign!`
      : '')

  return (
    <Alert severity={alertType} variant={'outlined'} icon={icon}>
      <Stack spacing={0.5}>
        <Typography color={'black'} variant={'label1'}>
          {title}
        </Typography>
        <Stack spacing={3}>
          {body}
          {!!additionalCreditsRequired && (
            <Typography variant={'label3'}>
              Purchase {additionalCreditsRequired.toLocaleString()} additional credits to schedule this campaign.{' '}
              <Typography color={'primary'} onClick={onBuyCreditsClick} variant={'label2'} sx={{ cursor: 'pointer' }}>
                Buy credits
              </Typography>
            </Typography>
          )}
        </Stack>
      </Stack>
    </Alert>
  )
}
