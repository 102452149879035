import { yupResolver } from '@hookform/resolvers/yup'
import { Question } from '@momentum/components/proposal-common/Question'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { updateCompany } from '@momentum/routes/company/queries'
import { Company } from '@momentum/routes/queries'
import { Typography, Stack, Chip, Box, Grid } from '@mui/material'
import { Form, SubmitButton, RadioInput, TextInput } from '@productwindtom/ui-base'
import { captureException } from '@sentry/react'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { UseFormReturn, useWatch, useFormContext } from 'react-hook-form'
import { PaymentInvoicingType } from '@productwindtom/shared-momentum-zeus-types'
import { useEffect } from 'react'

const schema = yup.object().shape({
  paymentInvoicingType: yup.string<PaymentInvoicingType>().required(),
  paymentBillingContact: yup
    .object({
      email: yup.string().required('Required').email('Invalid email'),
      name: yup.string().required('Required')
    })
    .nullable()
    .optional()
    .transform((_val, originalValue) => {
      if (!originalValue?.email && !originalValue?.name) {
        return null
      }
      return originalValue
    })
})

type BillingFormInput = {
  paymentInvoicingType: PaymentInvoicingType
  paymentBillingContact?: {
    email: string
    name: string
  } | null
}
export const CompanyBilling = ({ company }: { company: Company }) => {
  const { refreshCompany } = useUserSessionContext()

  const handleSubmit = async (submitValues: BillingFormInput, methods: UseFormReturn<BillingFormInput>) => {
    try {
      await updateCompany({
        id: company.id,
        paymentInvoicingType: submitValues.paymentInvoicingType,
        paymentBillingContact: submitValues.paymentBillingContact
      })

      await refreshCompany(company.id)
      toast(<Typography variant={'subtitle2'}>Company billing settings saved!</Typography>, { type: 'success' })
      methods.reset(submitValues)
    } catch (error) {
      captureException(error)
      toast(<Typography variant={'subtitle2'}>Failed to save company billing settings!</Typography>, {
        type: 'error'
      })
    }
  }

  const defaultValues: BillingFormInput = {
    paymentInvoicingType: company.paymentInvoicingType || PaymentInvoicingType.SINGLE,
    paymentBillingContact: company.paymentBillingContact
  }

  return (
    <Form onSubmit={handleSubmit} defaultValues={defaultValues} resolver={yupResolver(schema)}>
      <FormBody />
    </Form>
  )
}

const FormBody = () => {
  const { setValue, trigger } = useFormContext()
  const { paymentBillingContact } = useWatch<BillingFormInput>()

  useEffect(() => {
    if (!!paymentBillingContact && !paymentBillingContact?.email && !paymentBillingContact?.name) {
      setValue('paymentBillingContact', null, { shouldValidate: true })
    }
    trigger('paymentBillingContact')
  }, [paymentBillingContact])

  return (
    <Stack spacing={2}>
      <Stack direction={'row'}>
        <Typography variant={'h4'} mr={1}>
          Company billing
        </Typography>
        <Chip label={'Admin'} size="small" color="secondary" />
      </Stack>
      <Stack border={1} borderRadius={'4px'} borderColor={t => t.palette.grey.A200} p={3} spacing={2}>
        <Question
          primaryText={'Contact responsible for paying the bill'}
          subtext={
            'Add the primary billing contact for your Bill.com account. Invoices will be emailed to this contact.'
          }
        >
          <Grid container direction={'row'} spacing={2}>
            <Grid item xs={6}>
              <Stack spacing={1} display={'inline'}>
                <Typography variant={'label3'}>Billing email</Typography>
                <TextInput
                  name={'paymentBillingContact.email'}
                  fullWidth
                  placeholder={'Enter Bill.com billing email'}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={1} display={'inline'}>
                <Typography variant={'label3'}>Customer name in bill.com</Typography>
                <TextInput name={'paymentBillingContact.name'} fullWidth placeholder={'Enter contact name'} />
              </Stack>
            </Grid>
          </Grid>
        </Question>
        <Question
          primaryText={'Payment Terms'}
          subtext={'New invoices will default to these payment terms for all company proposals. '}
        >
          <RadioInput
            name={'paymentInvoicingType'}
            radioProps={{ sx: { py: 0.5 }, disableRipple: true }}
            options={[
              {
                label: (
                  <Typography variant={'label3'}>The company pays one invoice for the entire campaign.</Typography>
                ),
                value: PaymentInvoicingType.SINGLE
              },
              {
                label: (
                  <Typography variant={'label3'}>
                    The company pays one invoice for creator costs and a separate invoice for product costs.
                  </Typography>
                ),
                value: PaymentInvoicingType.SPLIT
              }
            ]}
          />
        </Question>
        <Box>
          <SubmitButton variant={'contained'}>Save company billing</SubmitButton>
        </Box>
      </Stack>
    </Stack>
  )
}
