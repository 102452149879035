import { Stack } from '@mui/system'
import { Outlet, useNavigate, useMatch } from 'react-router-dom'
import { TabBar } from './TabBar'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { ROUTES, ROUTE_NAMES_CAMPAIGN, ROUTE_NAMES_CAMPAIGN_OVERVIEW } from '@momentum/routes/RouteNames'
import { useEffect } from 'react'

export const CampaignOverview = () => {
  const { campaignDetails } = useCampaignContext()

  const navigate = useNavigate()
  const base = [ROUTES.CAMPAIGN, ROUTE_NAMES_CAMPAIGN.OVERVIEW].join('/')
  const baseMatch = useMatch(base)
  const defaultTab = ROUTE_NAMES_CAMPAIGN_OVERVIEW.SUMMARY

  useEffect(() => {
    if (baseMatch) {
      navigate(defaultTab, { replace: true })
    }
  }, [baseMatch])

  return (
    <Stack spacing={4}>
      {campaignDetails.proposal && <TabBar />}
      <Outlet />
    </Stack>
  )
}
