import { Font, Document, Page, Text, StyleSheet, View } from '@react-pdf/renderer'
import React from 'react'
import { DateTime } from 'luxon'

const styles = StyleSheet.create({
  page: {
    padding: 45
  },
  title1: {
    fontFamily: 'Avenir',
    fontSize: '14px'
  },
  body: {
    fontFamily: 'Avenir',
    fontSize: '11px'
  },
  body2: {
    fontFamily: 'Avenir',
    fontSize: '11px',
    fontWeight: 'heavy'
  },

  section: {
    textAlign: 'center',
    marginBottom: 15,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center'
  },
  column: { flexDirection: 'column' },
  row: { width: '100%', display: 'flex', flexDirection: 'row' },

  cellHeader: {
    textAlign: 'center',
    alignContent: 'center',
    padding: 5
  },
  firstCell: {
    textAlign: 'left',
    paddingLeft: 10,
    paddingVertical: 10,
    paddingRight: 5,
    width: '25%',
    borderTop: '1px solid black',
    borderRight: '1px solid black'
  },
  cell: {
    textAlign: 'left',
    paddingLeft: 5,
    width: '75%',
    paddingVertical: 10,
    paddingRight: 10,
    borderTop: '1px solid black'
  }
})

export const BuyCreditsOrderFormPdf = ({
  numCredits,
  contacts,
  companyName,
  invoiceNumber,
  paymentDueDate,
  clientContactName,
  clientContactEmail
}: {
  invoiceNumber?: string
  numCredits: number
  companyName: string
  contacts: string[]
  paymentDueDate: string
  clientContactName: string
  clientContactEmail: string
}) => {
  Font.register({
    family: 'Avenir',
    fonts: [
      {
        src: '/fonts/Avenir/AvenirRegular.ttf'
      },
      {
        src: '/fonts/Avenir/AvenirHeavy.ttf',
        fontWeight: 'heavy'
      }
    ]
  })

  const contactsString =
    contacts.length > 2
      ? [contacts.slice(0, contacts.length - 1).join(', '), contacts.pop()].join(', and ')
      : contacts.join(' and ')

  return (
    <Document
      pageLayout="singlePage"
      pageMode="useNone"
      title={`ProductWind Credit Order Receipt`}
      author="Momentum"
      creator="Momentum"
      creationDate={DateTime.now().toJSDate()}
      language="en"
    >
      <Page style={styles.page}>
        <View style={[styles.section]}>
          <Text style={[styles.title1]}>Credit Order Receipt</Text>
        </View>
        <View style={[styles.section]}>
          <Text style={[styles.body]}>
            This Credit Order Receipt (the “
            <Text style={[styles.body2, { fontWeight: 'heavy' }]}>Credit Order Receipt</Text>”) is provided to the{' '}
            {companyName} (“
            <Text style={styles.body2}>Client</Text>”) by ProductWind, Inc, a Delaware corporation (“
            <Text style={styles.body2}>ProductWind</Text>”). Client's purchase and use of Credits is subject to the
            Momentum Platform Terms and Conditions.
          </Text>
        </View>
        <View style={[{ flexDirection: 'column', border: '1px solid black' }]}>
          <View style={[styles.cellHeader]}>
            <Text style={[styles.body2, { textDecoration: 'underline' }]}>CREDIT ORDER RECEIPT</Text>
          </View>
          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Credits</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>
                In exchange for the payment set forth herein, Client will receive {numCredits.toLocaleString()} Credits
                redeemable on ProductWind’s Momentum Platform.
              </Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Credit Cost</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>
                ${numCredits.toLocaleString(undefined, { minimumFractionDigits: 2 })} ($1.00 per Credit)
              </Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Payment Date</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>Payment is due in full no later than {paymentDueDate}.</Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Payment Details</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>
                All payments shall be made in United States dollars. All credit purchases are non-refundable.
              </Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Payment Method</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>See invoice for accepted payment methods.</Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Billing Contacts</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>
                The corresponding invoice {invoiceNumber ? `#${invoiceNumber} has been` : 'will be'} sent to{' '}
                {contactsString}
              </Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Credit Usage</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>
                Credits will be available for use in the Momentum Platform upon payment of the amounts due above. All
                Credits expire at the end of the applicable Subscription Term.
              </Text>
            </View>
          </View>
          <View style={[styles.row]}>
            <View style={[styles.firstCell]}>
              <Text style={[styles.body2]}>Client Contact</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={[styles.body]}>{clientContactEmail}</Text>
              <Text style={[styles.body]}>Attention: {clientContactName}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
