import SocialPortraitIcon from '@momentum/components/icons/social-portrait'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import useEstimatedDeliverables from '@momentum/routes/proposals-create/modules/useEstimatedDeliverables'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import {
  AutoGraphOutlined,
  CheckCircleOutlineOutlined,
  EmojiPeopleOutlined,
  PersonSearchOutlined,
  RateReviewOutlined,
  SlowMotionVideoOutlined,
  StarOutline,
  TouchAppOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined
} from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'
import { ProposalGoal } from '@productwindtom/shared-momentum-zeus-types'
import { useFlag } from '@unleash/proxy-client-react'
import { uniq } from 'lodash'
import { ReactElement } from 'react'
import { useController, useFormContext, useWatch } from 'react-hook-form'

const MIN_WIDTH = 150
const SPACING = 5

export const CampaignImpact = () => {
  const { watch } = useFormContext<ProposalCreateForm>()
  const pageOneWinCalculatorFlag = useFlag('ProposalCalculatorPageOneWin')

  const { isDisplayResults, selectedProduct, locale, markAsDraft, markAsPublished, ...results } =
    useEstimatedDeliverables()

  const { isAdminView } = useUserSessionContext()

  const formData = watch()

  const isMonthly = formData?.goal === ProposalGoal.EVERGREEN

  return (
    <Stack px={2}>
      <Stack direction={'row'} mb={3} spacing={SPACING} justifyContent={'space-between'}>
        <Stack direction={'row'} spacing={1}>
          <PersonSearchOutlined color={'primary'} />
          <Stack>
            <Typography variant={'label1'}>
              {pageOneWinCalculatorFlag
                ? 'Estimated organic & paid SEO placement wins'
                : 'Organic & Paid SEO placement wins'}
            </Typography>
            <Typography variant={'caption2'}>
              ProductWind creators drive relevancy to win organic and paid slots on Page 1 of search results.
            </Typography>
          </Stack>
        </Stack>

        <Stack minWidth={MIN_WIDTH} direction={'row'} alignItems={'flex-start'} spacing={2} data-cy="seoWins">
          {isAdminView && <HiddenToggle name={'hiddenSeo'} />}
          {pageOneWinCalculatorFlag ? (
            <MetricsDisplay
              label={'Page 1 wins'}
              value={uniq(results?.estimatedPageOneWins)
                ?.filter(v => v)
                ?.map(v => v.toLocaleString())
                .join(' - ')}
              hiddenName={'hiddenSeo'}
            />
          ) : (
            <MetricsDisplay
              value={
                isDisplayResults ? (
                  <CheckCircleOutlineOutlined
                    color={results.improvesOrganicSeoPlacement && !formData.hiddenSeo ? 'primary' : 'disabled'}
                  />
                ) : undefined
              }
              hiddenName={'hiddenSeo'}
            />
          )}
        </Stack>
      </Stack>
      <Stack direction={'row'} mb={3} spacing={SPACING} justifyContent={'space-between'}>
        <Stack direction={'row'} spacing={1}>
          <SlowMotionVideoOutlined color={'primary'} />
          <Stack>
            <Typography variant={'label1'}>Unpublished UGC content</Typography>
            <Typography variant={'caption2'}>
              UGC and Premium UGC creators upload videos and photos directly to the Momentum platform
            </Typography>
          </Stack>
        </Stack>
        <Stack minWidth={MIN_WIDTH} direction={'row'} alignItems={'flex-start'} spacing={2} data-cy="ugcContent">
          {isAdminView && <HiddenToggle name={'hiddenUgcContent'} />}
          <MetricsDisplay
            label={'Photos & videos'}
            value={results?.numUgcContent}
            hiddenName={'hiddenUgcContent'}
            monthly={isMonthly}
          />
        </Stack>
      </Stack>
      <Stack direction={'row'} mb={3} spacing={SPACING} justifyContent={'space-between'}>
        <Stack direction={'row'} spacing={1}>
          <StarOutline color={'primary'} />
          <Stack>
            <Typography variant={'label1'}>Est. incremental reviews & ratings</Typography>
            <Typography variant={'caption2'}>
              Momentum campaigns start the retail flywheel and UGC creators drive incremental reviews & ratings.
            </Typography>
          </Stack>
        </Stack>
        <Stack minWidth={MIN_WIDTH} direction={'row'} alignItems={'flex-start'} spacing={2} data-cy="numReviews">
          {isAdminView && <HiddenToggle name={'hiddenNumReviews'} />}
          <MetricsDisplay
            label={'Reviews & ratings'}
            value={results?.numReviews}
            hiddenName={'hiddenNumReviews'}
            monthly={isMonthly}
          />
        </Stack>
      </Stack>
      {isMonthly && false && (
        <Stack direction={'row'} mb={3} spacing={SPACING} justifyContent={'space-between'}>
          <Stack direction={'row'} spacing={1}>
            <AutoGraphOutlined color={'primary'} />
            <Stack>
              <Typography variant={'label1'}>Est. months to improve star rating</Typography>
              <Typography variant={'caption2'}>
                {selectedProduct?.ratingSummary?.rating != null
                  ? `From ${selectedProduct?.ratingSummary?.rating} current rating to ${results.nextLevelRating} star rating.`
                  : `Could not calculate current star rating on listing`}
              </Typography>
            </Stack>
          </Stack>
          <Stack minWidth={MIN_WIDTH} direction={'row'} alignItems={'flex-start'} spacing={2} data-cy="endRating">
            {isAdminView && <HiddenToggle name={'hiddenEndRating'} />}
            <MetricsDisplay
              label={'Month(s)'}
              value={Math.ceil((results.creatorsRequiredForNextLevel || 0) / (results.totalCreators || 1))}
              hiddenName={'hiddenEndRating'}
            />
          </Stack>
        </Stack>
      )}
      {/*Temp disabled*/}
      {!isMonthly && false && (
        <Stack direction={'row'} mb={3} spacing={SPACING} justifyContent={'space-between'}>
          <Stack direction={'row'} spacing={1}>
            <RateReviewOutlined color={'primary'} />
            <Stack>
              <Typography variant={'label1'}>Est. star rating after the campaign</Typography>
              <Typography variant={'caption2'}>
                {selectedProduct?.ratingSummary?.rating != null
                  ? `Based on current ${selectedProduct?.ratingSummary?.rating} star rating on listing.`
                  : `Could not calculate current star rating on listing`}
              </Typography>
            </Stack>
          </Stack>
          <Stack minWidth={MIN_WIDTH} direction={'row'} alignItems={'flex-start'} spacing={2} data-cy="endRating">
            {isAdminView && <HiddenToggle name={'hiddenEndRating'} />}
            <MetricsDisplay label={'Star rating'} value={results?.endingRating} hiddenName={'hiddenEndRating'} />
          </Stack>
        </Stack>
      )}
      <Stack direction={'row'} mb={3} spacing={SPACING} justifyContent={'space-between'}>
        <Stack direction={'row'} spacing={1}>
          <SocialPortraitIcon color={'primary'} />
          <Stack>
            <Typography variant={'label1'}>Published social content</Typography>
            <Typography variant={'caption2'}>Social creators post videos on Instagram and/or Tiktok.</Typography>
          </Stack>
        </Stack>
        <Stack minWidth={MIN_WIDTH} direction={'row'} alignItems={'flex-start'} spacing={2} data-cy="socialContent">
          {isAdminView && <HiddenToggle name={'hiddenSocialContent'} />}
          <MetricsDisplay
            label={'Social posts & stories'}
            value={results?.numSocialContent}
            hiddenName={'hiddenSocialContent'}
            monthly={isMonthly}
          />
        </Stack>
      </Stack>
      <Stack direction={'row'} mb={1} spacing={SPACING} justifyContent={'space-between'}>
        <Stack direction={'row'} spacing={1}>
          <EmojiPeopleOutlined color={'primary'} />
          <Stack>
            <Typography variant={'label1'}>Est. traffic from ProductWind creators</Typography>
            <Typography variant={'caption2'}>Brand Advocates drive traffic to the product detail page.</Typography>
          </Stack>
        </Stack>

        <Stack minWidth={MIN_WIDTH} direction={'row'} alignItems={'flex-start'} spacing={2} data-cy="pdpTraffic">
          {isAdminView && <HiddenToggle name={'hiddenPdpTraffic'} />}
          <MetricsDisplay
            label={'Views'}
            value={results?.estimatedPdpViews}
            hiddenName={'hiddenPdpTraffic'}
            monthly={isMonthly}
          />
        </Stack>
      </Stack>
      <Stack display={'none'} direction={'row'} mb={1} spacing={SPACING} justifyContent={'space-between'}>
        <Stack direction={'row'} spacing={1}>
          <TouchAppOutlined color={'primary'} />
          <Stack>
            <Typography variant={'label1'}>Improve retail media efficiency</Typography>
            <Typography variant={'caption2'}>Traffic from creators improves paid ad placement and ROAS</Typography>
          </Stack>
        </Stack>

        <Stack minWidth={MIN_WIDTH} direction={'row'} alignItems={'flex-start'} spacing={2} data-cy="retailEfficiency">
          {isAdminView && <HiddenToggle name={'hiddenRetailEfficiency'} />}
          <MetricsDisplay
            value={
              isDisplayResults ? (
                <CheckCircleOutlineOutlined
                  color={
                    results.improvesRetailMediaEfficiency && !formData.hiddenRetailEfficiency ? 'primary' : 'disabled'
                  }
                />
              ) : undefined
            }
            hiddenName={'hiddenRetailEfficiency'}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

const HiddenToggle = ({ name }: { name: string }) => {
  const {
    field: { value, onChange }
  } = useController({ name })

  return (
    <IconButton size={'small'} onClick={() => onChange(!value)}>
      {value ? <VisibilityOffOutlined fontSize={'small'} /> : <VisibilityOutlined fontSize={'small'} />}
    </IconButton>
  )
}

const MetricsDisplay = ({
  value,
  label,
  hiddenName,
  leadingIcon,
  monthly
}: {
  value?: string | number | ReactElement
  label?: string
  hiddenName: string
  leadingIcon?: ReactElement
  monthly?: boolean
}) => {
  const hidden = useWatch({ name: hiddenName })

  return (
    <Stack>
      <Typography
        variant={'label1'}
        color={theme => (hidden ? theme.palette.grey.A700 : 'black')}
        data-cy="metricValue"
      >
        {leadingIcon}
        {value ? (typeof value === 'number' ? value.toLocaleString() : value) : '--'}{' '}
        {value != null && monthly ? '/mo' : ''}
      </Typography>
      {!!label && (
        <Typography variant={'caption2'} color={theme => (hidden ? theme.palette.grey.A700 : 'black')}>
          {label}
        </Typography>
      )}
    </Stack>
  )
}
