import { NoPreview } from '@momentum/components/no-preview'
import Video from '@momentum/components/video'
import {
  ContentTypeToSocialString,
  ContentTypeToString,
  SocialChannelToIcon,
  getHandleLink,
  getSocialProfile
} from '@momentum/utils/socialUtils'
import { Circle, Close, Favorite, FavoriteBorder, FileDownloadOutlined, Launch } from '@mui/icons-material'
import { Box, Button, Grid, IconButton, Link, Pagination, Slide, Stack, Typography } from '@mui/material'
import { Channel } from '@productwindtom/shared-momentum-zeus-types'
import { isVideo } from '@productwindtom/shared-node'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useContentContext } from '../context'
import { CreatorAndContent } from '../types'

const ContentDialog = ({ content }: { content: CreatorAndContent }) => {
  const { favoriteContent, unfavoriteContent, filteredContent } = useContentContext()
  const [isFavorite, setIsFavorite] = useState(content.isFavorite)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [otherContentOpen, setOtherContentOpen] = useState(false)
  const containerRef = React.useRef<HTMLDivElement>(null)
  console.log(content)
  const mediaFlat = content.content.map(c => (c.assets.length ? c.assets : [{}]).map(a => ({ ...c, ...a }))).flat()
  console.log(mediaFlat)
  const contentSelected = mediaFlat[selectedIndex]
  const isVideoAsset = contentSelected?.url ? isVideo(contentSelected.url) : false

  const socialProfile = getSocialProfile(content.channel as Channel, content)
  const handle = socialProfile?.handle
    ? `@${socialProfile.handle}`
    : `${content.firstName} ${content.lastName?.charAt(0)}`
  const handleLink = getHandleLink(content.channel as Channel, content)

  const creatorOtherContent =
    filteredContent
      .filter(c => c.creatorId === content.creatorId && c.id !== content.id)
      .filter(c => c.content.some(a => a.assets.find(aa => aa.url))) || []

  const metrics = [
    !!contentSelected.likes && (
      <Typography key={'likes'} variant={'body1'}>
        {contentSelected.likes.toLocaleString()} likes
      </Typography>
    ),
    !!contentSelected.comments && (
      <Typography key={'comments'} variant={'body1'}>
        {contentSelected.comments.toLocaleString()} comments
      </Typography>
    ),
    !!contentSelected.views && (
      <Typography key={'views'} variant={'body1'}>
        {contentSelected.views.toLocaleString()} views
      </Typography>
    )
  ]
    .filter(e => e)
    .flatMap((e, index) => [
      e,
      <Typography key={'bullet' + index} variant={'body1'}>
        {'\u2022'}
      </Typography>
    ])
    .slice(0, -1)

  return (
    <Stack
      ref={containerRef}
      py={2}
      px={2}
      bgcolor={theme => theme.palette.grey.A100}
      borderRadius={'4px'}
      border={theme => `1px solid ${theme.palette.grey.A200}`}
      position={'relative'}
      overflow={'hidden'}
    >
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Stack spacing={1} alignItems={'space-between'}>
            {isVideoAsset && contentSelected.url ? (
              <Video
                src={contentSelected.url}
                width={'100%'}
                maxHeight={'635px'}
                objectFit={'contain'}
                borderRadius={'4px'}
              />
            ) : contentSelected.url ? (
              <img
                src={contentSelected.url}
                style={{
                  maxHeight: '635px',
                  width: '100%',
                  borderRadius: '4px',
                  objectFit: 'contain',
                  objectPosition: 'top'
                }}
              />
            ) : (
              <NoPreview height={'100%'} minHeight={500} />
            )}
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Link href={contentSelected?.url} download>
                <FileDownloadOutlined />
              </Link>
              {mediaFlat.length > 1 && (
                <Pagination
                  count={mediaFlat.length}
                  page={selectedIndex + 1}
                  onChange={(e, page) => setSelectedIndex(page - 1)}
                  hidePrevButton
                  hideNextButton
                  renderItem={item => (
                    <IconButton onClick={item.onClick} size={'small'} disableRipple>
                      <Circle
                        fontSize={'small'}
                        sx={{ color: theme => (item.selected ? theme.palette.primary.main : theme.palette.grey.A200) }}
                      />
                    </IconButton>
                  )}
                />
              )}
              <IconButton
                size={'small'}
                onClick={() => {
                  setIsFavorite(!isFavorite)
                  if (!isFavorite) {
                    favoriteContent(content.id)
                  } else {
                    unfavoriteContent(content.id)
                  }
                }}
              >
                {isFavorite ? <Favorite color={'error'} /> : <FavoriteBorder />}
              </IconButton>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={5}>
          <Stack spacing={4} border={theme => `1px solid ${theme.palette.grey.A200}`} borderRadius={'4px'} p={2}>
            {socialProfile && (
              <Stack>
                {handle && (
                  <Stack direction={'row'} alignItems={'center'}>
                    <Typography variant={'link1'}>{handle}</Typography>
                    {!!handleLink && (
                      <IconButton size={'small'} href={handleLink} target={'_blank'}>
                        <Launch color={'primary'} />
                      </IconButton>
                    )}
                  </Stack>
                )}
                {!!(socialProfile.followers || socialProfile.engagementRate) && (
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    {socialProfile.followers && (
                      <Typography variant={'body1'}>{socialProfile.followers?.toLocaleString()} followers</Typography>
                    )}
                    {socialProfile.followers && socialProfile.engagementRate && (
                      <Typography variant={'body1'}>{'\u2022'}</Typography>
                    )}
                    {socialProfile.engagementRate && (
                      <Typography variant={'body1'}>
                        {socialProfile.engagementRate.toFixed(1)}% creator engagement rate
                      </Typography>
                    )}
                  </Stack>
                )}
              </Stack>
            )}

            <Stack spacing={1}>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Typography variant={'button2'}>Posted:</Typography>
                <Typography variant={'body1'}>
                  {DateTime.fromISO(content.submittedAt).toLocaleString(DateTime.DATE_SHORT)}
                </Typography>
                {contentSelected.engagements && (
                  <>
                    <Typography variant={'body1'}>{'\u2022'}</Typography>
                    <Typography variant={'body1'}>
                      {contentSelected.engagements.toLocaleString()} engagements
                    </Typography>
                  </>
                )}
              </Stack>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Typography variant={'button2'}>Content type:</Typography>
                <Typography variant={'body2'}>{ContentTypeToSocialString[content.contentType]}</Typography>
              </Stack>
            </Stack>

            {!!metrics.length && (
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                {metrics}
              </Stack>
            )}

            {!!contentSelected.caption && (
              <Stack>
                <Typography variant={'button2'}>Caption:</Typography>
                <Typography variant={'body2'}>{contentSelected.caption}</Typography>
              </Stack>
            )}
            {creatorOtherContent.length > 0 && (
              <Box>
                <Button variant={'contained'} onClick={() => setOtherContentOpen(true)}>
                  View more from this creator
                </Button>
              </Box>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Slide in={otherContentOpen} container={containerRef.current} direction={'up'}>
        <Stack
          bgcolor={theme => theme.palette.grey.A100}
          borderTop={theme => `1px solid ${theme.palette.grey.A200}`}
          borderRadius={'4px'}
          position={'absolute'}
          bottom={0}
          left={0}
          width={'100%'}
          pb={4}
          zIndex={9}
        >
          <Box alignSelf={'flex-end'}>
            <IconButton onClick={() => setOtherContentOpen(false)}>
              <Close />
            </IconButton>
          </Box>
          <Stack direction={'row'} spacing={3} justifyContent={'center'}>
            {creatorOtherContent.slice(0, 4).map(c => (
              <OtherContentCard key={c.id} content={c} />
            ))}
          </Stack>
        </Stack>
      </Slide>
    </Stack>
  )
}

const OtherContentCard = ({ content }: { content: CreatorAndContent }) => {
  const contentSelected = content.content.find(c => c.assets.find(aa => aa.url))
  const asset = contentSelected?.assets.find(aa => aa.url)

  if (!asset) {
    return null
  }

  const isVideoAsset = isVideo(asset.url)

  return (
    <Box
      position={'relative'}
      width={200}
      sx={{
        '.after': {
          width: '100%',
          display: 'none'
        },
        '&.pause-button': {
          display: 'inline-flex'
        },
        '&:hover': {
          background: 'rgba(0, 0, 0, .6)'
        },
        '&:hover .after': {
          display: 'flex'
        }
      }}
    >
      {isVideoAsset ? (
        <Video src={asset.url} width={'100%'} height={'285px'} maxHeight={'285px'} objectFit={'cover'} />
      ) : (
        <img
          src={asset.url}
          style={{
            height: '285px',
            width: '100%',
            borderRadius: '4px',
            objectFit: 'cover'
          }}
        />
      )}
      <Stack
        className={'after'}
        direction={'row'}
        position={'absolute'}
        bottom={0}
        left={0}
        justifyContent={'center'}
        alignItems={'flex-end'}
        spacing={1}
        pb={1}
        borderRadius={'4px'}
      >
        {SocialChannelToIcon[content.channel]?.({
          fontSize: 'mSmall',
          sx: {
            zIndex: 2,
            // backgroundColor: 'white',
            borderRadius: '50%'
          }
        })}
        <Typography color={'white'} variant={'caption3'}>
          {ContentTypeToString[content.contentType]}
        </Typography>
        <Typography color={'white'} variant={'caption3'}>
          {'\u2022'}
        </Typography>
        <Typography color={'white'} variant={'caption3'}>
          Submitted on {DateTime.fromISO(content.submittedAt).toLocaleString(DateTime.DATE_SHORT)}
        </Typography>
      </Stack>
    </Box>
  )
}

export default ContentDialog
