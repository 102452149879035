import SocialPortraitIcon from '@momentum/components/icons/social-portrait'
import { BenchmarkProductsInput } from '@momentum/components/proposal-common/BenchmarkProductsInput'
import { MultiTextListInput } from '@momentum/components/proposal-common/MultiTextListInput'
import { Question } from '@momentum/components/proposal-common/Question'
import { Product, Proposal } from '@momentum/routes/campaign/context/queries'
import { CreatorScheduleViewer } from '@momentum/routes/campaign/overview/proposal/CreatorScheduleViewer'
import {
  CancelOutlined,
  CheckCircleOutline,
  ContactPageOutlined,
  EmojiPeopleOutlined,
  PersonSearchOutlined,
  SlowMotionVideoOutlined,
  StarOutline
} from '@mui/icons-material'
import { Alert, AlertTitle, Chip, Divider, Grid, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { CalendarIcon } from '@mui/x-date-pickers'
import { CampaignProposalResults } from '@productwindtom/shared-momentum'
import { InvoicePaymentType, InvoiceStatus, PaymentTermsType } from '@productwindtom/shared-momentum-zeus-types'
import { DateInput, RadioInput } from '@productwindtom/ui-base'
import { uniq } from 'lodash'
import { DateTime } from 'luxon'
import { ReactElement } from 'react'
import { useWatch } from 'react-hook-form'
import { useCampaignContext } from '../../context/CampaignContext'
import ProductVariations from './ProductVariations'
import Row from '@momentum/components/row'
import { CampaignGoalTypeToIcon } from '@momentum/utils/goalIcons'
import { CampaignGoalTypeToName } from '@momentum/utils/campaignGoalUtils'
import { DEFAULT_NET_TERMS, PaymentTypeToTitle } from '@momentum/utils/proposalUtils'
import { EditProposalForm } from '.'
import { useUserSessionContext } from '@momentum/contexts/UserSession'

const MAX_SEARCH_TERMS = 5

export const CampaignImpact = ({
  proposal,
  proposalResults,
  product,
  isEditingProposal
}: {
  proposal: Proposal
  proposalResults: CampaignProposalResults
  product?: Product
  isEditingProposal: boolean
}) => {
  const {
    campaignDetails: { anticipatedStartDate }
  } = useCampaignContext()

  const { isAdminView, selectedCompany, agency, selectedBrand } = useUserSessionContext()

  const {
    invoiceStatus,
    invoiceProductCostStatus,
    benchmarkProducts = [],
    initialBenchmarkProducts = [],
    initialSearchTerms = [],
    searchTerms = [],
    invoiceAdditionalInformation,
    invoicePONumber,
    invoiceProductCostPONumber
  } = useWatch<EditProposalForm>()

  const isLaunchDateDisabled = DateTime.fromISO(anticipatedStartDate).toISODate()! <= DateTime.now().toISODate()

  const is24HoursBeforeLaunch = DateTime.fromISO(anticipatedStartDate).minus({ days: 1 }) > DateTime.now()

  const agencyTerms = agency?.paymentTermsType
  const netTermsType = agencyTerms || selectedCompany?.paymentTermsType

  const netTerms =
    netTermsType === PaymentTermsType.NET_CUSTOM
      ? agency?.paymentTermsCustomNetDays || selectedCompany?.paymentTermsCustomNetDays || 0
      : DEFAULT_NET_TERMS

  const productCostNetTermsType = agencyTerms || selectedCompany?.productCostPaymentTermsType
  const productCostNetTerms =
    productCostNetTermsType === PaymentTermsType.NET_CUSTOM
      ? agency?.paymentTermsCustomNetDays || selectedCompany?.productCostPaymentTermsCustomNetDays || 0
      : DEFAULT_NET_TERMS

  const hasSplitPayments = !!proposal.invoiceProductCostId

  return (
    <Stack spacing={4}>
      {!proposal.hiddenSeo && (
        <MetricRow
          title={'Estimated organic & paid SEO placement wins'}
          subtitle={'ProductWind creators drive relevancy to win organic and paid slots on Page 1 of search results.'}
          icon={<PersonSearchOutlined color={'primary'} />}
          metricLabel={'Page 1 wins'}
          value={uniq(proposalResults?.estimatedPageOneWins)
            ?.map(v => v.toLocaleString())
            .join(' - ')}
        />
      )}

      {!proposal.hiddenUgcContent && (
        <MetricRow
          title={'Unpublished premium UGC content'}
          subtitle={'UGC and Premium UGC creators upload videos and photos directly to the Momentum platform.'}
          icon={<SlowMotionVideoOutlined color={'primary'} />}
          value={proposalResults.numUgcContent}
          metricLabel={'Photos & videos'}
        />
      )}

      {!proposal.hiddenNumReviews && (
        <MetricRow
          title={'Est. incremental reviews & ratings'}
          subtitle={
            'Momentum campaigns start the retail flywheel and UGC creators drive incremental reviews & ratings.'
          }
          icon={<StarOutline color={'primary'} />}
          value={proposalResults.numReviews}
          metricLabel={'Reviews & ratings'}
        />
      )}

      {!proposal.hiddenSocialContent && (
        <MetricRow
          title={'Published social content'}
          subtitle={'Social creators post videos on Instagram and/or Tiktok.'}
          icon={<SocialPortraitIcon color={'primary'} />}
          metricLabel={'Social posts & stories'}
          value={proposalResults.numSocialContent}
        />
      )}

      {!proposal.hiddenPdpTraffic && (
        <MetricRow
          title={'Est. traffic from ProductWind creators'}
          subtitle={'Brand Advocates drive traffic to the product detail page.'}
          icon={<EmojiPeopleOutlined color={'primary'} />}
          value={proposalResults.estimatedPdpViews}
          metricLabel={'Views'}
        />
      )}

      <Divider />
      <Typography variant="label1">What is the campaign goal?</Typography>
      <Box
        bgcolor={theme => theme.palette.primary.lightest}
        display={'flex'}
        flexDirection={'column'}
        alignSelf={'flex-start'}
        justifyContent={'center'}
        alignItems={'center'}
        py={3}
        px={2}
        border={theme => `1px solid ${theme.palette.primary.lighter}`}
        borderRadius={'4px'}
      >
        {CampaignGoalTypeToIcon[proposal.goal]?.({
          fontSize: 'medium',
          sx: { color: theme => theme.palette.primary.main }
        })}
        <Typography variant={'label2'} mt={1}>
          {CampaignGoalTypeToName[proposal.goal]}
        </Typography>
      </Box>
      <Divider />
      {!!proposal.eventDate && (
        <>
          <Question primaryText={'What is the start date of your holiday or promo?'}>
            <Row spacing={1}>
              <CalendarIcon color="primary" />
              <Typography variant="label3">
                {DateTime.fromISO(proposal.eventDate).toLocaleString(DateTime.DATE_SHORT)}
              </Typography>
            </Row>
          </Question>
          <Divider />
        </>
      )}
      <Stack spacing={3}>
        <Typography variant="label1">What is the launch date for this campaign?</Typography>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Stack spacing={1} alignItems={'flex-start'}>
                <Typography variant="label1" fontWeight={400}>
                  Selected in proposal:
                </Typography>
                {isEditingProposal && isAdminView ? (
                  <DateInput name="launchDate" disabled readOnly />
                ) : (
                  <Row spacing={1}>
                    <CalendarIcon color="primary" />
                    <Typography variant="label3">
                      {DateTime.fromISO(proposal.launchDate).toLocaleString(DateTime.DATE_SHORT)}
                    </Typography>
                  </Row>
                )}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={1} alignItems={'flex-start'}>
                <Typography variant="label1" fontWeight={400}>
                  Current launch date:
                </Typography>
                {isEditingProposal ? (
                  <DateInput
                    name="campaignLaunchDate"
                    minDate={isAdminView ? DateTime.now().plus({ days: 1 }) : DateTime.fromISO(proposal.launchDate)}
                    disabled={isLaunchDateDisabled}
                    readOnly={isLaunchDateDisabled}
                  />
                ) : (
                  <Row spacing={1}>
                    <CalendarIcon color="primary" />
                    <Typography variant="label3">
                      {DateTime.fromISO(anticipatedStartDate).toLocaleString(DateTime.DATE_SHORT)}
                    </Typography>
                  </Row>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <Divider />
      <Question
        primaryText={'Estimated units sold for this product during the first month of the campaign'}
        subtext={'This is used to estimate your campaign timeline.'}
      >
        <Box>
          <Typography variant="label3">{proposal.estimatedUnitsSoldPerMonth}</Typography>
        </Box>
      </Question>
      <Divider />

      <Question primaryText="How are you paying for this campaign?">
        <Typography variant="label3">{PaymentTypeToTitle[proposal.paymentType!]}</Typography>
      </Question>

      {[InvoicePaymentType.EXISTING_INVOICE, InvoicePaymentType.NEW_INVOICE].includes(proposal.paymentType!) && (
        <>
          <Divider />
          <Box>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Stack spacing={4} alignItems={'flex-start'}>
                  <Question primaryText={'Has the invoice already been paid?'}>
                    {isEditingProposal && isAdminView ? (
                      <RadioInput
                        name={'invoiceStatus'}
                        radioProps={{ sx: { py: 0.5 }, disableRipple: true }}
                        options={[
                          { label: <Typography variant={'label3'}>Yes</Typography>, value: InvoiceStatus.PAID },
                          { label: <Typography variant={'label3'}>No</Typography>, value: InvoiceStatus.NOT_PAID }
                        ]}
                      />
                    ) : (
                      <Row spacing={1}>
                        {invoiceStatus === InvoiceStatus.PAID ? (
                          <CheckCircleOutline color="primary" />
                        ) : (
                          <CancelOutlined color="primary" />
                        )}
                        <Typography variant="label3">{invoiceStatus === InvoiceStatus.PAID ? 'Yes' : 'No'}</Typography>
                      </Row>
                    )}
                  </Question>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={4}>
                  <Question
                    primaryText={`When is the ${hasSplitPayments ? 'creator cost' : 'payment'} due?`}
                    subtext={
                      <Stack>
                        <Typography variant="body2">
                          The campaign start date is scheduled fors{' '}
                          {DateTime.fromISO(proposal.launchDate).toLocaleString(DateTime.DATE_SHORT)}.
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: '800' }}>
                          Payment is due net {netTerms} days before the campaign start date.
                        </Typography>
                      </Stack>
                    }
                    fullWidth={false}
                  >
                    <Row spacing={1}>
                      <CalendarIcon color="primary" />
                      <Typography variant="label3">
                        {proposal.invoiceDueDate
                          ? DateTime.fromISO(proposal.invoiceDueDate!).toLocaleString(DateTime.DATE_SHORT)
                          : DateTime.fromISO(proposal.launchDate)
                              .minus({ days: netTerms })
                              .toLocaleString(DateTime.DATE_SHORT)}
                      </Typography>
                    </Row>
                  </Question>
                  <Question primaryText={'PO number'} fullWidth={false}>
                    <Typography variant="label3">{invoicePONumber || '--'}</Typography>
                  </Question>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          {proposal.paymentType === InvoicePaymentType.NEW_INVOICE && hasSplitPayments && (
            <>
              <Divider />
              <Box>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Stack spacing={4} alignItems={'flex-start'}>
                      <Question primaryText={'Has the invoice already been paid?'}>
                        {isEditingProposal && isAdminView ? (
                          <RadioInput
                            name={'invoiceProductCostStatus'}
                            radioProps={{ sx: { py: 0.5 }, disableRipple: true }}
                            options={[
                              { label: <Typography variant={'label3'}>Yes</Typography>, value: InvoiceStatus.PAID },
                              { label: <Typography variant={'label3'}>No</Typography>, value: InvoiceStatus.NOT_PAID }
                            ]}
                          />
                        ) : (
                          <Row spacing={1}>
                            {invoiceProductCostStatus === InvoiceStatus.PAID ? (
                              <CheckCircleOutline color="primary" />
                            ) : (
                              <CancelOutlined color="primary" />
                            )}
                            <Typography variant="label3">
                              {invoiceProductCostStatus === InvoiceStatus.PAID ? 'Yes' : 'No'}
                            </Typography>
                          </Row>
                        )}
                      </Question>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={4}>
                      <Question
                        primaryText={'When is the product cost due?'}
                        subtext={
                          <Stack>
                            <Typography variant="body2">
                              The campaign start date is scheduled for{' '}
                              {DateTime.fromISO(proposal.launchDate).toLocaleString(DateTime.DATE_SHORT)}.
                            </Typography>
                            <Typography variant="body2" sx={{ fontWeight: '800' }}>
                              Payment is due net {productCostNetTerms} days from the campaign start date.
                            </Typography>
                          </Stack>
                        }
                        fullWidth={false}
                      >
                        <Row spacing={1}>
                          <CalendarIcon color="primary" />
                          <Typography variant="label3">
                            {!!proposal.invoiceProductCostDueDate &&
                              DateTime.fromISO(proposal.invoiceProductCostDueDate).toLocaleString(DateTime.DATE_SHORT)}
                          </Typography>
                        </Row>
                      </Question>
                      <Question primaryText={'PO number'} fullWidth={false}>
                        <Typography variant="label3">{invoiceProductCostPONumber || '--'}</Typography>
                      </Question>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}

          <Divider />
          <Box>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Stack spacing={4}>
                  <Question
                    primaryText={'Contact responsible for paying the invoice'}
                    subtext={`The invoice will be emailed to this contact.`}
                  >
                    <Row spacing={1} alignItems={'flex-start'}>
                      <ContactPageOutlined color="primary" />
                      <Stack>
                        <Typography variant="label3">
                          {proposal.paymentBillingContact?.email || selectedBrand?.paymentBillingContact?.email}
                        </Typography>
                        <Typography variant="label3">
                          {proposal.paymentBillingContact?.name || selectedBrand?.paymentBillingContact?.name}
                        </Typography>
                      </Stack>
                    </Row>
                  </Question>
                  <Question
                    primaryText={'Contacts to be cc’ed on the invoice'}
                    subtext={`The invoice will be cc’ed to these contacts.`}
                  >
                    <Stack spacing={2}>
                      {(proposal.billingContacts || []).map(bc => (
                        <Row key={bc.email} spacing={1} alignItems={'flex-start'}>
                          <ContactPageOutlined color="primary" />
                          <Stack>
                            <Typography variant="label3">{bc.email}</Typography>
                            <Typography variant="label3">{bc.name}</Typography>
                          </Stack>
                        </Row>
                      ))}
                    </Stack>
                  </Question>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={4}>
                  {!!invoiceAdditionalInformation && (
                    <Question
                      primaryText={'Additional invoice information'}
                      subtext="Any additional information which is required by your finance team to be added to the invoice."
                    >
                      <Typography variant="label3">{invoiceAdditionalInformation}</Typography>
                    </Question>
                  )}
                  {!!proposal.invoicePOSystem && (
                    <Question primaryText={'What is your invoicing system?'} fullWidth={false}>
                      <Typography variant="label3">{proposal.invoicePOSystem}</Typography>
                    </Question>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      <Divider />
      <Question primaryText={'What search terms are you tracking for this product?'}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Stack spacing={3}>
              <Typography variant="label1" fontWeight={400}>
                Selected in proposal:
              </Typography>
              <Stack spacing={2} alignItems={'flex-start'}>
                {initialSearchTerms.length ? (
                  initialSearchTerms?.map(term => (
                    <Chip label={<Typography variant={'label3'}>{term}</Typography>} color="secondary" size="small" />
                  ))
                ) : (
                  <Typography variant="label3">--</Typography>
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={3}>
              <Typography variant="label1" fontWeight={400}>
                Current search terms:
              </Typography>
              {isEditingProposal ? (
                <>
                  <MultiTextListInput
                    name={'searchTerms'}
                    addText={'Add search term'}
                    placeholder={'Enter search term'}
                    max={MAX_SEARCH_TERMS}
                    disabled={!is24HoursBeforeLaunch}
                    validate={value => {
                      return searchTerms?.map(s => s.trim()).includes(value?.trim())
                        ? 'This search term has already been added! Please enter a new search term.'
                        : undefined
                    }}
                  />
                  {!is24HoursBeforeLaunch && (
                    <Alert
                      severity={'warning'}
                      variant={'outlined'}
                      style={{
                        marginTop: '8px',
                        alignSelf: 'flex-start',
                        maxWidth: '360px'
                      }}
                    >
                      <AlertTitle paddingBottom={0}>
                        <Typography variant={'label3'} color={'black'}>
                          We’re unable to add or remove search terms for campaigns launching in less than 24 hours.
                        </Typography>
                      </AlertTitle>
                    </Alert>
                  )}
                  {searchTerms.length >= MAX_SEARCH_TERMS && (
                    <Alert
                      severity={'info'}
                      variant={'outlined'}
                      style={{
                        marginTop: '8px',
                        alignSelf: 'flex-start'
                      }}
                    >
                      <AlertTitle paddingBottom={0}>
                        <Typography variant={'label3'} color={'black'}>
                          We’re able to track a maximum of 5 search terms before your campaign launches.
                        </Typography>
                      </AlertTitle>
                    </Alert>
                  )}
                </>
              ) : (
                <Stack spacing={2} alignItems={'flex-start'}>
                  {searchTerms.length ? (
                    searchTerms?.map(term => (
                      <Chip
                        label={<Typography variant={'label3'}>{term}</Typography>}
                        sx={{
                          bgcolor: theme => theme.palette.info.main,
                          color: 'white'
                        }}
                        size="small"
                      />
                    ))
                  ) : (
                    <Typography variant="label3">--</Typography>
                  )}
                </Stack>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Question>
      <Divider />

      <Question
        primaryText={'What products are you benchmarking against?'}
        subtext={
          'Momentum will benchmark your product against other relevant products for traffic, sales, and revenue. Add up to 3 relevant products. Our system will review the input ASINs to determine if they are eligible to be used for benchmarking.'
        }
      >
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Stack spacing={3}>
              <Typography variant={'label1'} fontWeight={400}>
                Selected in proposal:
              </Typography>
              <Stack spacing={2}>
                {initialBenchmarkProducts.length ? (
                  initialBenchmarkProducts?.map((benchmark, index) => (
                    <Typography
                      key={[benchmark, index].join('-')}
                      variant={'label3'}
                      noWrap
                      color={theme => theme.palette.grey.A700}
                    >
                      {benchmark}
                    </Typography>
                  ))
                ) : (
                  <Typography variant="label3">--</Typography>
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={3}>
              <Typography variant={'label1'} fontWeight={400}>
                Current benchmarked products:
              </Typography>
              {benchmarkProducts.length || isEditingProposal ? (
                <BenchmarkProductsInput
                  name={'benchmarkProducts'}
                  addText={'Add product'}
                  placeholder={'Enter product URL'}
                  storeName={product?.store}
                  max={3}
                  disabled={!isEditingProposal}
                />
              ) : (
                <Typography variant="label3">--</Typography>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Question>
      <Divider />

      {proposal.productVariationSkus && product && (
        <>
          <Question primaryText={'Eligible product variations'}>
            <ProductVariations product={product} productVariationSkus={proposal.productVariationSkus} />
          </Question>
          <Divider />
        </>
      )}

      {proposal.isDailyScheduling && (
        <>
          <CreatorScheduleViewer
            startDate={DateTime.fromISO(anticipatedStartDate)}
            eventDate={proposal.eventDate ? DateTime.fromISO(proposal.eventDate) : undefined}
            creatorPricing={proposal.creatorPricing}
          />
          <Divider />
        </>
      )}
    </Stack>
  )
}

const MetricRow = ({
  icon,
  title,
  subtitle,
  metricLabel,
  value
}: {
  icon: ReactElement
  title: string
  subtitle: string
  metricLabel?: string
  value: string | number
}) => {
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={8}>
          <Stack direction={'row'} spacing={1}>
            {icon}
            <Stack>
              <Typography variant={'label1'}>{title}</Typography>
              <Typography variant={'caption2'}>{subtitle}</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack direction={'row'} alignItems={'flex-start'} spacing={2}>
            <MetricsDisplay label={metricLabel} value={value} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

const MetricsDisplay = ({ value, label }: { value?: string | number | ReactElement; label?: string }) => {
  return (
    <Stack>
      <Typography variant={'label1'} color={'black'} data-cy="metricValue">
        {value ? (typeof value === 'number' ? value.toLocaleString() : value) : '--'}{' '}
      </Typography>
      {!!label && (
        <Typography variant={'caption2'} color={'black'}>
          {label}
        </Typography>
      )}
    </Stack>
  )
}
