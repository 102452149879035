import {
  ChannelToContentTypes,
  ContentTypeToString,
  ExtendedChannel,
  ExtendedContentType,
  PremiumChannel
} from '@momentum/utils/socialUtils'
import {
  Check,
  ChevronRight,
  Close,
  KeyboardArrowDown,
  LocalPoliceOutlined,
  SlowMotionVideoOutlined
} from '@mui/icons-material'
import { Box, Button, Grow, Menu, MenuItem, MenuList, Paper, Popper, Stack, Typography } from '@mui/material'
import { Channel, ContentType, CreatorType } from '@productwindtom/shared-momentum-zeus-types'
import { groupBy, keyBy, without } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useContentContext } from '../context'

const ContentTypeFilter = () => {
  const { content, isSearch } = useContentContext()
  const [excludedContentTypes, setExcludedContentTypes] = useState<ExtendedContentType[]>([])
  const { setValue, getValues } = useFormContext()

  useEffect(() => {
    const excludedTypes = getValues('excludedContentTypes')

    if (JSON.stringify(excludedTypes) !== JSON.stringify(excludedContentTypes)) {
      setValue('excludedContentTypes', excludedContentTypes)
    }
  }, [excludedContentTypes, setValue, getValues])

  const contentByChannel = groupBy(content, c =>
    c.creatorType === CreatorType.PREMIUM_UGC ? PremiumChannel.PREMIUM_UGC : c.channel
  )

  const hasUgc = isSearch || contentByChannel[Channel.UGC]?.length
  const hasPremiumUgc = !isSearch && contentByChannel[PremiumChannel.PREMIUM_UGC]?.length
  const hasIg = isSearch || contentByChannel[Channel.INSTAGRAM]?.length
  const hasTt = isSearch || contentByChannel[Channel.TIKTOK]?.length
  const hasYt = isSearch || contentByChannel[Channel.YOUTUBE]?.length
  const hasSocial = hasIg || hasTt || hasYt

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [igAnchorEl, setIgAnchorEl] = React.useState<null | HTMLElement>(null)
  const [ttAnchorEl, setTtAnchorEl] = React.useState<null | HTMLElement>(null)
  const [ytAnchorEl, setYtAnchorEl] = React.useState<null | HTMLElement>(null)
  const [premiumUgcAnchorEl, setPremiumUgcAnchorEl] = React.useState<null | HTMLElement>(null)
  const [ugcAnchorEl, setUgcAnchorEl] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleHoverForIg = (event: React.MouseEvent<HTMLLIElement | HTMLDivElement>, isEnter: boolean) => {
    if (isEnter) {
      setTtAnchorEl(null)
      setYtAnchorEl(null)
      setPremiumUgcAnchorEl(null)
      setUgcAnchorEl(null)
      setIgAnchorEl(event.currentTarget)
    } else {
      setIgAnchorEl(null)
    }
  }

  const handleHoverForTt = (event: React.MouseEvent<HTMLLIElement | HTMLDivElement>, isEnter: boolean) => {
    if (isEnter) {
      setIgAnchorEl(null)
      setYtAnchorEl(null)
      setPremiumUgcAnchorEl(null)
      setUgcAnchorEl(null)
      setTtAnchorEl(event.currentTarget)
    } else {
      setTtAnchorEl(null)
    }
  }

  const handleHoverForYt = (event: React.MouseEvent<HTMLLIElement | HTMLDivElement>, isEnter: boolean) => {
    if (isEnter) {
      setIgAnchorEl(null)
      setTtAnchorEl(null)
      setPremiumUgcAnchorEl(null)
      setUgcAnchorEl(null)
      setYtAnchorEl(event.currentTarget)
    } else {
      setYtAnchorEl(null)
    }
  }

  const handleHoverForPremiumUgc = (event: React.MouseEvent<HTMLLIElement | HTMLDivElement>, isEnter: boolean) => {
    if (isEnter) {
      setIgAnchorEl(null)
      setTtAnchorEl(null)
      setYtAnchorEl(null)
      setUgcAnchorEl(null)
      setPremiumUgcAnchorEl(event.currentTarget)
    } else {
      setPremiumUgcAnchorEl(null)
    }
  }

  const handleHoverForStandardUgc = (event: React.MouseEvent<HTMLLIElement | HTMLDivElement>, isEnter: boolean) => {
    if (isEnter) {
      setIgAnchorEl(null)
      setTtAnchorEl(null)
      setYtAnchorEl(null)
      setPremiumUgcAnchorEl(null)
      setUgcAnchorEl(event.currentTarget)
    } else {
      setUgcAnchorEl(null)
    }
  }

  const clearSubMenus = () => {
    setIgAnchorEl(null)
    setTtAnchorEl(null)
    setYtAnchorEl(null)
    setPremiumUgcAnchorEl(null)
    setUgcAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
    clearSubMenus()
  }

  const handleBulkSelect = (channel: ExtendedChannel) => {
    const types = ChannelToContentTypes[channel]
    const isCheck = types.find(t => !keyed[t])
    setExcludedContentTypes((prev: ExtendedContentType[]) => {
      if (!isCheck) {
        return without(prev, ...types)
      }
      return [...prev, ...types]
    })
  }

  const handleSelect = (contentType: ExtendedContentType) => {
    setExcludedContentTypes((prev: ExtendedContentType[]) => {
      if (prev.includes(contentType)) {
        return without(prev, contentType)
      }
      return [...prev, contentType]
    })
  }

  const keyed = keyBy(excludedContentTypes, a => a)

  return (
    <Box color={theme => theme.palette.grey.A400}>
      <Button
        variant={'outlined'}
        color={'inherit'}
        onClick={handleClick}
        disableRipple
        endIcon={<KeyboardArrowDown sx={{ color: theme => theme.palette.grey.A700, fontSize: '24px !important' }} />}
        sx={{ width: '100%', justifyContent: 'space-between' }}
      >
        <Typography variant={'label3'}>Content type</Typography>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} disablePortal sx={{ zIndex: 10 }}>
        {hasSocial && (
          <Stack>
            <Box py={1}>
              <Typography variant={'label2'} px={2}>
                Social platform
              </Typography>
            </Box>
            {hasIg && (
              <MenuItem
                onMouseEnter={e => handleHoverForIg(e, true)}
                onClick={() => handleBulkSelect(Channel.INSTAGRAM)}
              >
                <Stack direction={'row'} alignItems={'center'} flex={1}>
                  {ChannelToContentTypes[Channel.INSTAGRAM].find(t => !keyed[t]) ? (
                    <Check fontSize={'small'} color={'primary'} />
                  ) : (
                    <Close fontSize={'small'} color={'primary'} />
                  )}
                  <Typography variant={'label3'} ml={1} mr={'auto'}>
                    Instagram{' '}
                    {contentByChannel[Channel.INSTAGRAM]?.length && `(${contentByChannel[Channel.INSTAGRAM]?.length})`}
                  </Typography>
                  <ChevronRight fontSize={'mSmall'} />
                </Stack>
              </MenuItem>
            )}
            {hasTt && (
              <MenuItem onMouseEnter={e => handleHoverForTt(e, true)} onClick={() => handleBulkSelect(Channel.TIKTOK)}>
                <Stack direction={'row'} alignItems={'center'} flex={1}>
                  {ChannelToContentTypes[Channel.TIKTOK].find(t => !keyed[t]) ? (
                    <Check fontSize={'small'} color={'primary'} />
                  ) : (
                    <Close fontSize={'small'} color={'primary'} />
                  )}
                  <Typography variant={'label3'} ml={1} mr={'auto'}>
                    TikTok {contentByChannel[Channel.TIKTOK]?.length && `(${contentByChannel[Channel.TIKTOK]?.length})`}
                  </Typography>
                  <ChevronRight fontSize={'mSmall'} />
                </Stack>
              </MenuItem>
            )}
            {hasYt && (
              <MenuItem onMouseEnter={e => handleHoverForYt(e, true)} onClick={() => handleBulkSelect(Channel.YOUTUBE)}>
                <Stack direction={'row'} alignItems={'center'} flex={1}>
                  {ChannelToContentTypes[Channel.YOUTUBE].find(t => !keyed[t]) ? (
                    <Check fontSize={'small'} color={'primary'} />
                  ) : (
                    <Close fontSize={'small'} color={'primary'} />
                  )}
                  <Typography variant={'label3'} ml={1} mr={'auto'}>
                    YouTube{' '}
                    {contentByChannel[Channel.YOUTUBE]?.length && `(${contentByChannel[Channel.YOUTUBE]?.length})`}
                  </Typography>
                  <ChevronRight fontSize={'mSmall'} />
                </Stack>
              </MenuItem>
            )}
          </Stack>
        )}
        {hasUgc && !hasPremiumUgc && (
          <Stack>
            <Box py={1}>
              <Typography variant={'label2'} px={2}>
                UGC content type {contentByChannel[Channel.UGC]?.length && `(${contentByChannel[Channel.UGC]?.length})`}
              </Typography>
            </Box>
            <SubMenuItem
              onMouseEnter={clearSubMenus}
              contentType={ContentType.UGC_PHOTO}
              selected={!!keyed[ContentType.UGC_PHOTO]}
              onClick={() => handleSelect(ContentType.UGC_PHOTO)}
            />
            <SubMenuItem
              onMouseEnter={clearSubMenus}
              contentType={ContentType.UGC_VIDEO}
              selected={!!keyed[ContentType.UGC_VIDEO]}
              onClick={() => handleSelect(ContentType.UGC_VIDEO)}
            />
          </Stack>
        )}
        {hasPremiumUgc && (
          <Stack>
            <Box py={1}>
              <Typography variant={'label2'} px={2}>
                UGC content type
              </Typography>
            </Box>
            <MenuItem
              onMouseEnter={e => handleHoverForPremiumUgc(e, true)}
              onClick={() => handleBulkSelect(PremiumChannel.PREMIUM_UGC)}
            >
              <Stack direction={'row'} alignItems={'center'} flex={1}>
                {ChannelToContentTypes[PremiumChannel.PREMIUM_UGC].find(t => !keyed[t]) ? (
                  <Check fontSize={'small'} color={'primary'} />
                ) : (
                  <Close fontSize={'small'} color={'primary'} />
                )}
                <Typography variant={'label3'} ml={1} mr={'auto'}>
                  Premium{' '}
                  {contentByChannel[PremiumChannel.PREMIUM_UGC]?.length &&
                    `(${contentByChannel[PremiumChannel.PREMIUM_UGC]?.length})`}
                </Typography>
                <LocalPoliceOutlined
                  fontSize="mSmall"
                  style={{
                    marginLeft: '8px',
                    marginRight: '22px'
                  }}
                />
                <ChevronRight fontSize={'mSmall'} />
              </Stack>
            </MenuItem>
            <MenuItem
              onMouseEnter={e => handleHoverForStandardUgc(e, true)}
              onClick={() => handleBulkSelect(Channel.UGC)}
            >
              <Stack direction={'row'} alignItems={'center'} flex={1}>
                {ChannelToContentTypes[Channel.UGC].find(t => !keyed[t]) ? (
                  <Check fontSize={'small'} color={'primary'} />
                ) : (
                  <Close fontSize={'small'} color={'primary'} />
                )}
                <Typography variant={'label3'} ml={1} mr={'auto'}>
                  Standard {contentByChannel[Channel.UGC]?.length && `(${contentByChannel[Channel.UGC]?.length})`}
                </Typography>
                <SlowMotionVideoOutlined
                  fontSize="mSmall"
                  style={{
                    marginLeft: '8px',
                    marginRight: '22px'
                  }}
                />
                <ChevronRight fontSize={'mSmall'} />
              </Stack>
            </MenuItem>
          </Stack>
        )}
      </Menu>

      <Popper
        open={!!igAnchorEl}
        anchorEl={igAnchorEl}
        placement="right-start"
        transition
        disablePortal
        sx={{ zIndex: 11 }}
        onMouseLeave={e => handleHoverForIg(e, false)}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'left top'
            }}
          >
            <Paper>
              <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
                {ChannelToContentTypes[Channel.INSTAGRAM].map(contentType => (
                  <SubMenuItem
                    key={contentType}
                    contentType={contentType}
                    selected={!!keyed[contentType]}
                    onClick={() => handleSelect(contentType)}
                  />
                ))}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>

      <Popper
        open={!!ttAnchorEl}
        anchorEl={ttAnchorEl}
        placement="right-start"
        transition
        disablePortal
        sx={{ zIndex: 11 }}
        onMouseLeave={e => handleHoverForYt(e, false)}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'left top'
            }}
          >
            <Paper>
              <MenuList>
                {ChannelToContentTypes[Channel.TIKTOK].map(contentType => (
                  <SubMenuItem
                    key={contentType}
                    contentType={contentType}
                    selected={!!keyed[contentType]}
                    onClick={() => handleSelect(contentType)}
                  />
                ))}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>

      <Popper
        open={!!ytAnchorEl}
        anchorEl={ytAnchorEl}
        placement="right-start"
        transition
        disablePortal
        sx={{ zIndex: 11 }}
        onMouseLeave={e => handleHoverForTt(e, false)}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'left top'
            }}
          >
            <Paper>
              <MenuList>
                {ChannelToContentTypes[Channel.YOUTUBE].map(contentType => (
                  <SubMenuItem
                    key={contentType}
                    contentType={contentType}
                    selected={!!keyed[contentType]}
                    onClick={() => handleSelect(contentType)}
                  />
                ))}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>

      <Popper
        open={!!premiumUgcAnchorEl}
        anchorEl={premiumUgcAnchorEl}
        placement="right-start"
        transition
        disablePortal
        sx={{ zIndex: 11 }}
        onMouseLeave={e => handleHoverForTt(e, false)}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'left top'
            }}
          >
            <Paper>
              <MenuList>
                {ChannelToContentTypes[PremiumChannel.PREMIUM_UGC].map(contentType => (
                  <SubMenuItem
                    key={contentType}
                    contentType={contentType}
                    selected={!!keyed[contentType]}
                    onClick={() => handleSelect(contentType)}
                  />
                ))}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>

      <Popper
        open={!!ugcAnchorEl}
        anchorEl={ugcAnchorEl}
        placement="right-start"
        transition
        disablePortal
        sx={{ zIndex: 11 }}
        onMouseLeave={e => handleHoverForTt(e, false)}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'left top'
            }}
          >
            <Paper>
              <MenuList>
                {ChannelToContentTypes[Channel.UGC].map(contentType => (
                  <SubMenuItem
                    key={contentType}
                    contentType={contentType}
                    selected={!!keyed[contentType]}
                    onClick={() => handleSelect(contentType)}
                  />
                ))}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}

export default ContentTypeFilter

const SubMenuItem = ({
  contentType,
  onClick,
  selected,
  onMouseEnter
}: {
  contentType: ExtendedContentType
  onClick: () => void
  selected: boolean
  onMouseEnter?: (event: React.MouseEvent<HTMLLIElement | HTMLDivElement>) => void
}) => (
  <MenuItem onClick={onClick} onMouseEnter={onMouseEnter}>
    <Stack direction={'row'} spacing={1} alignItems={'center'}>
      {!selected ? <Check fontSize={'small'} color={'primary'} /> : <Close fontSize={'small'} color={'primary'} />}
      <Typography variant={'label3'}>{ContentTypeToString[contentType]}</Typography>
    </Stack>
  </MenuItem>
)
