import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { CampaignPerformanceProduct } from '@momentum/routes/campaign/e-commerce/performance/queries'
import { ellipsisString } from '@momentum/utils/stringUtils'
import { defaultTooltipOptions } from '@momentum/utils/tooltipUtils'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { PerformanceComparisonMetric } from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { first, last } from 'lodash'
import { Line } from 'react-chartjs-2'
import { useFormContext } from 'react-hook-form'
import { FormType } from '../..'
import { useMilestones, useProductColors } from '../../hooks'
import CampaignLaunchImage from '/images/campaign-launch.png'

const PROMOTED_PRODUCT_INDEX = 0

export const PerformanceGraph = () => {
  const { campaignDetails } = useCampaignContext()
  const { watch } = useFormContext<FormType>()
  const theme = useTheme()
  const productColors = useProductColors()
  const promotedProductId = watch('promotedProductId')
  const products = watch('products') as CampaignPerformanceProduct[]
  const comparisonMetric = watch('comparisonMetric') as PerformanceComparisonMetric
  const comparisonMetricValue = watch('comparisonMetricValue')

  const promotedProduct = products.find((product: CampaignPerformanceProduct) => product.id === promotedProductId)

  if (!promotedProduct) {
    return null
  }

  const productsForMetric = products.filter(
    p => p.id === promotedProductId || !p.comparisonMetric || p.comparisonMetric === comparisonMetric
  )

  const records = productsForMetric.map(p => {
    const startingIndex = p.productPerformanceRecords.findIndex(record =>
      comparisonMetric === PerformanceComparisonMetric.REVIEWS ? record.reviewCount != null : record.salesRank != null
    )
    if (startingIndex >= 0 && comparisonMetric && comparisonMetricValue) {
      let endingIndex: number | undefined
      if (comparisonMetric === PerformanceComparisonMetric.REVIEWS) {
        endingIndex = p.productPerformanceRecords.findIndex(
          r => r.reviewCount && r.reviewCount >= comparisonMetricValue
        )
      } else if (comparisonMetric === PerformanceComparisonMetric.BEST_SELLER_RANK) {
        endingIndex = p.productPerformanceRecords.findIndex(r => r.salesRank && r.salesRank <= comparisonMetricValue)
      }

      return p.productPerformanceRecords.slice(
        startingIndex,
        endingIndex != null && endingIndex >= 1 ? endingIndex + 1 : undefined
      )
    }
    return []
  })

  const recordSets = records.map(rr =>
    rr.map(r => (comparisonMetric === PerformanceComparisonMetric.REVIEWS ? r.reviewCount : r.salesRank))
  )

  const startDateNoTime = campaignDetails.startDate?.split('T')[0]

  const minDays = first(recordSets)?.length || 0
  const startDateIndex = first(records)?.findIndex(r => r.date === startDateNoTime)

  const labels = Array.from({ length: minDays }, (_, i) => `Day ${i}`)

  const milestones = useMilestones()

  const datasets = recordSets
    .map((records, index) =>
      records
        ? {
            data: records,
            milestoneHitIndex: milestones.map(m => records.findIndex(r => r && r >= m)), // for each milestone, datapoint index where milestone is hit
            hidden: !productsForMetric[index].isVisible
          }
        : undefined
    )
    .filter(notEmpty)

  return (
    <Stack>
      <Box>
        <Typography variant={'label1'}>
          Time to {comparisonMetricValue?.toLocaleString()}{' '}
          {comparisonMetric === PerformanceComparisonMetric.REVIEWS ? 'reviews' : 'best seller rank'}
        </Typography>
      </Box>
      <Stack direction={'row'} justifyContent={'center'} spacing={2} py={3}>
        {productsForMetric.map((product, index) => (
          <Stack direction={'row'} spacing={1} key={product.id}>
            <Box
              sx={{
                backgroundColor: productColors[product.id],
                width: 16,
                height: 16,
                borderRadius: 8
              }}
            />
            <Typography variant={'label3'}>{ellipsisString(product.productName, 30)}</Typography>
          </Stack>
        ))}
      </Stack>
      <Box position={'relative'}>
        <Line
          data={{
            labels,
            datasets
          }}
          options={{
            borderColor: theme.palette.grey.A400,
            responsive: true,
            maintainAspectRatio: false,
            datasets: {
              line: {
                borderColor: value => productColors[productsForMetric[value.datasetIndex].id],
                borderWidth: 4.374,
                backgroundColor: value => productColors[productsForMetric[value.datasetIndex].id],
                pointBackgroundColor: value => productColors[productsForMetric[value.datasetIndex].id],
                pointBorderColor: value => 'white',
                pointBorderWidth: 2,
                cubicInterpolationMode: 'monotone',
                spanGaps: true
              }
            },
            elements: {
              point: {
                radius: value => {
                  // campaign start date
                  if (value.datasetIndex === PROMOTED_PRODUCT_INDEX && value.dataIndex === startDateIndex) {
                    return 10
                  }

                  if (datasets[value.datasetIndex].milestoneHitIndex.includes(value.dataIndex)) {
                    return 5
                  }

                  return 0
                },
                pointStyle: value => {
                  if (value.datasetIndex === PROMOTED_PRODUCT_INDEX && value.dataIndex === startDateIndex) {
                    const img = new Image(36, 36)
                    img.src = CampaignLaunchImage

                    return img
                  }
                  return 'circle'
                },
                hitRadius: value => {
                  // campaign start date
                  if (value.datasetIndex === PROMOTED_PRODUCT_INDEX && value.dataIndex === startDateIndex) {
                    return 10
                  }

                  if (datasets[value.datasetIndex].milestoneHitIndex.includes(value.dataIndex)) {
                    return 10
                  }

                  return 0
                },
                hoverRadius: value => 10,
                borderWidth: value => 2,
                drawActiveElementsOnTop: true
              }
            },
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                ...defaultTooltipOptions,
                callbacks: {
                  label: item => {
                    if (item.datasetIndex === PROMOTED_PRODUCT_INDEX && item.dataIndex === startDateIndex) {
                      return 'Campaign launch!'
                    }

                    return last(
                      datasets[item.datasetIndex].milestoneHitIndex
                        .map((goal, index) => {
                          if (goal === item.dataIndex && comparisonMetric === PerformanceComparisonMetric.REVIEWS) {
                            return item.datasetIndex === PROMOTED_PRODUCT_INDEX
                              ? `Promoted product reached ${milestones[index]} reviews!`
                              : `Listing reached ${milestones[index]} reviews`
                          }
                        })
                        .filter(notEmpty)
                    )
                  },
                  title: () => '',
                  footer: () => ''
                }
              }
            },
            scales: {
              y: {
                display: true,
                title: {
                  display: true,
                  text:
                    comparisonMetric === PerformanceComparisonMetric.REVIEWS
                      ? 'Review count on listing'
                      : 'Best seller rank',
                  color: 'black',
                  font: {
                    family: theme.typography.fontFamily as string,
                    weight: theme.typography.label1.fontWeight as number,
                    size: theme.typography.label1.fontSize as number
                  }
                },
                beginAtZero: true,
                grid: {
                  drawOnChartArea: true,
                  drawTicks: false,
                  color: theme.palette.grey.A400,
                  lineWidth: 1.5
                },
                offset: true,
                ticks: {
                  font: {
                    family: theme.typography.fontFamily as string,
                    weight: theme.typography.fontWeightRegular as number,
                    size: theme.typography.label1.fontSize as number
                  },
                  color: 'black',
                  padding: 16
                },
                border: {
                  width: 1.5,
                  color: theme.palette.grey.A400
                }
              },
              x: {
                title: {
                  display: true,
                  text: 'Days since launch',
                  color: 'black',
                  font: {
                    family: theme.typography.fontFamily as string,
                    weight: theme.typography.label1.fontWeight as number,
                    size: theme.typography.label1.fontSize as number
                  }
                },
                ticks: {
                  source: 'labels',
                  minRotation: 60,
                  padding: 16,
                  font: (ctx, options) => {
                    return {
                      family: theme.typography.fontFamily as string,
                      weight: ctx.index === startDateIndex ? 800 : (theme.typography.fontWeightRegular as number),
                      size: theme.typography.label1.fontSize as number
                    }
                  },
                  color: value => {
                    return value.index === startDateIndex ? theme.palette.primary.main : 'black'
                  }
                },
                time: {
                  unit: 'day',
                  tooltipFormat: 'LL/dd',
                  displayFormats: {
                    day: 'LL/dd'
                  }
                },
                grid: {
                  drawOnChartArea: true,
                  drawTicks: false,
                  color: theme.palette.grey.A400,
                  lineWidth: 1.5
                },
                border: {
                  width: 1.5,
                  color: theme.palette.grey.A400
                }
              }
            }
          }}
          height={'560px'}
        />
      </Box>
    </Stack>
  )
}
